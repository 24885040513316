import React, { useMemo } from 'react';
import JobItem from './item';
import Filters from './filters';
import { useQueryParams } from '../../utils/hooks/useQueryParams';
import { selectAllVacancies, useGetVacanciesListQuery } from '../../redux/api/common';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default React.memo(function JobList() {
  const { t } = useTranslation('common');
  const [searchParams, setSearchParams] = useQueryParams();
  const { isFetching, isLoading } = useGetVacanciesListQuery();
  const vacanciesList = useSelector(selectAllVacancies);

  const filteredOffers = useMemo(() => {
    let result = vacanciesList;
    if (searchParams.department) {
      result = result.filter(item => item.department.toLowerCase() === searchParams.department.toLowerCase());
    }

    return result;
  }, [searchParams]);

  return ( !isLoading && (!vacanciesList || !vacanciesList.length)
      ? <span>{t('descriptions.no_vacancies')}</span>
      : <div className={'w-full flex flex-col gap-14 max-w-3xl'}>
      {/*<Filters departments={vacanciesList.map(item => item.department).filter((department, pos, arr) => arr.indexOf(department) === pos)} searchParams={searchParams} setSearchParams={setSearchParams}/>*/}

      <div className={'w-full flex flex-col gap-4 md:gap-10'}>
        {filteredOffers.map(item =>
          <JobItem key={item.id} data={item} />
        )}
      </div>
    </div>
  );
})
