import React from 'react';
import { useNode } from '@craftjs/core';
import ImageSettings from '../settings/imageSettings';
import Border from '../utils/border';

export const Image = React.memo(function ({ src, width, borderRadius }) {
  const { connectors: { connect, drag }, hasSelectedNode, hasHoveredNode, hasDraggedNode } = useNode((state) => {
    return {
      hasSelectedNode: state.events.selected,
      hasHoveredNode: state.events.hovered,
      hasDraggedNode: state.events.dragged
    }
  });

  return (
    <div ref={ref => connect(drag(ref))} className={'relative w-full flex items-center justify-center shrink-0'}
         style={{ width: `${width}%` }}>
      <img src={src} alt={''} className={'w-full h-full object-contain'}
           style={{ borderRadius: `${borderRadius}rem` }} />

      <Border highlight={hasSelectedNode || hasHoveredNode} />
    </div>
  );
});

const defaultProps = {
  src: '',
  width: 100,
  borderRadius: 0,
};

Image.craft = {
  props: defaultProps,
  related: {
    settings: ImageSettings
  }
};
