import React from 'react';

export default React.memo(function Badge({ icon, label, children, className = '' }) {
  return (
    <div className={`w-full flex flex-col gap-2 p-2 rounded-lg overflow-hidden ${className}`}>
      {icon}
      <label>{label}</label>
      <div>
        {children}
      </div>
    </div>
  );
})
