import React, { useCallback, useEffect, useState } from 'react';
import AccordionItem from './accordionItem';

export default React.memo(function Accordion({ items, singleView, className = '', itemContainerClassName, expand, expandDuration, callback }) {
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    if (expand === undefined) return;
    if (!Array.isArray(expand)) expand = [expand];

    setExpanded(expand);
  }, [expand]);

  useEffect(() => {
    if (callback && typeof callback === 'function') callback(expanded);
  }, [expanded, callback]);

  const toggleExpand = useCallback((id) => {
    setExpanded(prev => {
      let nextExpanded;
      if (prev.includes(id)) {
        nextExpanded = prev.filter(item => item !== id);
      } else if (singleView) {
        nextExpanded = [id];
      } else {
        nextExpanded = [...prev, id];
      }

      return nextExpanded;
    })
  }, [singleView]);

  return (
    <div className={`w-full p-4 ${className}`}>
      <div className={'w-full flex flex-col items-center gap-4'}>

        {items.map(item => {
            const isExpanded = expanded.includes(item.id);

            let className;
            if (itemContainerClassName) {
              if (typeof itemContainerClassName === 'string') {
                className = itemContainerClassName;
              } else if (typeof itemContainerClassName === 'function') {
                className = itemContainerClassName(isExpanded);
              }
            }

            return <AccordionItem key={item.id} item={item} isExpanded={isExpanded} expandDuration={expandDuration}
                                  toggleExpand={toggleExpand} className={className} />
          }
        )}

      </div>
    </div>
  );
})
