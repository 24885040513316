import React from 'react';
import Title from '../../uiKit/typography/title';
import SubscriptionForm from '../forms/newsSubscribtionForm';
import { useTranslation } from 'react-i18next';

export default React.memo(function FooterNewsSubscription() {
  const { t } = useTranslation('common');

  return (
    <div
      className={`bg-black flex flex-col w-full md:w-11/12 md:max-w-max md:rounded-[2.5rem] py-10 px-6 md:p-16 [&_span]:text-white [&_p]:text-white`}>
      <Title className={'!text-white text-4xl mb-4'}>
        {t('titles.subscribe')}
      </Title>
      <SubscriptionForm />
    </div>
  );
})
