import React, { useCallback, useEffect, useState } from 'react';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import { Input } from '../../uiKit/inputs';
import Form from './base';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useSubscribeToNewsMutation } from '../../redux/api/common';
import { useGoogleCaptcha } from '../../utils/hooks/useGoogleCaptcha';

export default React.memo(function NewsSubscriptionForm() {
  const { t } = useTranslation('common');
  const executeWithToken = useGoogleCaptcha(true);
  const [success, setSuccess] = useState(null);
  const [errorFields, setErrorFields] = useState([]);
  const [subscribe, { isLoading }] = useSubscribeToNewsMutation();
  const [data, setData] = useState({
    email: '',
  });

  useEffect(() => {
    if (success === null) return;

    const timeout = setTimeout(() => {
      setSuccess(null);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    }
  }, [success]);

  const changeForm = useCallback((e) => {
    const field = e.target.name;
    setErrorFields(prev => prev.filter(item => item !== field));
    setData(prev => ({
      ...prev,
      [field]: e.target.value,
    }));
  }, []);

  const captchaCallback = useCallback(async (token) => {
    try {
      await subscribe({ ...data, captcha: token }).unwrap();
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
      console.error(e);
    }
  }, [data]);

  const submit = useCallback(async () => {
    executeWithToken(captchaCallback, 'submitSubscriptionForm');
  }, [executeWithToken, captchaCallback]);

  const onError = useCallback((errFields) => {
    setErrorFields(errFields);
  }, []);

  return (
    <Form submit={submit} onError={onError} className={'w-full flex flex-col gap-8 md:gap-10'}>

      <Paragraph className={'text-center text-xl'}>
        {t('descriptions.subscribe_on_news')}
      </Paragraph>

      <div className={'w-full flex flex-col md:flex-row gap-4'}>
        <div className={'w-full flex flex-col gap-2'}>
          <Input name={'email'} type={'email'} onChange={changeForm} placeholder={t('placeholders.enter_email')}
                 className={`text-lg ${errorFields.includes('email') ? 'input-error' : ''}`} required />

          <span className={'text-sm text-gray'}>
            <span>{t('labels.data_care')}</span>
            <Link to={'/privacy-policy'} target={'_blank'} rel={'noopener noreferrer'} className={'underline'}>
              <span className={'pointer-events-none'}>{t('labels.privacy_policy')}</span>
            </Link>
          </span>
        </div>

        <div className={'flex flex-col'}>
          <ButtonFulfilled type={'submit'} disabled={isLoading} className={'text-white text-lg px-12 h-max disabled:opacity-50'}>
            {t('buttons.subscribe')}
          </ButtonFulfilled>
          <div className={'relative w-full flex items-start md:items-center justify-center'}>
            {!isLoading && success !== null &&
            <span
              className={`absolute w-full top-0 left-0 animate-appearY ${success ? '!text-green-400' : '!text-red-400'}`}>
            {t(success ? 'labels.success' : 'errors.message_error')}
          </span>}
          </div>
        </div>
      </div>

    </Form>
  );
})
