import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import Cards from '../../uiKit/cards';
import { useTranslation } from 'react-i18next';

import crashGamesImage from '../../assets/images/cards/crash-games-icon.svg';
import fastGamesImage from '../../assets/images/cards/fast-games-icon.svg';
import slotGamesImage from '../../assets/images/cards/slot-games-icon.svg';

export default React.memo(function MechanicsBlock() {
  const { t, i18n } = useTranslation('common');

  const cards = useMemo(() => [
    {
      id: 0,
      imageSrc: crashGamesImage,
      title: t('cards.titles.game_type_crash'),
      text: t('cards.descriptions.game_type_crash'),
    },
    {
      id: 1,
      imageSrc: fastGamesImage,
      title: t('cards.titles.game_type_fast'),
      text: t('cards.descriptions.game_type_fast'),
    },
    {
      id: 2,
      imageSrc: slotGamesImage,
      title: t('cards.titles.game_type_slot'),
      text: t('cards.descriptions.game_type_slot'),
    },
  ], [i18n.language]);

  return (
    <section className={'flex items-center justify-center flex-col w-full md:max-w-7xl'}>
      <div className={'flex flex-col items-center gap-12 md:gap-16'}>
        <div className={'w-10/12 md:max-w-2xl flex justify-center'}>
          <Title>
            {t('titles.mechanics')}
          </Title>
        </div>
        <div className={'w-full flex justify-center text-center md:[&_li]:max-w-sm'}>
          <Cards cardsData={cards}
                 className={'[&_li_div>div]:w-2/3 [&_li_hr]:w-2/3 [&_li_div>div]:md:w-full [&_li_hr]:md:w-full md:max-w-7xl'} />
        </div>
      </div>
    </section>
  );
})
