import React, { useCallback, useEffect, useRef, useState } from 'react';
import { getTimeLeftTillTimestamp } from '../../utils/getTimeLeftTillTimestamp';
import Title from '../typography/title';

export default React.memo(function Ticker({ title, timestamp, className = '' }) {
  const [timeLeft, setTimeLeft] = useState({ days: '00', hours: '00', minutes: '00', seconds: '00' });
  const mounted = useRef(false);
  const lastTimeStampForTick = useRef(null);

  const ticker = useCallback((elapsed) => {
    if (!mounted.current) {
      lastTimeStampForTick.current = null;
      return;
    }

    if (!lastTimeStampForTick.current) lastTimeStampForTick.current = elapsed;
    if (elapsed - lastTimeStampForTick.current > 1000) {
      lastTimeStampForTick.current = elapsed;
      const values = getTimeLeftTillTimestamp(timestamp);
      setTimeLeft(values);

      if (Object.values(values).every(value => !parseInt(value))) {
        lastTimeStampForTick.current = null;
        return;
      }
    }

    requestAnimationFrame(ticker);
  }, [timestamp]);

  useEffect(() => {
    const values = getTimeLeftTillTimestamp(timestamp);
    setTimeLeft(values);
  }, [timestamp]);

  useEffect(() => {
    mounted.current = true;
    requestAnimationFrame(ticker);

    return () => {
      mounted.current = false;
    }
  }, [ticker]);

  return (
    <div
      className={`flex flex-col justify-center items-center text-center w-full rounded-b-3xl p-8 pb-12 blurred ${className}`}>
      <Title className={'!text-white mb-2'}>
        {title}
      </Title>
      <div
        className={'flex flex-row gap-4 font-Staatliches text-4xl text-white [&_span]:flex-1 [&_span]:relative'}>
                    <span
                      className={`after:content-['days'] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:text-lg`}>{timeLeft.days}</span>
        <span>:</span>
        <span
          className={`after:content-['hours'] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:text-lg`}>{timeLeft.hours}</span>
        <span>:</span>
        <span
          className={`after:content-['minutes'] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:text-lg`}>{timeLeft.minutes}</span>
        <span>:</span>
        <span
          className={`after:content-['seconds'] after:absolute after:top-full after:left-1/2 after:-translate-x-1/2 after:text-lg`}>{timeLeft.seconds}</span>
      </div>
    </div>
  );
})
