import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactDom from 'react-dom';
import { useScrollBlocker } from '../../utils/hooks/useScrollBlocker';

export default React.memo(function BaseModal({ isOpen, close, closable, children, className = '' }) {
  const [visible, setVisible] = useState(isOpen);
  const wrapperRef = useRef(null);

  useScrollBlocker(isOpen);

  useEffect(() => {
   if (isOpen) setVisible(true);
  }, [isOpen]);

  const closing = useMemo(() => {
    return !isOpen && visible;
  }, [visible, isOpen]);

  const onClose = () => {
    close();
  };

  const onAnimationEnd = (e) => {
    if (e.target !== wrapperRef.current) return;
    if (isOpen && !closing) return;

    close && close();
    setVisible(false);
  };

  const onWrapperClick = (e) => {
    if (!closable || (e.target !== wrapperRef.current)) return;
    onClose();
  };

  return !visible ? null : ReactDom.createPortal(<div ref={wrapperRef} onClick={onWrapperClick} onAnimationEnd={onAnimationEnd}
         className={`fixed inset-0 w-full h-screen bg-black/50 z-[300] flex items-center justify-center p-4 ${closing ? 'opacity-0 animate-fadeOut' : 'opacity-1 animate-fadeIn'}`}>
      <div className={`max-h-screen overflow-y-scroll p-10 bg-white rounded-xl ${closing ? 'animate-hideY' : 'animate-appearY'} ${className}`}>
        {children}
      </div>
    </div>, document.body);
})

