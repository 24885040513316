import React, { useId, useMemo } from 'react';
import DonutChart from '../../charts/donutChart';
import ShadowCard from '../../../uiKit/cards/shadowCard';
import COLORS from '../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { useIntersectionObserver } from '../../../utils/hooks/useIntersectionObserver';

export default React.memo(function CountriesCoverage({ data, gamesCount, operatorsCount, className = '' }) {
  const { t } = useTranslation('common');
  const id = useId();
  const isIntersecting = useIntersectionObserver(id);

  const dataPercentage = useMemo(() => {
    const totalValue = data.reduce((acc, curr) => acc + curr.operatorsCount, 0);
    return data.map((item, i) => ({
      ...item,
      color: COLORS[i % COLORS.length],
      percent: ((item.operatorsCount / totalValue) * 100).toFixed(0)
    }));
  }, []);

  return (
    <ShadowCard className={className}>
      <div className={'w-full flex flex-col gap-6'}>
        <div className={'w-full flex items-center gap-2'}>
          <span className={'font-Staatliches text-6xl uppercase'}>
            {gamesCount}+
          </span>
          <span className={'text-base uppercase whitespace-pre-line'}>
            {t('cards.descriptions.countries_coverage', { operatorsCount })}
          </span>
        </div>

        <div className={'w-full flex flex-col gap-7'}>
          <div id={id} className={'w-full h-48'}>
            <DonutChart data={data} dataKey={'operatorsCount'} className={isIntersecting ? '' : 'hidden'}/>
          </div>
          <div className={'flex flex-col gap-7'}>
            {dataPercentage.map((item, i) =>
              <div key={i} className={'w-full grid grid-cols-[max-content,_1fr] gap-1.5 text-sm text-black'}>
                <div className={'flex items-center gap-1.5'}>
                  <span className={'w-4 h-4 rounded'} style={{ backgroundColor: item.color }} />
                  <span>{item.region}</span>
                </div>
                <div className={'flex items-center gap-1.5'}>
                    <span className={'inline-block h-2 rounded-2xl'}
                          style={{ backgroundColor: item.color, width: `${item.percent}%` }} />
                  <span>{item.percent}%</span>
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
    </ShadowCard>
  );
})
