import React, { useRef } from 'react';
import { useNode, useEditor } from '@craftjs/core';
import TextEditor from '../../textEditor';
import Border from '../utils/border';

export const Text = React.memo(function ({ text }) {
  const { editable } = useEditor((state) => ({
    editable: state.options.enabled,
  }));

  const { connectors: { connect, drag }, actions: { setProp }, hasSelectedNode, hasHoveredNode, hasDraggedNode } = useNode((state) => {
    return {
      hasSelectedNode: state.events.selected,
      hasHoveredNode: state.events.hovered && !state.events.selected,
      hasDraggedNode: state.events.dragged
    }
  });

  const textElRef = useRef(null);

  const saveHtml = () => {
    setProp(props => props.text = textElRef.current.toHtml())
  };

  return (
    <div className={'relative w-full'} ref={ref => connect(drag(ref))}>
      <TextEditor readOnly={!editable} initialValue={text}
                  toolbarClassName={!editable || !hasSelectedNode ? '!hidden' : ''}
                  editorClassName={`[&_a_*]:text-blue-300 ${!editable || !hasSelectedNode ? '!border-none' : ''}`}
                  onBlur={saveHtml} ref={textElRef} />
      <Border highlight={hasSelectedNode || hasHoveredNode} />
    </div>
  );
});

const defaultProps = {
  text: 'Text',
};

Text.craft = {
  props: defaultProps,
};
