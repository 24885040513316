import React, { useEffect, useId, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getGamesList, useGetGamesDataQuery } from '../../redux/api/games';
import { useSelector } from 'react-redux';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import GameCardStatic from '../gameCard/static';
import Title from '../../uiKit/typography/title';
import GameCard from '../gameCard';
import GameCardSkeleton from '../gameCard/skeleton';
import { useImagePreload } from '../../utils/hooks/useImagePreload';
import { useIntersectionObserver } from '../../utils/hooks/useIntersectionObserver';

export default React.memo(function GamesBlock({ title, topOffset = 0 }) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const [openCardId, setOpenCardId] = useState(null);
  const { isFetching } = useGetGamesDataQuery();
  const gamesList = useSelector(getGamesList);
  const loaded = useImagePreload(gamesList.reduce((res, game) => {
    res.push(game.iconPreview, game.iconSquare);
    return res;
  }, []));

  const goToGamesListPage = () => {
    navigate('/games');
  };

  const onCardClick = (id) => {
    setOpenCardId(id);
  };

  const list = useMemo(() => {
    const listLength = 5;
    return gamesList.length ? gamesList.slice(0, listLength) : Array.from({ length: listLength }, () => null);
  }, [gamesList]);

  return (
    <section className={'w-full flex flex-col items-center gap-4 md:gap-6'}>

      {/* mobile */}
      <div className={'flex md:hidden flex-col gap-12'}>
        <div className={'relative w-full flex flex-col items-center gap-4 md:gap-6'}>
          <Title className={'md:!text-8xl'}>
            {title}
          </Title>

          <span className={'uppercase font-Staatliches text-3xl md:text-6xl text-gradient md:max-w-4xl '}>
            {gamesList.length}+
            </span>
        </div>

        <div
          className={`grid grid-cols-[_22rem] auto-rows-[22rem] xl:grid-cols-[repeat(2,minmax(0,_22rem))] justify-center gap-8 w-full [&>*]:w-[22rem]`}>
          {list.map((game, index) => game && loaded.includes(game.iconSquare) && loaded.includes(game.iconPreview) ?
            <GameCard key={game.gameBundle} game={game} callback={onCardClick} isOpen={game.id === openCardId} /> :
            <GameCardSkeleton key={index} />
          )}
        </div>

        <div className={'relative w-full flex items-center justify-center'}>
          <ButtonOutlined onClick={goToGamesListPage} className={'px-6'}>
            {t('buttons.view_games')}
          </ButtonOutlined>
        </div>

      </div>

      {/* desktop */}
      <div
        className={'hidden md:flex w-full relative h-[81.5rem] flex-col items-center justify-between gap-4 md:gap-6'}>

        <div className={'relative h-full w-full flex flex-col justify-between items-center pb-[10%]'}>
          <div
            className={`absolute left-0 bg-gradient-to-b from-white w-full h-80 z-10`}
            style={{ top: `${topOffset - 0.5}%` }} />

          <div className={'relative w-full flex flex-col items-center gap-4 md:gap-6 z-20'}>
            <Title className={'md:!text-8xl'}>
              {title}
            </Title>

            <span className={'uppercase font-Staatliches text-3xl md:text-6xl text-gradient md:max-w-4xl '}>
              {gamesList.length}+
            </span>
          </div>

          <GamesGrid gamesList={gamesList} loaded={loaded} style={{ top: `${topOffset}%`, height: `${100 - topOffset}%` }} />

          <div className={'relative w-full flex items-center justify-center mt-10 z-20'}>
            <ButtonOutlined onClick={goToGamesListPage} className={'px-6'}>
              {t('buttons.view_games')}
            </ButtonOutlined>
          </div>

          <div
            className={'absolute -bottom-0.5 left-0 bg-gradient-to-t from-white w-full h-96 z-10'} />
        </div>

      </div>

    </section>
  );
})

const GamesGrid = React.memo(function ({ gamesList, loaded, className = '', style }) {
  const [progress, setProgress] = useState(0);
  const id = useId();
  const isIntersecting = useIntersectionObserver(id);
  const effortCoeff = 0.2;

  useEffect(() => {
    const target = document.getElementById(id);

    const scrollListener = () => {
      const bounds = target.getBoundingClientRect();
      const fullHeight = window.innerHeight + bounds.height;
      const inView = window.innerHeight - bounds.top;
      let progress = inView * 100 / fullHeight;
      if (progress < 0) progress = 0;
      if (progress > 100) progress = 100;
      setProgress(progress);
    };

    if (isIntersecting) {
      window.addEventListener('scroll', scrollListener);
    } else {
      window.removeEventListener('scroll', scrollListener);
    }

    return () => {
      window.removeEventListener('scroll', scrollListener);
    }
  }, [id, isIntersecting]);

  const cols = useMemo(() => {
    const colsCount = 6;
    const colsLength = 6;

    const result = Array.from({ length: colsCount }, () => []);

    if (gamesList.length) {
      const itemsCount = colsCount * colsLength;

      for (let i = 0; i < itemsCount; i++) {
        const colIndex = i % colsCount;
        const gameIndex = i % gamesList.length;
        result[colIndex].push(gamesList[gameIndex]);
      }
    } else {
      result.forEach(col => col.push(...Array.from({ length: colsLength }, () => null)));
    }

    return result;
  }, [gamesList]);

  return (
    <div id={id}
         className={`absolute top-0 h-full grid grid-cols-[repeat(6,minmax(0,_19rem))] w-max gap-6 overflow-hidden select-none ${className}`} style={style}>
      {cols.map((col, colIndex) =>
        <div key={colIndex} className={'relative w-full h-full'}>
          <div className={'absolute grid auto-rows-[19rem] w-full gap-6 will-change-transform'}
               style={colIndex % 2 === 0 ? {
                 bottom: 0,
                 transform: `translateY(${effortCoeff * progress}%) translateZ(0)`
               } : {
                 top: 0,
                 transform: `translateY(-${effortCoeff * progress}%) translateZ(0)`
               }}>
            {col.map((game, cardIndex) => game && loaded.includes(game.iconSquare) && loaded.includes(game.iconPreview) ?
              <GameCardStatic key={game.gameBundle} game={game} /> : <GameCardSkeleton key={cardIndex} />)}
          </div>
        </div>)}
    </div>
  );
});
