import React from 'react';

export default React.memo(function Burger({ open, toggle }) {
  return (
    <button className={'flex items-center justify-center md:hidden pl-4 py-4 hover:cursor-pointer'}
            onClick={toggle}>
      <div id={'side-bar-burger'} className={'flex flex-col justify-between w-5 h-3.5 [&>span]:transition-all [&>span]:duration-500'}>
        <span className={`w-full h-0.5 rounded-sm bg-white ${open ? 'translate-y-1.5 rotate-45' : ''}`}/>
        <span className={`w-full h-0.5 rounded-sm bg-white ${open ? 'opacity-0 rotate-45' : 'opacity-100'}`}/>
        <span className={`w-full h-0.5 rounded-sm bg-white ${open ? '-translate-y-1.5 -rotate-45' : ''}`}/>
      </div>
    </button>
  );
})
