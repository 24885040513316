import React, { useMemo } from 'react';
import ImageBlock from '../imageBlock/index';
import PageTitleLayout from '../../uiKit/pageTitleLayout';
import Ticker from '../../uiKit/ticker';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import GamesList from '../gamesList';
import { useTranslation } from 'react-i18next';
import { useGetUpcomingGameQuery } from '../../redux/api/games';
import { useNavigate } from 'react-router-dom';
import { transformGameTitleToUrl } from '../../utils/transformGameTitleToUrl';
import imagePlaceholder from '../../assets/images/games_page_placeholder_1.jpg';
import {useGetSettingsQuery} from "../../redux/api/admin";

export default React.memo(function GamesListPage() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const {data: settings, isFetching: isFetchingSettings} = useGetSettingsQuery()
  const { data: upcomingGame, isFetching } = useGetUpcomingGameQuery();

  const goToUpcomingGamePage = () => {
    navigate(`/games/${transformGameTitleToUrl(upcomingGame.name)}`);
  };

  const imageSrc = useMemo(() => {
    if (isFetching) return null;
    return upcomingGame?.imagePreview || settings?.gamesPagePlaceholderImages?.horizontal || imagePlaceholder;
  }, [upcomingGame, isFetching, settings]);

  const mobileImageSrc = useMemo(() => {
    if (isFetching) return null;
    return upcomingGame?.imagePreviewMobile || settings?.gamesPagePlaceholderImages?.vertical || imagePlaceholder;
  }, [upcomingGame, isFetching, settings]);

  return (
    <>
    <ImageBlock src={imageSrc} mobileSrc={mobileImageSrc} showOverlay={false}>
        <div className={'w-full h-full flex flex-col items-center self-end'}>
          {(!isFetching && upcomingGame) &&
          <PageTitleLayout title={upcomingGame.name}
                           className={'w-11/12 h-full flex flex-col items-center justify-center text-center gap-4 md:gap-12 max-w-3xl'}>
            <Ticker title={'Upcoming Game'} timestamp={new Date(upcomingGame.releaseDate)}
                    className={'w-full md:w-7/12 rounded-t-3xl'} />
            {upcomingGame.gameBundle &&
            <ButtonFulfilled onClick={goToUpcomingGamePage} className={'w-10/12 md:w-max px-12 text-white'}>
              {t('buttons.game_info')}
            </ButtonFulfilled>}
          </PageTitleLayout>}

          {(!isFetching && !upcomingGame) &&
          <div className={'pb-10 px-2 md:pb-14 md:px-4'}>
            <div
              className={'font-Staatliches text-5xl md:text-[5rem] leading-none text-white flex flex-wrap justify-center [&>h1]:whitespace-nowrap'}>
              <h1>{t('titles.games_page_0')}</h1>
              <span className={'text-[#f05a26] mx-3'}>|</span>
              <h1>{t('titles.games_page_1')}</h1>
              <span className={'text-[#f05a26] mx-3'}>|</span>
              <h1>{t('titles.games_page_2')}</h1>
              <span className={'text-[#f05a26] mx-3'}>|</span>
              <h1>{t('titles.games_page_3')}</h1>
            </div>
          </div>}
        </div>
      </ImageBlock>

      <div className={'flex flex-col items-center w-full py-20 px-4 md:px-12 2xl:px-44 md:pt-24 md:pb-32'}>
        <GamesList />
      </div>
    </>
  );
})
