import React from 'react';

import { Navigate, useParams } from 'react-router-dom';
import { usePageTitle } from '../../utils/hooks/usePageTitle';
import { useMetaTags } from '../../utils/hooks/useMetaTags';
import { selectVacanciesById, useGetVacanciesListQuery } from '../../redux/api/common';
import { useSelector } from 'react-redux';
import PageTitleLayout from '../../uiKit/pageTitleLayout';
import Paragraph from '../../uiKit/typography/paragraph';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ClockIcon } from '../../assets/icons/system/clock-icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/system/map-icon.svg';
import JobForm from '../forms/jobForm';
import LinkFulfilled from '../../uiKit/links/fulfilled';

export default React.memo(function JobPage() {
  const { t } = useTranslation('common');
  const params = useParams();
  const { isFetching } = useGetVacanciesListQuery();
  const jobData = useSelector(state => selectVacanciesById(state, params.id));

  usePageTitle(jobData?.name);
  useMetaTags([
    {
      name: 'description',
      content: jobData?.description.reduce((prev, descObj) => prev + ' \n\n' + descObj.title + ' \n' + descObj.description, '') || ''
    },
    { property: 'og:title', content: jobData?.name || '' }
  ]);

  const showLinks = Boolean(jobData?.linkLinkedIn || jobData?.linkDjinni);

  return isFetching ? null : !jobData ? <Navigate to={'/404'} replace={true} /> : (
    <>
      <div className={'w-full bg-black flex flex-col items-center'}>
        <PageTitleLayout title={jobData.name}
                         className={'w-full flex flex-col items-center gap-8 pt-10 pb-32 px-4 mt-24 md:mt-48 max-w-7xl'}
                         classNameTitle={'md:!text-8xl'}>
          <div className={'flex flex-col gap-16'}>
            <div className={'flex gap-2 md:gap-6'}>
              <Paragraph className={'!text-gray !whitespace-nowrap'}>
                {jobData.department}
              </Paragraph>
              <div className={'border-r my-1.5 border-gray'} />
              <Paragraph className={'!text-gray !whitespace-nowrap flex gap-2 items-center'}>
                <LocationIcon /><span>{jobData.workPlace}</span>
              </Paragraph>
              <Paragraph className={'!text-gray !whitespace-nowrap  flex gap-2 items-center'}>
                <ClockIcon /><span>{jobData.workTime}</span>
              </Paragraph>
            </div>

            {showLinks &&
            <div className={'flex flex-wrap justify-center gap-8 [&>a]:whitespace-nowrap [&>a]:flex-1'}>
              {jobData.linkLinkedIn &&
              <LinkFulfilled external target={'_blank'} to={jobData.linkLinkedIn}
                             className={'!py-4 !px-6 w-max text-white text-lg'}>
                {t('buttons.applyLinkedIn')}
              </LinkFulfilled>}
              {jobData.linkDjinni &&
              <LinkFulfilled external target={'_blank'} to={jobData.linkDjinni}
                             className={'!py-4 !px-6 w-max text-white text-lg'}>
                {t('buttons.applyDjinni')}
              </LinkFulfilled>}
            </div>}
          </div>
        </PageTitleLayout>
      </div>

      <div
        className={'w-full flex flex-col gap-12 max-w-4xl py-32 px-4 md:px-16 [&_ol]:!list-decimal [&_ol]:p-[revert] [&_ul]:p-[revert] [&_ul]:!list-disc'}>
        {jobData.description && jobData.description.map((item, index) => (
          <div key={index} className={' flex flex-col gap-6 justify-items-center md:justify-items-start'}>
            {item.title && <div>
              <span className={'uppercase font-Staatliches text-black text-4xl md:text-5xl'}>{item.title}</span>
            </div>}

            <Paragraph className={'!whitespace-normal [&_p]:m-[revert]'}>
              <span dangerouslySetInnerHTML={{ __html: item.description }} />
            </Paragraph>
          </div>
        ))}
        <JobForm jobData={jobData} />
      </div>
    </>
  );
})
