import React from 'react';

export default React.memo(function DatePicker({ value, placeholder, className, type = 'date', ...props }) {
  return (
    <div className={`relative flex items-center w-full h-16 rounded-2xl bg-white border border-[#D0D5DD] outline-none px-4 py-3 shadow-sm ${className}`}>
      <label className={` ${value ? '' : 'text-neutral'}`}>{value.replace('T', ' ') || placeholder}</label>
      <input className={`datepicker`} value={value} type={type} {...props} />
    </div>
  );
})
