import React from 'react';
import PageTitleLayout from '../../uiKit/pageTitleLayout';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import OurStory from '../infoBlocks/ourStoryBlock';
import GamesBlock from '../infoBlocks/gamesBlock';
import GamingSolutions from '../infoBlocks/gamingSolutionsBlock';

import { useTranslation } from 'react-i18next';

export default React.memo(function CompanyPage() {
  const { t } = useTranslation('common');

  return (
    <>
      <section
        className={`relative w-full min-h-screen after:absolute after:inset-0 after:bg-black after:opacity-50 after:z-[-1]`}>
        <div className={'absolute inset-0'}>
          <div className={'w-full h-1/2 bg-blue-700'} />
          <div className={'w-full h-1/2 bg-yellow-300'} />
          {/*<div className={'w-full h-1/2 bg-[#0600FF]'}/>*/}
          {/*<div className={'w-full h-1/2 bg-[#FFFF00]'}/>*/}
        </div>
        <div className={`relative w-full min-h-screen flex items-center pb-4 pt-20 md:pt-40`}>
          <div className={'w-full h-full'}>
            <div className={'flex items-center justify-center'}>
              <PageTitleLayout title={t('titles.company_page')} className={'w-10/12 md:max-w-4xl mb-14 md:mb-32'} />
            </div>
          </div>
        </div>
      </section>

      <div className={'w-full flex justify-center py-20 md:py-32'}>
        <div className={'flex flex-col justify-center text-center gap-4 md:gap-10 max-w-6xl px-4'}>
          <Title>
            {t('titles.about')}
          </Title>
          <Paragraph className={'!text-xl'}>
            {t('descriptions.about')}
          </Paragraph>
        </div>
      </div>

      <div className={'w-full py-20 md:py-32 px-4 md:px-12 xl:px-44'}>
        <OurStory />
      </div>

      <GamesBlock title={t('titles.made_for_you')} topOffset={20} />

      <div className={'w-full py-20 md:py-24 px-4 md:px-12 xl:px-44'}>
        <div className={'px-4 py-6 md:px-12 md:py-14 rounded-3xl bg-rose flex flex-col items-start gap-4 md:gap-14'}>
          <Title>
            {t('titles.vision')}
          </Title>
          <Paragraph>
            {t('descriptions.vision')}
          </Paragraph>
        </div>
      </div>

      <div className={'w-full flex flex-col items-center py-20 md:py-32 px-4 md:px-12 xl:px-44'}>
        <GamingSolutions />
      </div>

    </>
  );
})
