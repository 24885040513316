import React, { useState, useRef } from 'react'
import DeleteButton from '../buttons/delete';

export default React.memo(function DragAndDrop({ onChange, accept = 'image/*', multiple, name, label, children, required, className = '', containerClassName = '' }) {
  const [isDrag, setIsDrag] = useState(false);
  const dragCounter = useRef(0);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current++;
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setIsDrag(true);
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dragCounter.current--;
    if (dragCounter.current === 0) {
      setIsDrag(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDrag(false);
    dragCounter.current = 0;

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      if (Array.from(e.dataTransfer.files).every(file => validateFileExtension(accept, file.type))) {
        onChange({
          target: {
            name,
            files: e.dataTransfer.files
          }
        });
      }
      e.dataTransfer.clearData();
    }
  };

  const validateFileExtension = (accept, fileExt) => {
    let valid = true;
    const canAcceptArray = accept.replace(/\s/g, '').split(',');

    for (let acceptExt of canAcceptArray) {
      const validExtParts = acceptExt.split('/');
      const fileExtParts = fileExt.split('/');
      let breakCycle = false;

      for (let i = 0; i < validExtParts.length; i++) {
        if (validExtParts[i] === '*') {
          breakCycle = true;
          break;
        }
        if (validExtParts[i] !== fileExtParts[i]) {
          valid = false;
          breakCycle = true;
          break;
        }
      }

      if (breakCycle) break;
    }

    return valid;
  };

  const onFilePick = (e) => {
    onChange(e);
  };

  const deleteFile = (e) => {
    onChange({
      target: {
        name,
        files: null,
      }
    });
  };

  return (
    <div className={'relative w-full h-full'}>
      {children && <DeleteButton onClick={deleteFile} />}

      <div className={`w-full h-full relative rounded-xl overflow-hidden ${containerClassName}`}
           onDragEnter={handleDragIn} onDragLeave={handleDragOut} onDrop={handleDrop} onDragOver={handleDrag}>

        <label
          className={`absolute inset-0 z-[1] flex items-center justify-center border rounded-[inherit] border-dashed transition-all ${isDrag ? '!border-sky-400' : 'border-sky-200'} ${className}`}>
          <input type={'file'} name={name} value={''} accept={accept} multiple={multiple} onChange={onFilePick}
                 className={'hidden'} required={required} />
          {!children &&
          <span className={`select-none transition-all text-center ${isDrag ? 'text-gray' : 'text-neutral'}`}>
          {label}
        </span>}
        </label>

        {children}
      </div>
    </div>
  )
})
