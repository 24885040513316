import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Checkbox, FileDrop, Input, TextArea } from '../../uiKit/inputs';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import Title from '../../uiKit/typography/title';
import LinkOutlined from '../../uiKit/links/outlined';
import Paragraph from '../../uiKit/typography/paragraph';

import { useSendVacancyApplicationMutation } from '../../redux/api/common';
import { useTranslation } from 'react-i18next';
import { useGoogleCaptcha } from '../../utils/hooks/useGoogleCaptcha';
import { fileToBase64 } from '../../utils/fileToBase64';
import { deleteFile, truncateFilesBySizeLimit } from '../../utils/filesHelpers';

import { ReactComponent as LinkedInIcon } from '../../assets/icons/socials/linkedin-icon-smal.svg';
import { ReactComponent as DjinniInIcon } from '../../assets/icons/socials/djinni-icon-smal.svg';
import { ReactComponent as CrossIcon } from '../../assets/admin/icons/system/cross.svg';

export default React.memo(function JobForm({ jobData }) {
  const { t } = useTranslation('common');
  const [sendVacancyApplication, { isLoading }] = useSendVacancyApplicationMutation();
  const [success, setSuccess] = useState(null);
  const executeWithToken = useGoogleCaptcha(true);
  const formRef = useRef(null);
  const fileDropRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(true);
  const [data, setData] = useState({
    vacancyId: jobData.id,
    firstName: '',
    lastName: '',
    email: '',
    additionalContact: '',
    city: '',
    salaryExpectations: '',
    about: '',
    files: [],
    privacyPolicyChecked: ''
  });
  const [fieldsValidity, setFieldsValidity] = useState(Object.fromEntries(Object.entries(data).map(entry => [entry[0], { valid: true }])));

  const onDragStart = useCallback(() => {
    setDragging(true);
  }, [setDragging]);

  const onDragEnd = useCallback((e) => {
    setDragging(false);
  }, [setDragging]);

  useEffect(() => {
    if (success === null) return;

    const timeout = setTimeout(() => {
      setSuccess(null);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    }
  }, [success]);

  const onChange = (e) => {
    const field = e.target.name;
    let value = e.target.value;

    if (!fieldsValidity[field].valid) setFieldsValidity(prev => ({
      ...prev,
      [field]: { ...prev[field], valid: true }
    }));
    setData(prev => ({ ...prev, [field]: value }));
  };

  const onChangePrivacyPolicy = (e) => {
    const field = e.target.name;
    let value = e.target.checked;

    if (!fieldsValidity[field].valid) setFieldsValidity(prev => ({
      ...prev,
      [field]: { ...prev[field], valid: true }
    }));
    setPrivacyPolicyChecked(value);
  };

  const onFilePick = useCallback(async (e) => {
    const field = e.target.name;

    let files = e.target.files;

    if (!files) {
      return;
    }

    files = truncateFilesBySizeLimit(files, 41943040, fileDropRef.current?.inputRef.current); //40MB

    for (const file of files) {
      const base64 = await fileToBase64(file);
      if (base64.error) {
        console.error(base64.error);
        break;
      } else {
        if (!fieldsValidity[field].valid) setFieldsValidity(prev => ({
          ...prev,
          [field]: { ...prev[field], valid: true }
        }));
        setData(prev => ({
          ...prev, [field]: [...prev[field], {
            filename: file.name,
            content: base64.result,
            encoding: 'base64'
          }]
        }));
      }
    }
  }, []);

  const onFileDelete = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setData(prev => ({ ...prev, files: prev.files.filter((file, pos) => pos !== index) }));
    deleteFile(fileDropRef.current?.inputRef.current.files || [], index, fileDropRef.current?.inputRef.current);
  };

  const validate = () => {
    const inputs = formRef.current.elements;
    let isValid = true;

    for (let field in fieldsValidity) {
      if (field === 'vacancyId') continue;
      if (!inputs[field].validity.valid) {
        setFieldsValidity(prev => ({ ...prev, [field]: { ...prev[field], valid: false } }));
        isValid = false;
      }
    }

    return isValid
  };

  const captchaCallback = async (token) => {
    try {
      let sendData = {};
      for (const dataKey in data) {
        if (data[dataKey] !== '') sendData[dataKey] = data[dataKey];
      }
      await sendVacancyApplication({ ...sendData, captcha: token }).unwrap()
        .then((res) => {
          if (res.success) {
            setSuccess(true);
          } else {
            console.error(res.message);
            setSuccess(false);
          }
        });
    } catch (e) {
      console.error(e);
      setSuccess(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;

    executeWithToken(captchaCallback, 'submitJobForm');
  };

  const linksCount = (jobData.linkLinkedIn ? 1 : 0) + (jobData.linkDjinni ? 1 : 0);

  return (
    <div className={'bg-rose p-6 md:p-24 rounded-2xl'}>
      {!isLoading && success !== null
        ? <div className={'flex flex-col items-center justify-center gap-4 animate-fadeIn'}>
          <Title className={'text-4xl md:text-4xl !leading-tight'}>
            {t('titles.thank_you')}
          </Title>
          <Paragraph className={'text-center'}>
            {t('descriptions.message_sent')}
          </Paragraph>
        </div>
        : <form ref={formRef} onSubmit={submit} className={'w-full flex flex-col gap-12 animate-fadeIn'} noValidate>
          <Title className={'text-4xl md:text-4xl !leading-tight'}>
            {t('titles.apply_to_position')}
          </Title>
          <div className={'grid grid-cols-2 gap-x-8 gap-y-6'}>
            <div className={'col-span-2 md:col-span-1 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.first_name')}</label>
              <Input name={'firstName'} value={data.firstName} onChange={onChange}
                     className={`!h-14 ${!fieldsValidity.firstName.valid ? '!border-red-400 animate-errorShake' : ''}`}
                     placeholder={t('placeholders.first_name')} maxLength={30} required />
            </div>

            <div className={'col-span-2 md:col-span-1 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.last_name')}</label>
              <Input name={'lastName'} value={data.lastName} onChange={onChange}
                     className={`!h-14 ${!fieldsValidity.lastName.valid ? '!border-red-400 animate-errorShake' : ''}`}
                     placeholder={t('placeholders.last_name')} maxLength={30} required />
            </div>

            <div className={'col-span-2 md:col-span-1 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.email')}</label>
              <Input name={'email'} value={data.email} onChange={onChange} placeholder={t('placeholders.email')}
                     className={`!h-14 ${!fieldsValidity.email.valid ? '!border-red-400 animate-errorShake' : ''}`}
                     type={'email'} maxLength={30} required />
            </div>

            <div className={'col-span-2 md:col-span-1 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.additionalContact')}</label>
              <Input name={'additionalContact'} value={data.additionalContact} onChange={onChange}
                     placeholder={t('placeholders.additionalContact')}
                     className={'!h-14'}
                     type={'additionalContact'} maxLength={30} />
            </div>

            <div className={'col-span-2 md:col-span-1 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.city')}</label>
              <Input name={'city'} value={data.city} onChange={onChange} placeholder={t('placeholders.city')}
                     className={`!h-14 ${!fieldsValidity.city.valid ? '!border-red-400 animate-errorShake' : ''}`}
                     type={'city'} maxLength={30} required />
            </div>

            <div className={'col-span-2 md:col-span-1 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.salaryExpectations')}</label>
              <Input name={'salaryExpectations'} value={data.salaryExpectations} onChange={onChange}
                     placeholder={t('placeholders.salaryExpectations')}
                     className={`!h-14 ${!fieldsValidity.salaryExpectations.valid ? '!border-red-400 animate-errorShake' : ''}`}
                     type={'salaryExpectations'} maxLength={30} required />
            </div>

            <div className={'col-span-2 flex flex-col gap-1.5'}>
              <label className={'text-gray-700'}>{t('labels.about')}</label>
              <TextArea name={'about'} value={data.about} onChange={onChange}
                        className={`h-32 ${!fieldsValidity.about.valid ? '!border-red-400 animate-errorShake' : ''}`}
                        maxLength={10000} required />
            </div>

            <div className={'col-span-2 flex-1'}>
              <FileDrop name={'files'} onDrop={onDragEnd} onDragLeave={onDragEnd} onDragOver={onDragStart}
                        onChange={onFilePick} multiple accept={''} ref={fileDropRef}
                        label={data.files.length && !dragging
                          ? <div
                            className={'w-full flex flex-wrap justify-center gap-1.5 m-2 p-2'}>{data.files.map((file, index) => (
                            <div key={index}
                                 className={'btn__outlined group max-w-full py-0.5 px-1.5 before:rounded-md before:p-px pointer-events-auto break-words'}>
                              <CrossIcon onClick={(e) => onFileDelete(e, index)}
                                         className={'absolute  top-0 right-0 bg-black   opacity-0 group-hover:opacity-100 pointer-events-auto rounded-full p-px  w-3 h-3 translate-y-[-50%] translate-x-[50%] cursor-pointer'} />
                              <span>{file.filename}</span>
                            </div>
                          ))}</div>
                          : <div className={'text-center text-xs md:text-base text-gray-400 whitespace-pre'}>
                              <span className={`${dragging ? 'hidden' : ''}`}><span
                                className={`!text-gradient`}>{t('placeholders.uploadResumeFile_0')}</span>
                            <span>{t('placeholders.uploadResumeFile_1')}</span></span>
                            {dragging
                              ? <p className={'first-letter:uppercase'}>{t('placeholders.uploadResumeFile_2')}</p>
                              : <span>{t('placeholders.uploadResumeFile_2')}</span>}
                          </div>}
                        className={'absolute cursor-pointer shadow-sm !border-solid !border-[#D0D5DD] bg-white'}
                        wrapperClassName={'w-auto h-auto !min-h-[5rem]'}
                        labelClassName={'relative'}

              />
            </div>

            <div className={'col-span-2 flex justify-center md:justify-start'}>
              <label className={'w-max flex items-center gap-3 select-none'}>
                <Checkbox name={'privacyPolicyChecked'} checked={privacyPolicyChecked} onChange={onChangePrivacyPolicy}
                          className={!fieldsValidity.privacyPolicyChecked.valid ? 'before:!border-red-400 animate-errorShake' : ''}
                          required />

                <span className={'text-lg text-gray'}>
                <span>{t('labels.agreement')}</span>
                <Link to={'/privacy-policy'} target={'_blank'} rel={'noopener noreferrer'} className={'underline'}>
                  <span className={'pointer-events-none'}>{t('labels.privacy_policy')}</span>
                </Link>
          </span>
              </label>
            </div>
            <div
              className={'col-span-2 flex m-auto mt-6 flex-col md:flex-row justify-center items-center md:justify-start'}>
              <ButtonFulfilled disabled={!privacyPolicyChecked || isLoading} type={'submit'}
                               className={'text-lg text-white font-semibold px-6 !py-4 before:transition-all disabled:text-gray disabled:before:opacity-50 shrink-0'}>
                {t('buttons.submit')}
              </ButtonFulfilled>
              <div className={'relative w-full flex items-start md:items-center justify-center'}>
                {!isLoading && success !== null &&
                <span className={`absolute animate-appearY ${success ? 'text-green-400' : 'text-red-400'}`}>
          {t(success ? 'labels.message_sent' : 'errors.message_error')}
        </span>}
              </div>
            </div>
            {linksCount > 0 &&
            <div className={'col-span-2'}>
              <div className={'my-6 flex items-center justify-center'}>
                <div className={'border-b w-full border-gray'} />
                <span className={'mx-2 font-Staatliches text-2xl select-none'}>OR</span>
                <div className={'border-b w-full border-gray'} />
              </div>
              <div className={'grid grid-cols-2 md:grid-cols-4 gap-8'}>
                {jobData.linkLinkedIn &&
                <LinkOutlined external to={jobData.linkLinkedIn} target={'_blank'}
                              className={`text-sm md:text-base transition-all flex items-center gap-2 px-6 py-4 before:transition-opacity col-span-2 ${linksCount === 1 ? 'md:col-start-2' : ''}`}>
                  <LinkedInIcon />
                  <span>{t('buttons.applyLinkedIn')}</span>
                </LinkOutlined>}

                {jobData.linkDjinni &&
                <LinkOutlined external to={jobData.linkDjinni} target={'_blank'}
                              className={`text-sm md:text-base transition-all flex items-center gap-2 px-6 py-4 before:transition-opacity col-span-2 ${linksCount === 1 ? 'md:col-start-2' : ''}`}>
                  <DjinniInIcon />
                  <span>{t('buttons.applyDjinni')}</span>
                </LinkOutlined>}
              </div>
            </div>}
          </div>
        </form>
      }
    </div>
  );
})
