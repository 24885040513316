import React, { useMemo } from 'react';
import Badge from '../../uiKit/badge';
import FlagsBlock from '../flagsBlock';
import CompatibilitiesBlock from '../compatibilitiesBlock';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TypeIcon } from '../../assets/icons/badges/console-icon.svg';
import { ReactComponent as DateIcon } from '../../assets/icons/badges/date-icon.svg';
import { ReactComponent as ThemeIcon } from '../../assets/icons/badges/theme-icon.svg';
import { ReactComponent as DeviceIcon } from '../../assets/icons/badges/device-icon.svg';
import { ReactComponent as FolderIcon } from '../../assets/icons/badges/folder-icon.svg';

export default React.memo(function GameBadgesGrid({ game, className = '' }) {
  const { t } = useTranslation('common');

  const formattedReleaseDate = useMemo(() => {
    const date = new Date(game.releaseDate);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;

    return `${month}/${day}/${year}`
  }, [game]);

  return (
    <div className={`grid grid-cols-2 md:grid-cols-10 gap-x-8 gap-y-6 ${className}`}>
      <Badge icon={<TypeIcon />} label={t('labels.game_type')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.gameTypes.join(', ')}</span>
      </Badge>
      <Badge icon={<DateIcon />} label={t('labels.release_date')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span
          className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{formattedReleaseDate}</span>
      </Badge>
      <Badge icon={<ThemeIcon />} label={t('labels.theme')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.theme}</span>
      </Badge>
      <Badge icon={<DeviceIcon />} label={t('labels.mobile')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span
          className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{t('placeholders.yes')}</span>
      </Badge>
      <Badge icon={<FolderIcon />} label={t('labels.size')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.size}</span>
      </Badge>
      <Badge label={t('labels.rtp')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.rtp}</span>
      </Badge>
      <Badge label={t('labels.volatility')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.volatility}</span>
      </Badge>
      <Badge label={t('labels.exposure')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.maxExpose}</span>
      </Badge>
      <Badge label={t('labels.orientation')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span
          className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.gameOrientation}</span>
      </Badge>
      <Badge label={t('labels.resolution')}
             className={'col-span-1 md:col-span-2 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span
          className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{game.gameResolution}</span>
      </Badge>
      <Badge label={t('labels.compatible')}
             className={'col-span-2 md:col-span-3 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <CompatibilitiesBlock />
      </Badge>
      <Badge label={t('labels.currencies')}
             className={'col-span-2 md:col-span-3 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <span
          className={'font-Staatliches text-2xl text-black uppercase whitespace-pre-line'}>{t('placeholders.supported_currencies')}</span>
      </Badge>
      <Badge label={t('labels.languages')}
             className={'col-span-2 md:col-span-4 bg-rose !rounded-2xl px-6 py-4 [&>label]:text-gray [&>label]:leading-none [&>label]:text-lg'}>
        <FlagsBlock flagsData={game.supportedLanguages} className={'[&_svg]:h-6 [&_svg]:rounded-sm gap-2'}
                    withHoverEffect />
      </Badge>
    </div>
  );
})
