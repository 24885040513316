import React from 'react';
import BaseModal from './base';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useStorage } from '../../utils/hooks/useStorage';
import eStorageKeys from '../../enums/storageKeys';

export default React.memo(function AgeRequirement() {
  const { t } = useTranslation('common');
  const [ageConfirm, setAgeConfirm] = useStorage(eStorageKeys.ESK_AGE_CONFIRM);
  const navigate = useNavigate();

  const confirm = () => {
    setAgeConfirm({ confirmed: true, timestamp: Date.now() });
  };

  const back = () => {
    navigate(-1);
  };

  const showBackButton = window.history.length > 1;

  return (
    <BaseModal isOpen={!ageConfirm}>
      <div className={'w-full max-w-[27rem] flex flex-col gap-10'}>
        <div className={'w-full flex flex-col gap-4 items-center text-center'}>
          <Title className={'text-4xl md:text-4xl !leading-tight'}>
            {t('titles.age_requirement')}
          </Title>
          <Paragraph className={'text-lg'}>
            {t('descriptions.age_requirement')}
          </Paragraph>
        </div>

        <div className={'w-full flex flex-col gap-3'}>
          <ButtonFulfilled onClick={confirm} className={'text-white text-lg font-semibold'}>
            {t('buttons.age_confirm')}
          </ButtonFulfilled>
          {showBackButton && <ButtonOutlined onClick={back} className={'text-lg font-semibold'}>
            {t('buttons.age_reject')}
          </ButtonOutlined>}
        </div>
      </div>
    </BaseModal>
  );
})
