import React from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import Skeleton from './mainCardSkeleton';
import { Link } from 'react-router-dom';
import { formatter } from '../../utils/dateFormatter';
import { useImagePreload } from '../../utils/hooks/useImagePreload';

export default React.memo(function MainNewsBlock({ data }) {
  const loaded = useImagePreload(data?.iconBig);

  return !data || !loaded.includes(data.iconBig) ?
    <Skeleton /> :
    <Link to={data.urlName} className={'w-full flex flex-col gap-10'}>
      <div className={'w-full rounded-3xl overflow-hidden'}>
        <img src={data.iconBig} alt={'preview'} className={'w-full object-cover'} />
      </div>

      <div className={'w-full flex flex-col gap-3'}>
        <span className={'text-gray text-sm'}>{formatter.format(new Date(data.createdDate))}</span>
        <Title className={'!text-left'}>{data.title}</Title>
        <Paragraph className={'text-lg'}>{data.description}</Paragraph>
      </div>
    </Link>
})
