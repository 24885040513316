import React, { useEffect, useMemo, useState } from 'react';
import Avatar from '../../uiKit/avatar';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import LatestNews from '../infoBlocks/newsBlock';
import PageConstructorViewer from '../../uiKit/pageConstructor/preview';
import { Navigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { copyToClipBoard } from '../../utils/copyToClipBoard';
import { useGetNewsQuery } from '../../redux/api/common';

import { ReactComponent as CopyIcon } from '../../assets/icons/system/copy-icon.svg';
import { useMetaTags } from '../../utils/hooks/useMetaTags';

export default React.memo(function NewsPage() {
  const params = useParams();
  const { t } = useTranslation('common');
  const [copied, setCopied] = useState(false);
  const { data: news, isFetching } = useGetNewsQuery(params.id);

  useMetaTags([
    { name: 'description', content: news?.description || '' },
    { property: 'og:title', content: news?.title || '' },
    { property: 'og:description', content: news?.description.slice(0, 150).concat('...') || '' },
    { property: 'og:image', content: news?.iconBig || ''},
    { name: 'twitter:title', content: news?.title || '' },
    { name: 'twitter:description', content: news?.description.slice(0, 150).concat('...') || '' },
    { name: 'twitter:image', content: news?.iconBig || ''}
  ]);

  useEffect(() => {
    if (!copied) return;

    const timeout = setTimeout(() => {
      setCopied(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    }
  }, [copied]);

  const viewerData = useMemo(() => {
    if (!news) return null;

    try {
      return JSON.parse(news.article);
    } catch (e) {
      console.error(e);
      return null;
    }
  }, [news]);

  const copyLink = async () => {
    const success = await copyToClipBoard(window.location.href);
    if (success) setCopied(true);
  };

  return isFetching ? null : !news ? <Navigate to={'/404'} replace={true} /> : (
    <>
      <div className={'w-full flex flex-col items-center px-6 pb-32'}>
        {viewerData &&
        <>
          <PageConstructorViewer data={viewerData} />

          <div className={'w-full max-w-3xl py-4'}>
            <hr />
            <div className={'w-full flex justify-end mt-10'}>
              {/*<Avatar src={news.authorIcon} name={news.author} label={t('labels.author')} />*/}
              <div className={'relative flex justify-center items-center'}>
                <ButtonOutlined onClick={copyLink} className={'px-6 inline-flex items-center gap-2 text-black whitespace-nowrap'}>
                  <CopyIcon /> Copy link
                </ButtonOutlined>
                {copied && <span
                  className={'absolute top-[calc(100%_+_0.5rem)] px-4 text-gray rounded-sm bg-white drop-shadow animate-appearY'}>
                  {t('labels.copied')}
                </span>}
              </div>
            </div>
          </div>
        </>}
      </div>

      <div className={'w-full flex justify-center py-32 px-6 bg-gradient-to-b from-pink-100/40 to-white/0.001'}>
        <LatestNews excludeId={news?.id} />
      </div>
    </>
  );
})
