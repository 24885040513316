import { useEffect, useRef, useState } from 'react';
import { getBreakpointValue, getCurrentBreakpoint } from '../getBreakpoint';

const useBreakpoint = (callback) => {
  const callbackRef = useRef(callback);
  const breakPointKey = getCurrentBreakpoint();
  const [breakpoint, setBreakpoint] = useState({
    key: breakPointKey,
    value: getBreakpointValue(breakPointKey),
  });

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const listener = () => {
      const breakPointKey = getCurrentBreakpoint();

      const currentBreakpoint = {
        key: breakPointKey,
        value: getBreakpointValue(breakPointKey),
      };

      if (breakpoint.key !== currentBreakpoint.key || breakpoint.value !== currentBreakpoint.value) {
        setBreakpoint(currentBreakpoint);
        callbackRef.current && callbackRef.current(currentBreakpoint);
      }
    };

    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    }
  }, [breakpoint]);

  return breakpoint;
};

export {
  useBreakpoint,
}
