import React from 'react';
import searchIcon from '../../assets/icons/system/search-icon.png';

export default React.memo(function SearchInput({ className = '', ...props }) {
  return (
    <div className={'relative w-full'}>
      <img src={searchIcon} alt={'search'} className={'h-[28%] absolute top-1/2 translate-x-full -translate-y-1/2'}/>
      <input {...props} className={`w-full h-16 rounded-2xl bg-white border border-[#D0D5DD] outline-none py-4 pl-11 pr-4 shadow-sm ${className}`} />
    </div>
  );
})
