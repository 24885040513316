import React from 'react';
import PageTitleLayout from '../../uiKit/pageTitleLayout';
import Paragraph from '../../uiKit/typography/paragraph';
import JobList from '../jobList';
import { useTranslation } from 'react-i18next';

export default React.memo(function JobsInfoPage() {
  const { t } = useTranslation('common');

  return (
    <>
      <div className={'w-full bg-black flex flex-col items-center'}>
        <PageTitleLayout title={t('titles.jobs_page')} className={'w-full flex flex-col items-center gap-8 py-24 px-4 mt-24 md:mt-48 max-w-2xl'}>
          <Paragraph className={'!text-white'}>
            {t('descriptions.jobs_page')}
          </Paragraph>
        </PageTitleLayout>
      </div>

      <div className={'w-full flex flex-col items-center py-20 px-4 md:py-32'}>
        <JobList />
      </div>
    </>
  );
})
