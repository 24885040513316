import { useEffect, useState } from 'react';
import staatliches from '../../assets/fonts/staatliches/regular.ttf';
import gilroyRegular from '../../assets/fonts/gilroy/regular.woff';
import gilroyRegular2 from '../../assets/fonts/gilroy/regular.woff2';
import gilroyMedium from '../../assets/fonts/gilroy/medium.woff';
import gilroyMedium2 from '../../assets/fonts/gilroy/medium.woff2';
import gilroySemi from '../../assets/fonts/gilroy/semi-bold.woff';
import gilroySemi2 from '../../assets/fonts/gilroy/semi-bold.woff2';

const useFontsPreloader = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    try {
      const staatlichesReg = new FontFace('Staatliches', `url(${staatliches})`, { weight: 400 });
      const gilroyReg = new FontFace('Gilroy', `url(${gilroyRegular}), url(${gilroyRegular2}) `, { weight: 400 });
      const gilroyMed = new FontFace('Gilroy', `url(${gilroyMedium}), url(${gilroyMedium2})`, { weight: 500 });
      const gilroySem = new FontFace('Gilroy', `url(${gilroySemi}), url(${gilroySemi2})`, { weight: 600 });

      const fonts = [staatlichesReg, gilroyReg, gilroyMed, gilroySem];

      fonts.forEach(font => {
        document.fonts.add(font);
        font.load();
      });
      document.fonts.ready.then(() => {
        setLoaded(true);
      });
    } catch (e) {
      console.error(e);
      setLoaded(true);
    }
  }, []);

  return loaded;
};

export {
  useFontsPreloader,
}
