import React from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { BlockPicker, ChromePicker } from 'react-color';
import { Range, Checkbox, Radio } from '../../inputs';

export default React.memo(function ContainerSettings() {
  const { query } = useEditor();
  const { actions: { setProp }, id, paddingTop, paddingBottom, paddingLeft, paddingRight, background, borderRadius, align, alignSelf, direction, spacing, width } = useNode((node) => ({
    paddingTop: node.data.props.paddingTop,
    paddingBottom: node.data.props.paddingBottom,
    paddingLeft: node.data.props.paddingLeft,
    paddingRight: node.data.props.paddingRight,
    background: node.data.props.background,
    borderRadius: node.data.props.borderRadius,
    align: node.data.props.align,
    alignSelf: node.data.props.alignSelf,
    direction: node.data.props.direction,
    spacing: node.data.props.spacing,
    width: node.data.props.width,
  }));

  const isRoot = query.node(id).isRoot();

  const changeColor = (color) => {
    setProp(props => props.background = color.rgb)
  };

  const changeAlignItems = (e) => {
    setProp(props => props.align = e.target.value);
  };

  const changeAlignSelf = (e) => {
    setProp(props => props.alignSelf = e.target.value);
  };

  const changeDirection = (e) => {
    setProp(props => props.direction = e.target.value);
  };

  const isFullWidthChecked = /%$/.test(width);

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'w-full flex flex-col gap-1 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Background</span>
        <ChromePicker width={'100%'} color={background} onChange={changeColor} onChangeComplete={changeColor} />
      </div>

      {!isRoot && <div className={'w-full flex flex-col gap-4 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Width: ${width} ${isFullWidthChecked ? '' : 'rem'}`}</span>
        <div className={'w-full flex flex-col gap-3'}>
          <Range value={isFullWidthChecked ? 100 : width} step={0.1}
                 onChange={e => setProp(props => props.width = e.target.value)} />
        </div>
        <div>
          <label className={'flex items-center gap-3'}>
            <Checkbox checked={isFullWidthChecked}
                      onChange={(e) => setProp(props => props.width = e.target.checked ? '100%' : 100)} />
            <span>Max available</span>
          </label>
        </div>
      </div>}

      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Direction</span>
        <div className={'w-full flex flex-col gap-1'}>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeDirection} name={'direction'} value={'row'}
                   checked={direction === 'row'} />
            <span>Row</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeDirection} name={'direction'} value={'column'}
                   checked={direction === 'column'} />
            <span>Column</span>
          </label>
        </div>
      </div>

      {!isRoot && <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Self align</span>
        <div className={'w-full flex flex-col gap-1'}>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignSelf} name={'alignSelf'} value={'flex-start'}
                   checked={alignSelf === 'flex-start'} />
            <span>Start</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignSelf} name={'alignSelf'} value={'center'}
                   checked={alignSelf === 'center'} />
            <span>Center</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignSelf} name={'alignSelf'} value={'flex-end'}
                   checked={alignSelf === 'flex-end'} />
            <span>End</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignSelf} name={'alignSelf'} value={'unset'}
                   checked={alignSelf === 'unset'} />
            <span>None</span>
          </label>
        </div>
      </div>}

      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Items align</span>
        <div className={'w-full flex flex-col gap-1'}>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignItems} name={'alignItems'} value={'flex-start'}
                   checked={align === 'flex-start'} />
            <span>Start</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignItems} name={'alignItems'} value={'center'}
                   checked={align === 'center'} />
            <span>Center</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignItems} name={'alignItems'} value={'flex-end'}
                   checked={align === 'flex-end'} />
            <span>End</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlignItems} name={'alignItems'} value={'unset'}
                   checked={align === 'unset'} />
            <span>None</span>
          </label>
        </div>
      </div>

      <div className={'w-full flex flex-col gap-4 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Spacing: ${spacing} rem`}</span>
        <div className={'w-full flex flex-col gap-3'}>
          <Range value={spacing} step={0.1} max={6}
                 onChange={e => setProp(props => props.spacing = e.target.value)} />
        </div>
      </div>

      <div className={'w-full flex flex-col gap-4 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Padding</span>
        <div className={'w-full flex flex-col gap-3'}>
          <label className={'w-full flex flex-col gap-1'}>
            <span>{`Top: ${paddingTop} rem`}</span>
            <Range value={paddingTop} step={0.1} max={10}
                   onChange={e => setProp(props => props.paddingTop = e.target.value)} />
          </label>
          <label className={'w-full flex flex-col gap-1'}>
            <span>{`Bottom: ${paddingBottom} rem`}</span>
            <Range value={paddingBottom} step={0.1} max={10}
                   onChange={e => setProp(props => props.paddingBottom = e.target.value)} />
          </label>
          <label className={'w-full flex flex-col gap-1'}>
            <span>{`Left: ${paddingLeft} rem`}</span>
            <Range value={paddingLeft} step={0.1} max={10}
                   onChange={e => setProp(props => props.paddingLeft = e.target.value)} />
          </label>
          <label className={'w-full flex flex-col gap-1'}>
            <span>{`Right: ${paddingRight} rem`}</span>
            <Range value={paddingRight} step={0.1} max={10}
                   onChange={e => setProp(props => props.paddingRight = e.target.value)} />
          </label>
        </div>
      </div>

      <div className={'w-full flex flex-col gap-4 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Border radius: ${borderRadius} rem`}</span>
        <div className={'w-full flex flex-col gap-3'}>
          <Range value={borderRadius} step={0.1} max={6}
                 onChange={e => setProp(props => props.borderRadius = e.target.value)} />
        </div>
      </div>
    </div>
  );
})
