import React from 'react';
import Header from '../header';
import Footer from '../footer';
import AgeRequirementModal from '../modals/ageRequirement';
import CookiesModal from '../modals/cookies';
import SideBar from '../sidebar';
import { Outlet } from 'react-router-dom';

function MainLayout() {
  return (
    <div className={'flex flex-col items-center w-full overflow-hidden'}>
      <AgeRequirementModal />
      <CookiesModal />

      <Header />
      <SideBar />

      <div className={'w-full min-h-screen flex flex-col items-center'}>
        <Outlet />
      </div>

      <Footer />
    </div>
  );
}

export default MainLayout;
