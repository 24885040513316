import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export default React.memo(function AccordionItem({ item, isExpanded, toggleExpand, expandDuration = 75, className = '' }) {
  const [expandOffset, setExpandOffset] = useState(0);
  const [subElementHeight, setSubElementHeight] = useState(0);
  const [fontSize, setFontSize] = useState(parseFloat(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size')));
  const infoRef = useRef(null);

  const resizeListener = useCallback(() => {
    const currentFontSize = window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size');
    setFontSize(parseFloat(currentFontSize));

    setSubElementHeight(infoRef.current.offsetHeight);
  },  []);

  useEffect(() => {
    setSubElementHeight(infoRef.current.offsetHeight);
  }, [isExpanded]);

  useEffect(() => {
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [resizeListener]);

  useEffect(() => {
    if (isExpanded) {
      setExpandOffset(subElementHeight / fontSize);
    } else {
      setExpandOffset(0);
    }
  }, [isExpanded, subElementHeight, fontSize]);

  const onBlockClick = () => {
    if (!item.hasOwnProperty('id')) throw new Error(`Every item must have an 'id' property`);
    toggleExpand(item.id);
  };

  const mainEl = useMemo(() => {
    return typeof item.mainElement === 'function' ? item.mainElement(isExpanded) : item.mainElement;
  }, [item.mainElement, isExpanded]);

  const subEl = useMemo(() => {
    return typeof item.subElement === 'function' ? item.subElement(isExpanded) : item.subElement;
  }, [item.subElement, isExpanded]);

  return (
    <div className={`w-full overflow-hidden transition-all ${className}`}>
      <div onClick={onBlockClick} className={'relative z-1 cursor-pointer'}>
        {mainEl}
      </div>

      <div style={{ maxHeight: `${expandOffset}rem`, transitionDuration: `${expandDuration}ms` }} className={'transition-all'}>
        <div ref={infoRef} className={'relative z-0'}>
          {subEl}
        </div>
      </div>
    </div>
  );
})
