import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import NewsCards from './cards';
import { OverflowPagination } from '../../uiKit/pagination';
import { ArrowsPagination } from '../../uiKit/pagination';
import { useQueryParams } from '../../utils/hooks/useQueryParams';
import { useGetMainNewsQuery, useGetNewsListQuery } from '../../redux/api/common';
import { useScrollTo } from '../../utils/hooks/useScrollTo';

export default React.memo(function NewsList() {
  const [searchParams, setSearchParams] = useQueryParams({ page: page => Number(page) });
  const { data = { news: [], pagesCount: 0 }, isFetching } = useGetNewsListQuery(searchParams.page || 1);
  const { data: mainNews, isFetching: isMainNewsFetching } = useGetMainNewsQuery();
  const containerRef = useRef();
  const scrollTo = useScrollTo();

  useEffect(() => {
    if (!isFetching && data.pagesCount < searchParams.page) setSearchParams({ page: data.pagesCount });
  }, [searchParams.page, data.pagesCount, isFetching]);

  const list = useMemo(() => {
    return data.news.map(item => ({
      imageSrc: item.icon,
      title: item.title,
      text: item.description,
      link: `${item.urlName}`,
      id: item.id
    }));
  }, [data]);

  const mobileList = useMemo(() => {
    let result = [];
    if (mainNews && (!searchParams.hasOwnProperty('page') || searchParams.page === 1)) result.push({
      imageSrc: mainNews.icon,
      title: mainNews.title,
      text: mainNews.description,
      link: `${mainNews.urlName}`,
      id: mainNews.id
    });

    if (result.length) {
      result.push(...list);
    } else {
      result = list;
    }

    return result;
  }, [mainNews, data]);

  const setPage = useCallback((page) => {
    scrollTo(containerRef.current, 'smooth');
    setSearchParams({ page });
  }, []);

  return (
    <div ref={containerRef}>
      <div className={'hidden md:flex w-full flex-col md:gap-16'}>
        <NewsCards cardsData={list} />

        <div>
          <OverflowPagination page={searchParams.page || 1} setPage={setPage} totalPagesCount={data.pagesCount} />
        </div>
      </div>

      <div className={'md:hidden w-full flex flex-col gap-12'}>
        <NewsCards cardsData={mobileList} />

        <div className={'block md:hidden'}>
          <ArrowsPagination page={searchParams.page || 1} setPage={setPage} totalPagesCount={data.pagesCount} />
        </div>
      </div>

    </div>
  );
})
