import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getFromStorage } from '../../utils/hooks/useStorage';
import eStorageKeys from '../../enums/storageKeys';

const isLocalEnv = /localhost|192\.168\./.test(window.location.hostname);

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: isLocalEnv ? 'https://dev.onlyplay.net/api' : '/api',
    // baseUrl: isLocalEnv ? 'http://localhost:3001/api' : '/api',
    prepareHeaders: (headers, { getState }) => {
      const token = getFromStorage(eStorageKeys.ESK_ADMIN_TOKEN);

      if (isLocalEnv) {
        headers.set('authorization', process.env.REACT_APP_BASIC_AUTH);
      }

      if (token) {
        headers.set('token', token);
      }

      return headers
    },
  }),
  tagTypes: [
    'GamesData',
    'AdminGamesData',
    'Game',
    'AdminGame',
    'UpcomingGame',
    'AdminUpcomingGame',
    'Dashboard',
    'News',
    'AdminNews',
    'Auth',
    'Vacancies',
    'AdminVacancies',
    'Partners',
    'AdminPartners',
    'MediaPartners',
    'AdminMediaPartners',
    'Languages',
    'AdminUsers',
  ],
  endpoints: builder => ({})
});
