import React, { useId } from 'react';

export default React.memo(function Checkbox({ className, ...props }) {
  const id = useId();

  return (
    <>
      <input id={id} type={'checkbox'} {...props} className={`peer hidden`} />
      {/*<label htmlFor={id} className={`flex select-none before:transition-all before:cursor-pointer before:inline-block before:w-5 before:h-5 before:border before:border-gray-300 before:rounded-md peer-checked:before:bg-gradient-to-br peer-checked:before:from-[#FF4D4D] peer-checked:before:to-[#FF971D] ${className}`} />*/}
      <label htmlFor={id} className={`flex select-none before:transition-all cursor-pointer before:inline-block before:w-5 before:h-5 before:border before:border-gray-300 before:rounded-md peer-checked:w-5 peer-checked:h-5 peer-checked:outlined_gradient peer-checked:before:border-0 peer-checked:after:bg-checkMark peer-checked:after:bg-center peer-checked:after:bg-no-repeat peer-checked:after:h-full peer-checked:after:w-full ${className}`} />
    </>
  );
})
