import React, { useMemo } from 'react';
import Navigation from '../navigation';
import CollaborateBlock from './collaborationBlock';
import NewsSubscription from './subscriptionBlock';
import { useLocation, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../logo';

import { ReactComponent as LinkedinIcon } from '../../assets/icons/socials/linkedin-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/icons/socials/youtube-icon.svg';
import { ReactComponent as AgeIcon } from '../../assets/icons/icon18.svg';

export default React.memo(function Footer() {
  const location = useLocation();
  const params = useParams();
  const { t } = useTranslation('common');

  const ContactsBlock = useMemo(() => {
    let block = CollaborateBlock;
    if (/\/news\//.test(location.pathname) && params.hasOwnProperty('id')) block = NewsSubscription;
    return block;
  }, [location.pathname]);

  return (
    <section className={'flex items-center flex-col gap-20 w-full mb-16'}>
      <ContactsBlock />

      <div className={'w-full flex flex-col items-center px-4 gap-10'}>
        <div className={'w-full flex flex-col items-center gap-8'}>
          <Logo />

          <div className={'w-full flex md:flex-col [&>*]:flex-1 gap-8'}>
            <div>
              <Navigation
                className={'[&_a]:!text-black [&>ul]:flex-col [&>ul]:items-start [&>ul]:gap-3 [&>ul]:gap-x-28 md:[&>ul]:flex-row md:[&>ul]:gap-16'} />
            </div>
            <div className={'w-full h-max flex justify-center'}>
              <ul
                className={'grid grid-cols-1 md:grid-cols-[repeat(3,_minmax(0,_max-content))] items-center gap-3 md:gap-6 w-full md:w-max'}>
                <li className={'order-2 md:order-1'}>
                  <a target={'_blank'} rel={'noopener noreferrer'} href={'https://www.linkedin.com/company/onlyplay/'}>
                    <LinkedinIcon />
                  </a>
                </li>
                <li className={'order-3 md:order-2'}>
                  <a href={'https://www.youtube.com/channel/UClUIYN_8h9lot6LVEac_GcA/featured'} target={'_blank'}
                     rel={'noopener noreferrer'}>
                    <YoutubeIcon />
                  </a>
                </li>
                <li className={'order-1 md:order-3'}>
                  <AgeIcon className={'h-7 md:h-full w-auto'} />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div
          className={'w-full flex flex-col md:flex-row justify-center p-4 max-w-6xl pt-8 border-t border-gray-200 gap-8 text-center text-gray text-base'}>
          <span>&copy; 2022 Onlyplay. {t('labels.rights_reserved')}</span>
          {/*<span>{t('labels.licensed')}</span>*/}
          <div className={'flex gap-4 whitespace-nowrap justify-center'}>
            {/*<Link to={'/terms'}>*/}
            {/*  {t('navigation.terms')}*/}
            {/*</Link>*/}
            <Link to={'/privacy-policy'}>
              {t('navigation.privacy_policy')}
            </Link>
            <Link to={'/cookies'}>
              {t('navigation.cookies')}
            </Link>
          </div>
        </div>

      </div>
    </section>
  );
})
