import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './api';
import { appSlice } from './slices/appSlice';
import { modalsSlice } from './slices/modalsSlice';

export default configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [modalsSlice.name]: modalsSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(apiSlice.middleware)
})
