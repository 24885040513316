import { useCallback, useEffect, useState } from 'react';

const useStorage = (key) => {

  const getData = useCallback(() => {
    let data = null;

    try {
      data = localStorage.getItem(key);

      try {
        data = JSON.parse(data);
      } catch (e) {
        console.error(e);
      }

    } catch (e) {
      console.error(e);
    }

    return data;
  }, []);

  const [data, setData] = useState(getData());

  const updateData = (data) => {
    if (typeof data === 'object') data = JSON.stringify(data);

    try {
      localStorage.setItem(key, data);
      window.dispatchEvent(new StorageEvent('storage', { key }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const listener = (e) => {
      if (e.key !== key && e.key !== null) return;

      setData(getData());
    };

    window.addEventListener('storage', listener);
    return () => {
      window.removeEventListener('storage', listener);
    }
  }, [getData]);

  return [data, updateData];
};

const setToStorage = (key, value) => {
  localStorage.setItem(key, value);
};

const getFromStorage = (key) => {
  return localStorage.getItem(key);
};

export {
  useStorage,
  setToStorage,
  getFromStorage,
}
