import React from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMetaTags } from '../../utils/hooks/useMetaTags';

export default React.memo(function Page404() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  useMetaTags([{ name: 'prerender-status-code', content: '404' }]);

  const showBackButton = window.history.length > 1;

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className={'w-full flex flex-col gap-12 py-28 px-4'}>

      <div className={'w-full flex flex-col gap-6'}>
        <Title>
          {t('titles.404_page')}
        </Title>
        <Paragraph className={'text-center !text-2xl'}>
          {t('descriptions.404_page')}
        </Paragraph>
      </div>

      <div className={'flex flex-wrap justify-center items-center gap-3'}>
        {showBackButton &&
        <ButtonOutlined onClick={goBack} className={'px-6'}>
          {t('buttons.go_back')}
        </ButtonOutlined>}

        <ButtonFulfilled onClick={goHome} className={'px-6 text-white'}>
          {t('buttons.go_home')}
        </ButtonFulfilled>
      </div>

    </div>
  );
})
