import React from 'react';

export default React.memo(function PageTitleLayout({ title, className = '', classNameTitle = '', children }) {
  return (
    <div className={`text-center ${className}`}>
      <h1 className={`uppercase text-white text-6xl md:text-9xl font-Staatliches whitespace-pre-line select-none ${classNameTitle}`}>{title}</h1>
      {children}
    </div>
  );
});
