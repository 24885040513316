import React from 'react';

export default React.memo(function PartnerItem({ partnerData, linkActive, alt = 'partner', className = '', classNamesImg = '' }) {

  const containerClassNames = `flex items-center justify-center ${className}`;

  const Container = ({...props}) => {
    return partnerData.link && linkActive ?
      <a href={partnerData.link} target="_blank" className={containerClassNames} {...props} /> :
      <div className={containerClassNames} {...props} />;
  };

  return (
    <Container>
      <img src={partnerData.icon} alt={alt}
           className={`h-full w-full object-contain ${classNamesImg}`} draggable={false} />
    </Container>
  );
});
