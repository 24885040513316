import React from 'react';
import { ReactComponent as BackIcon } from '../../assets/icons/system/back-icon.svg';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default React.memo(function BackButton({ localizationKey, link, className = '' }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const back = () => {
    navigate(link);
  };

  return (
    <button onClick={back} className={`flex items-center gap-3 blurred rounded-2xl p-4 ${className}`}>
      <BackIcon />
      <span className={'hidden md:inline-block text-white text-sm'}>
        {t(`common:buttons.go_back_to`, { to: t(localizationKey) })}
      </span>
    </button>
  );
})
