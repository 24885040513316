import React, { useCallback, useLayoutEffect, useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import TextSettings from '../settings/textSettings';
import ContentEditable from 'react-contenteditable';
import Border from '../utils/border';

export const Title = React.memo(function ({ text, color, textAlign, fontSize }) {
  const { editable } = useEditor((state) => ({
    editable: state.options.enabled,
  }));
  const { connectors: { connect, drag }, actions: { setProp }, hasSelectedNode, hasHoveredNode, hasDraggedNode } = useNode((state) => {
    return {
      hasSelectedNode: state.events.selected,
      hasHoveredNode: state.events.hovered,
      hasDraggedNode: state.events.dragged
    }
  });

  const textElRef = useRef(null);

  return (
    <div className={'relative'} ref={ref => connect(drag(ref))}>
      <ContentEditable
        innerRef={textElRef}
        disabled={!editable}
        html={text}
        onChange={e => setProp((prop) => (prop.text = e.target.value.replace(/(style|class)=[^>]+("|})/g, "")), 500)}
        tagName="h2"
        className={'whitespace-pre-line font-Staatliches leading-tight break-words'}
        style={{
          color: typeof color === 'object' ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})` : color,
          fontSize: `${fontSize}rem`,
          textAlign }}
      />

      <Border highlight={hasSelectedNode || hasHoveredNode} />
    </div>
  );
});

const defaultProps = {
  text: 'Title',
  fontSize: 2,
  color: '#212121',
  textAlign: 'left',
};

Title.craft = {
  props: defaultProps,
  related: {
    settings: () => <TextSettings options={{ fontSize: { step: 0.1, min: 1, max: 6 } }} />
  }
};
