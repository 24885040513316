import React, { useEffect, useRef } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import VideoSettings from '../settings/videoSettings';
import Border from '../utils/border';
import YouTube, { YouTubeProps } from 'react-youtube';

export const Video = React.memo(function (props) {
  const { src = 'https://youtu.be/JOv-D8YQOXE', videoId, width, borderRadius, } = props;
  const { enabled } = useEditor((state) => ({
    enabled: state.options.enabled,
  }));
  const {
    connectors: { connect, select, drag },
    hasSelectedNode,
    hasHoveredNode,
    hasDraggedNode
  } = useNode((state) => {
    return {
      hasSelectedNode: state.events.selected,
      hasHoveredNode: state.events.hovered,
      hasDraggedNode: state.events.dragged,
      selected: state.events.selected,
    }
  });

  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.stopVideo();
  }

  const opts = {
    height: '100%',
    width: '100%',

    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      rel: 0,
      frameborder: 2,
      controls: enabled ? 0 : 1,
    },
    enablejsapi: 1,

  };
  return (
    <div id={'parentId'} ref={ref => connect(drag(ref))}
         className={`relative w-full flex items-center justify-center shrink-0 ${videoId ? '' : 'p-5'}`}
         style={{ width: `${width}%` }}>

      {videoId && <YouTube
        iframeClassName={enabled ? 'pointer-events-none': 'pointer-events-auto'}
        className={'w-full h-full aspect-video'} opts={opts} style={{ borderRadius: `${borderRadius}rem` }}
        videoId={videoId} onReady={onPlayerReady} />}
      <Border highlight={hasSelectedNode || hasHoveredNode} />
    </div>
  );
});

const defaultProps = {
  width: 100,
  videoId: '',
};

Video.craft = {
  props: defaultProps,
  related: {
    settings: VideoSettings
  }
};
