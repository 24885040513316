import React from 'react';
import InfiniteSlider from '../../../uiKit/carousel/infinite';
import ShadowCard from '../../../uiKit/cards/shadowCard';
import { useTranslation } from 'react-i18next';

import { ReactComponent as WinIcon } from '../../../assets/icons/compatibilities/color/win-icon.svg';
import { ReactComponent as HtmlIcon } from '../../../assets/icons/compatibilities/color/html-icon.svg';
import { ReactComponent as IosIcon } from '../../../assets/icons/compatibilities/color/ios-icon.svg';
import { ReactComponent as MacIcon } from '../../../assets/icons/compatibilities/color/mac-icon.svg';
import { ReactComponent as AndroidIcon } from '../../../assets/icons/compatibilities/color/android-icon.svg';

export default React.memo(function DeviceCompatibility({ slidesPerView = 3, className = '' }) {
  const { t } = useTranslation('common');

  return (
    <ShadowCard className={className}>
      <div className={'w-full h-full flex flex-col gap-12 md:gap-4 justify-between'}>
        <div className={'px-6'}>
          <span className={'text-xl md:text-base uppercase whitespace-pre-line'}>
            {t('cards.descriptions.compatibility')}
          </span>
        </div>

        <div className={'w-full flex flex-col justify-center md:flex-row items-center flex-wrap gap-6'}>
          <InfiniteSlider slidesPerView={slidesPerView} containerClassName={''} speed={10} direction={'right'}
                          className={'[&_svg]:object-contain [&_svg]:w-24 [&_svg]:h-24 [&_svg]:md:w-14 [&_svg]:md:h-14'}>
            <WinIcon />
            <HtmlIcon />
            <IosIcon/>
            <MacIcon/>
            <AndroidIcon/>
          </InfiniteSlider>
        </div>

      </div>
    </ShadowCard>
  );
})
