import React, { useMemo } from 'react';
import ButtonOutlined from '../../uiKit/buttons/outlined';

import { ReactComponent as ArrowLeft } from '../../assets/icons/system/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/system/arrow-right.svg';

export default React.memo(function OverflowPagination({ page, setPage, totalPagesCount, className = '' }) {
  const pagesOffset = 2;
  const placeholder = '...';

  const pages = useMemo(() => {
    const result = [];

    for (let i = 1; i <= totalPagesCount; i++) {
      if (i <= pagesOffset + 1 || i >= totalPagesCount - pagesOffset) {
        result.push(i);
        continue;
      }
      if (page - pagesOffset < i && page + pagesOffset > i) {
        result.push(i);
        continue;
      }

      if (result[result.length - 1] === placeholder) continue;
      result.push(placeholder);
    }

    return result;
  }, [page, totalPagesCount]);

  const changePage = (e) => {
    const page = e.currentTarget.dataset.page;
    setPage(page);
  };

  const goToPreviousPage = () => {
    setPage(page - 1);
  };

  const goToNextPage = () => {
    setPage(page + 1);
  };

  return (
    <div className={`w-full flex items-center justify-between [&>*]:shrink-0 select-none ${className}`}>
      <ButtonOutlined onClick={goToPreviousPage} disabled={page <= 1}
                      className={`transition-all flex items-center gap-2 px-6 py-4 before:transition-opacity ${page > 1 ? 'before:opacity-1' : 'opacity-50 before:opacity-0'}`}>
        <ArrowLeft />
        <span>Previous</span>
      </ButtonOutlined>

      <div className={'flex items-center justify-center'}>
        {pages.map((item, index) => item === placeholder ?
          <span key={item + index} className={'text-gray text-sm'}>{item}</span>
          :
          <button key={item} onClick={changePage}
                  className={`transition-all p-5 relative ${page === item ? 'text-black text-lg outlined_gradient before:rounded-full' : 'text-sm text-gray'}`}
                  data-page={item}>
            <span className={'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'}>
              {item}
            </span>
          </button>
        )}
      </div>

      <ButtonOutlined onClick={goToNextPage} disabled={totalPagesCount <= page}
                      className={`transition-all flex items-center gap-2 px-6 py-4 before:transition-opacity ${totalPagesCount > page ? 'before:opacity-1' : 'opacity-50 before:opacity-0'}`}>
        <span>Next</span>
        <ArrowRight />
      </ButtonOutlined>
    </div>
  );
})
