import React from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export default React.memo(function ErrorFallback() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const location = useLocation();
  console.log('render');
  const showBackButton = window.history.length > 1;
  const showHomeButton = location.pathname !== '/';

  const goBack = () => {
    navigate(-1);
  };

  const goHome = () => {
    navigate('/');
  };

  return (
    <div className={'w-full h-full min-h-[100vh] flex flex-col justify-center gap-12 py-28 px-4'}>

      <div className={'w-full flex flex-col gap-6'}>
        <Title>
          {t('titles.oops')}
        </Title>
        <Paragraph className={'text-center !text-2xl'}>
          {t('descriptions.oops')}
        </Paragraph>
      </div>

      <div className={'flex flex-wrap justify-center items-center gap-3'}>
        {showBackButton &&
        <ButtonOutlined onClick={goBack} className={'px-6'}>
          {t('buttons.go_back')}
        </ButtonOutlined>}

        {showHomeButton &&
        <ButtonFulfilled onClick={goHome} className={'px-6 text-white'}>
          {t('buttons.go_home')}
        </ButtonFulfilled>}
      </div>

    </div>
  );
})
