import React from 'react';

export default React.memo(function GameCard({ game }) {

  return (
    <div
      className={`relative flex w-full h-full rounded-3xl overflow-hidden select-none transition-all`}>
      <div className={'flex items-center justify-center w-full h-full'}>
        <img src={game.iconPreview} alt={`${game.name} icon`} className={'w-full h-full object-cover'} draggable={false}/>
      </div>

      <div
        className={'absolute max-h-full w-full bottom-0 left-0 right-0 px-4 py-6 bg-gradient-to-t from-[#141414] to-[rgba(20,_20,_20,_0)]'}>
        <div
          className={'flex flex-col items-center w-full h-full p-4 bg-neutral-900/[0.7] rounded-3xl overflow-hidden'}>

          <div className={'flex w-full gap-4'}>
            <div className={'w-14 h-14 shrink-0 rounded-lg overflow-hidden'}>
              <img src={game.iconSquare} alt={`${game.name} icon`} className={'h-full w-full object-contain'} />
            </div>

            <div className={'flex flex-col gap-2'}>
              <div>
                <span className={'text-2xl text-white font-Staatliches leading-[88%] line-clamp-1'}>
                  {game.name}
                </span>
              </div>
              <div>
                <span className={'text-xs text-neutral line-clamp-2'}>
                {game.overview}
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
})
