import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default React.memo(function Navigation({ callback, className = '', activeLinkClassName = '' }) {
  const { t, i18n } = useTranslation('common');

  const routes = useMemo(() => [
    { title: t('navigation.games'), route: '/games' },
    { title: t('navigation.news'), route: '/news' },
    { title: t('navigation.company'), route: '/company' },
    { title: t('navigation.partners'), route: '/partners' },
    { title: t('navigation.hire'), route: '/we-hire' },
  ], [i18n.language]);

  const onClick = (e) => {
    callback && callback(e);
  };

  return (
    <nav className={className}>
      <ul className={'flex items-center justify-center gap-16 select-none'}>
        {routes.map(routeData =>
          <li key={routeData.route}>
            <NavLink to={routeData.route} onClick={onClick}
                     className={({ isActive }) => `transition-all duration-500 text-base ${isActive ? `${activeLinkClassName ? activeLinkClassName : '!text-gradient'}` : 'text-white'}`}>
              {routeData.title}
            </NavLink>
          </li>
        )}
      </ul>
    </nav>
  );
});
