const validateFilesSize = (files, maxSize) => {
  const totalSize = Array.from(files).reduce((size, file) => size + file.size, 0);
  return totalSize <= maxSize;
};

const truncateFilesBySizeLimit = (files, maxSize, input) => {
  let _files = files;

  while (!validateFilesSize(_files, maxSize)) {
    const dataTransfer = new DataTransfer();

    for (let i = 0; i < _files.length - 1; i++) {
      dataTransfer.items.add(_files[i]);
    }

    _files = dataTransfer.files;
  }

  if (input) {
    input.files = _files;
  }

  return _files;
};

const deleteFile = (files, index, input) => {
  const dataTransfer = new DataTransfer();

  for (let i = 0; i < files.length; i++) {
    if (i === index) continue;
    dataTransfer.items.add(files[i]);
  }

  if (input) {
    input.files = dataTransfer.files;
  }

  return dataTransfer.files;
};

export {
  validateFilesSize,
  truncateFilesBySizeLimit,
  deleteFile,
}
