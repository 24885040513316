import React, { useMemo } from 'react';
import Card from './card';
import { Link } from 'react-router-dom';

export default React.memo(function Cards({ cardsData, link = false, className = '' }) {

  const cardItems = useMemo(() => {
    return cardsData.map(data => {
      return link ?
        <Link key={data.id} to={data.link || '/'} className={'flex flex-1 w-full h-full'}>
          <Card {...data} />
        </Link>
        :
        <Card key={data.id} {...data} />
    })
  }, [cardsData, link]);

  return (
    <ul className={`grid grid-cols-1 md:grid-cols-3 items-center justify-between gap-6 p-6 h-auto w-full ${className}`}>
      {cardItems}
    </ul>
  );
})
