import React, { useState } from 'react';
import { Input, Range } from '../../inputs';
import { useNode } from '@craftjs/core';

export default React.memo(function VideoSettings() {
  const { actions: { setProp }, width, borderRadius } = useNode((node) => ({
    width: node.data.props.width,
    borderRadius: node.data.props.borderRadius,
  }));
  const [linkError, setLinkError] = useState('');

  const changeSrc = async (e) => {
    let src = e.target.value;
    if (!src) {
      setProp(props => props.src = '');
      return;
    }
    const regex = /(v=)(.*?)(&|$)/;
    try {
      const match = src.match(regex);
      const id = match[0].split('=')[1].replace('&', '');
      setLinkError('');
      setProp(props => props.videoId = id);

    } catch (err) {
      setLinkError('Link isn\'t correct');
      console.error(err)
    }
  };
  const changeId = (e) => {
    let id = e.target.value;
    if (!id) {
      setProp(props => props.videoId = '');
      return;
    } else {
      setProp(props => props.videoId = id);
    }
  }

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'w-full '}>
        <span className={'font-semibold'}>Video src</span>
        <Input onChange={changeSrc} className={'w-full h-12 rounded-2l p-0'}
               placeholder={'https://youtu.be/JOv-D8YQOXE'} />
        <span className={`relative animate-appearY text-red-400`}>{linkError}</span>
      </div>
      <div className={'w-full '}>
        <span className={'font-semibold'}>Video id</span>
        <Input onChange={changeId} className={'w-full h-12 rounded-2l p-0'} placeholder={'5tP7-nZxB28'} />
      </div>

      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Width: ${width} %`}</span>
        <Range value={width} onChange={(e) => setProp(props => props.width = e.target.value)} />
      </div>
    </div>
  );
})
