import { useSearchParams } from 'react-router-dom';

const useQueryParams = (parseConfig) => {
  const [params, setParams] = useSearchParams();

  const updateParams = (obj) => {
    const nextParams = {};

    for (let key in obj) {
      let data = obj[key];

      if (data === '' || data === undefined || data === null) continue;
      if (typeof data === 'object') data = JSON.stringify(data);
      nextParams[key] = encodeURIComponent(obj[key]);
    }

    setParams(nextParams);
  };

  const parseParams = (params) => {
    const result = Object.fromEntries(params);

    for (let key in result) {
      result[key] = decodeURIComponent(result[key]);

      if (parseConfig && parseConfig.hasOwnProperty(key)) {
        result[key] = parseConfig[key](result[key]);
      }
    }

    return result;
  };

  return [parseParams(params), updateParams];
};

export {
  useQueryParams,
}
