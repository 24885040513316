import React, { useState, useRef } from 'react';
import FilterSelect from '../../components/gamesList/filterSelect';
import { useClickOutside } from '../../utils/hooks/useClickOutside';

import {ReactComponent as DropDownIcon} from '../../assets/icons/system/drop-down-icon.svg';

export default React.memo(function DropDownFilterSelect({ title, items, selected, select, multiSelect, className, ...props }) {
  const [showDropDown, setShowDropDown] = useState(false);
  const ref = useRef();

  const toggleDropDown = () => {
    setShowDropDown(prev => !prev);
  };

  useClickOutside(ref, showDropDown, () => setShowDropDown(false));

  return (
    <div ref={ref} className={'relative'}>
      <div className={`relative w-full h-16 flex items-center rounded-2xl bg-white border border-[#D0D5DD] outline-none p-4 shadow-sm cursor-pointer select-none ${className}`} onClick={toggleDropDown}>
        <DropDownIcon className={'absolute right-0 top-1/2 -translate-y-1/2 -translate-x-4'}/>
        <span className={'w-full flex pr-12 text-gray text-lg'}>
          {title}
        </span>
      </div>

      {showDropDown &&
      <div className={'absolute top-full left-0 right-0 pt-4 z-10 bg-white border rounded-2xl shadow-xl'}>
        <FilterSelect items={items} selected={selected}
                      select={select} multiSelect={multiSelect} {...props} />
      </div>}
    </div>
  );
})
