import { apiSlice } from './index';
import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';

export const vacanciesAdapter = createEntityAdapter();
const vacanciesInitialState = vacanciesAdapter.getInitialState();

export const commonExtendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getDashboardData: builder.query({
      query: () => '/common/get_dashboard',
      providesTags: ['Dashboard'],
      transformResponse: responseData => responseData.data
    }),
    getVacanciesList: builder.query({
      query: () => '/common/get_vacancies_list',
      providesTags: (result = [], error, arg) => [
        'Vacancies',
        ...result.ids.map(id => ({ type: 'Vacancies', id }))
      ],
      transformResponse: responseData => {
        return vacanciesAdapter.setAll(vacanciesInitialState, responseData.data);
      }
    }),
    getNewsList: builder.query({
      query: (page) => '/common/get_news_list' + (page !== undefined ? `?page=${page}` : ''),
      providesTags: (result = [], error, arg) => [
        'News',
        ...result.news.map(({ id }) => ({ type: 'News', id }))
      ],
      transformResponse: responseData => responseData.data
    }),
    getNews: builder.query({
      query: (urlName) => `/common/get_news?urlName=${urlName}`,
      providesTags: (result, err, arg) => [result ? { type: 'News', id: result.id } : 'News'],
      transformResponse: responseData => responseData.data,
    }),
    getMainNews: builder.query({
      query: () => `/common/get_main_news`,
      providesTags: ['News'],
      transformResponse: responseData => responseData.data,
    }),
    getPartnersList: builder.query({
      query: () => '/common/get_partners_list',
      providesTags: ['Partners'],
      transformResponse: responseData => responseData.data,
    }),
    getMediaPartnersList: builder.query({
      query: () => '/common/get_media_partners_list',
      providesTags: ['MediaPartners'],
      transformResponse: responseData => responseData.data,
    }),
    contactWithUs: builder.mutation({
      query: (data) => ({
        url: `/common/send_message`,
        method: 'POST',
        body: data
      }),
      transformResponse: responseData => {
        if (!responseData.success) throw new Error();
      }
    }),
    subscribeToNews: builder.mutation({
      query: (data) => ({
        url: `/common/subscribe`,
        method: 'POST',
        body: data
      }),
      transformResponse: responseData => {
        if (!responseData.success) throw new Error();
      }
    }),
    sendVacancyApplication: builder.mutation({
      query: (data) => ({
        url: `/common/send_vacancy_application`,
        method: 'POST',
        body: data
      }),
    }),
  })
});

const selectVacanciesResult = commonExtendedApi.endpoints.getVacanciesList.select();
const selectVacanciesData = createSelector(
  selectVacanciesResult,
  ({ data }) => data
);

export const { selectAll: selectAllVacancies, selectEntities: selectVacanciesEntities, selectById: selectVacanciesById } = vacanciesAdapter.getSelectors(state => selectVacanciesData(state) ?? vacanciesInitialState);

export const {
  useGetDashboardDataQuery,
  useGetVacanciesListQuery,
  useGetNewsListQuery,
  useGetNewsQuery,
  useGetMainNewsQuery,
  useGetPartnersListQuery,
  useGetMediaPartnersListQuery,
  useContactWithUsMutation,
  useSubscribeToNewsMutation,
  useSendVacancyApplicationMutation
} = commonExtendedApi;
