import React from 'react';
import ReactDom from 'react-dom';
import { ReactComponent as LogoAnimated } from '../../assets/icons/logo_full_animated.svg';

export default React.memo(function Preloader() {
  return ReactDom.createPortal(
    <div className={'fixed w-full h-screen inset-0 z-[10000] flex items-center justify-center bg-black/50'}>
      <LogoAnimated className={'w-1/2'}/>
    </div>, document.body)
})
