import { useCallback, useEffect } from 'react';

export const useClickOutside = (ref, condition, callback) => {

  const handler = useCallback((e) => {
    if (e.target === ref.current || ref.current.contains(e.target)) return;

    callback();
  }, [ref, callback]);

  useEffect(() => {
    if (condition) {
      document.addEventListener('mousedown', handler);
      document.addEventListener('touchstart', handler);
    } else {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    }

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    }
  }, [handler]);
};
