import React from 'react';
import CountriesCoverageCard from './dashboardCards/countriesCoverage';
import GameTypesCard from './dashboardCards/gameTypes';
import SupportedLanguagesCard from './dashboardCards/supportedLanguages';
import CompatibilityCard from './dashboardCards/deviceCompatibility';
import CurrenciesSupportCard from './dashboardCards/currenciesSupport';
import OperatorsCountCard from './dashboardCards/operatorsCount';
import DotControls from '../../uiKit/carousel/controls/dotControls';
import Carousel from '../../uiKit/carousel';
import { useGetDashboardDataQuery } from '../../redux/api/common';

export default React.memo(function DashboardBlock() {
  const {data: dashboardData, isFetching} = useGetDashboardDataQuery();

  return isFetching || !dashboardData ? null : (
    <div className={'w-full px-4 max-w-6xl'}>

      <div className={'hidden md:grid grid-cols-2 md:grid-cols-5 md:grid-rows-6 gap-8 grid-flow-row-dense'}>
        <CountriesCoverageCard data={dashboardData.regionCoverage} operatorsCount={dashboardData.totalOperatorsCount} gamesCount={dashboardData.totalGamesCount} className={'col-span-2 row-span-6'} />
        <GameTypesCard data={dashboardData.gameTypes} className={'col-span-2 row-span-3'} />
        <SupportedLanguagesCard data={dashboardData.supportedLanguages} className={'col-span-2 row-span-3'} />
        <CompatibilityCard className={'col-span-1 row-span-2 px-0 py-6'} />
        <CurrenciesSupportCard className={'col-span-1 row-span-2 px-0 py-6'} />
        <OperatorsCountCard count={dashboardData.totalOperatorsCount} className={'col-span-2 md:col-span-1 row-span-2'} />
      </div>

      <Carousel slidesPerView={1} className={'rounded-2xl overflow-visible [&>div>div]:p-4 [&>div>div]:overflow-visible'} containerClassName={'md:hidden'}
                applyControls={props => <DotControls {...props} className={'mt-12'} />}>

        <CountriesCoverageCard data={dashboardData.regionCoverage} operatorsCount={dashboardData.totalOperatorsCount} gamesCount={dashboardData.totalGamesCount} />
        <GameTypesCard data={dashboardData.gameTypes} />
        <SupportedLanguagesCard data={dashboardData.supportedLanguages} />
        <CompatibilityCard className={'px-0 py-6 [&_div]:items-center [&_div]:justify-center [&_div]:text-center'} />
        <CurrenciesSupportCard className={'px-0 py-6 [&_div]:items-center [&_div]:justify-center [&_div]:text-center'} />
        <OperatorsCountCard className={'[&_div]:items-center [&_div]:justify-center [&_div]:text-center'}/>

      </Carousel>

    </div>
  );
});
