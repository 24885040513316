import React from 'react';
import { ReactComponent as ArrowLeft } from '../../assets/icons/system/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../assets/icons/system/arrow-right.svg';
import { useTranslation } from 'react-i18next';

export default React.memo(function ArrowsPagination({ page, setPage, totalPagesCount, className = '' }) {
  const { t } = useTranslation('common');

  const showPrevious = () => {
    setPage(page - 1);
  };

  const showNext = () => {
    setPage(page + 1);
  };

  return (
    <div className={`flex items-center justify-between ${className}`}>
      <button disabled={page <= 1}
              className={'p-3 border border-slate-300 rounded-lg shadow-sm shadow-[rgba(16,_24,_40,_0.05)]'}
              onClick={showPrevious}>
        <ArrowLeft />
      </button>

      <span className={'text-gray text-lg'}>
        {t('labels.shows_page_of', { page, total: totalPagesCount })}
      </span>

      <button disabled={page >= totalPagesCount}
              className={'p-3 border border-slate-300 rounded-lg shadow-sm shadow-[rgba(16,_24,_40,_0.05)]'}
              onClick={showNext}>
        <ArrowRight />
      </button>
    </div>
  );
})
