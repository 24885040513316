import React from 'react';

export default React.memo(function Select({ children, className = '', placeholder, ...props }) {

  return (
    <label className={'relative'}>
      {(placeholder && props.value === '') &&
      <div className={'absolute inset-0 flex items-center justify-start px-4 pointer-events-none'}>
        <span className={'text-neutral'}>{placeholder}</span>
      </div>}

      <select
        className={`w-full h-16 rounded-2xl bg-white border border-[#D0D5DD] outline-none px-4 py-3 shadow-sm ${className}`} {...props}>
        <option disabled value={''} />
        {children}
      </select>
    </label>
  );
})
