import React from 'react';

import { ReactComponent as AppleIcon } from '../../assets/icons/compatibilities/gray/apple-icon.svg';
import { ReactComponent as WinIcon } from '../../assets/icons/compatibilities/gray/win-icon.svg';
import { ReactComponent as AndroidIcon } from '../../assets/icons/compatibilities/gray/android-icon.svg';
import { ReactComponent as HtmlIcon } from '../../assets/icons/compatibilities/gray/html-icon.svg';

export default React.memo(function CompatibilitiesBlock() {
  return (
    <div className={'flex gap-1 [&>svg]:shrink-0 flex-wrap'}>
      <AppleIcon />
      <WinIcon />
      <AndroidIcon />
      <HtmlIcon />
    </div>
  );
})
