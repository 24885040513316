import React from 'react';
import FlagsBlock from '../../flagsBlock';
import ShadowCard from '../../../uiKit/cards/shadowCard';
import { useTranslation } from 'react-i18next';

export default React.memo(function SupportedLanguages({ data, className = '' }) {
  const { t } = useTranslation('common');

  return (
    <ShadowCard className={className}>
      <div className={'w-full h-full flex flex-col gap-6'}>
        <div className={'w-full flex items-center gap-2'}>
          <span className={'font-Staatliches text-6xl uppercase'}>{data.length}</span>
          <span className={'text-base uppercase'}>
            {t('cards.descriptions.supported_languages')}
          </span>
        </div>

        <div className={'w-full flex flex-col items-center md:flex-row flex-wrap gap-6'}>
          <FlagsBlock flagsData={data} className={'w-full content-start [&_svg]:h-10 [&_svg]:rounded-sm gap-5'} />
        </div>

      </div>
    </ShadowCard>
  );
})
