import React from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/system/arrow-square-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/system/arrow-square-right.svg';

export default React.memo(function ArrowsOverControls({ showPrevious, showNext, goTo, getCurrentIndex, totalCount, className = '' }) {

  const onDotClick = (e) => {
    goTo(e.currentTarget.dataset.index);
  };

  return (
    <div className={`absolute z-[1] inset-0 flex items-center justify-between px-6 pointer-events-none ${className}`}>
      <button className={'p-2.5 rounded-full blurred pointer-events-auto'} onClick={showPrevious}>
        <ArrowLeft />
      </button>

      <button className={'p-2.5 rounded-full blurred pointer-events-auto'} onClick={showNext}>
        <ArrowRight />
      </button>

      <div className={'absolute bottom-6 left-1/2 -translate-x-1/2 px-6 py-4 blurred rounded-3xl'}>
        <ul className={'flex gap-2'}>
          {Array.from({ length: totalCount }, (v, i) =>
            <li key={i}>
              <button onClick={onDotClick} data-index={i} className={'pointer-events-auto'}>
                <span
                  className={`block w-2.5 h-2.5 rounded-full transition-all ${getCurrentIndex() === i ? 'bg-white' : 'bg-[#979797]'}`} />
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
})
