import React, { useEffect, useRef, useState } from 'react';
import { Checkbox, Input, TextArea } from '../../uiKit/inputs';
import { Link } from 'react-router-dom';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import { useTranslation } from 'react-i18next';
import { useGoogleCaptcha } from '../../utils/hooks/useGoogleCaptcha';
import { useContactWithUsMutation } from '../../redux/api/common';

export default React.memo(function ContactForm() {
  const { t } = useTranslation('common');
  const [contactWithUs, { isLoading }] = useContactWithUsMutation();
  const [success, setSuccess] = useState(null);
  const executeWithToken = useGoogleCaptcha(true);
  const formRef = useRef(null);
  const [data, setData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
    privacyPolicyChecked: true,
  });
  const [fieldsValidity, setFieldsValidity] = useState(Object.fromEntries(Object.entries(data).map(entry => [entry[0], { valid: true }])));

  useEffect(() => {
    if (success === null) return;

    const timeout = setTimeout(() => {
      setSuccess(null);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    }
  }, [success]);

  const onChange = (e) => {
    const field = e.target.name;
    let value = e.target.value;

    if (e.target.type === 'checkbox') {
      value = e.target.checked;
    }
    if (!fieldsValidity[field].valid) setFieldsValidity(prev => ({
      ...prev,
      [field]: { ...prev[field], valid: true }
    }));
    setData(prev => ({ ...prev, [field]: value }));
  };

  const validate = () => {
    const inputs = formRef.current.elements;
    let isValid = true;

    for (let field in fieldsValidity) {
      if (!inputs[field].validity.valid) {
        setFieldsValidity(prev => ({ ...prev, [field]: { ...prev[field], valid: false } }));
        isValid = false;
      }
    }

    return isValid
  };

  const captchaCallback = async (token) => {
    try {
      await contactWithUs({ ...data, captcha: token }).unwrap();
      setSuccess(true);
    } catch (e) {
      setSuccess(false);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) return;

    executeWithToken(captchaCallback, 'submitContactForm');
  };

  return (
    <form ref={formRef} onSubmit={submit} className={'w-full flex flex-col gap-12'} noValidate>
      <div className={'grid grid-cols-2 gap-x-8 gap-y-6'}>
        <div className={'flex flex-col gap-1.5'}>
          <label className={'text-gray-700'}>{t('labels.first_name')}</label>
          <Input name={'firstName'} value={data.firstName} onChange={onChange}
                 className={!fieldsValidity.firstName.valid ? '!border-red-400 animate-errorShake' : ''}
                 placeholder={t('placeholders.first_name')} maxLength={30} required />
        </div>

        <div className={'flex flex-col gap-1.5'}>
          <label className={'text-gray-700'}>{t('labels.last_name')}</label>
          <Input name={'lastName'} value={data.lastName} onChange={onChange}
                 className={!fieldsValidity.lastName.valid ? '!border-red-400 animate-errorShake' : ''}
                 placeholder={t('placeholders.last_name')} maxLength={30} required />
        </div>

        <div className={'col-span-2 flex flex-col gap-1.5'}>
          <label className={'text-gray-700'}>{t('labels.email')}</label>
          <Input name={'email'} value={data.email} onChange={onChange} placeholder={t('placeholders.email')}
                 className={!fieldsValidity.email.valid ? '!border-red-400 animate-errorShake' : ''}
                 type={'email'} maxLength={30} required />
        </div>

        <div className={'col-span-2 flex flex-col gap-1.5'}>
          <label className={'text-gray-700'}>{t('labels.message')}</label>
          <TextArea name={'message'} value={data.message} onChange={onChange} placeholder={t('placeholders.message')}
                    className={`h-32 ${!fieldsValidity.message.valid ? '!border-red-400 animate-errorShake' : ''}`}
                    maxLength={100000} required />
        </div>

        <div className={'col-span-2 flex justify-center md:justify-start'}>
          <label className={'w-max flex items-center gap-3 select-none'}>
            <Checkbox name={'privacyPolicyChecked'} checked={data.privacyPolicyChecked} onChange={onChange}
                      className={!fieldsValidity.privacyPolicyChecked.valid ? 'before:!border-red-400 animate-errorShake' : ''}
                      required />

            <span className={'text-lg text-gray'}>
                  <span>{t('labels.agreement')}</span>
                  <Link to={'/privacy-policy'} target={'_blank'} rel={'noopener noreferrer'} className={'underline'}>
                    <span className={'pointer-events-none'}>{t('labels.privacy_policy')}</span>
                  </Link>
            </span>
          </label>
        </div>
      </div>

      <div className={'flex flex-col md:flex-row justify-center items-center md:justify-start gap-4'}>
        <ButtonOutlined disabled={!data.privacyPolicyChecked || isLoading} type={'submit'}
                        className={'text-lg font-semibold px-6 before:transition-all disabled:text-gray disabled:before:opacity-50 shrink-0'}>
          {t('buttons.send_message')}
        </ButtonOutlined>
        <div className={'relative w-full flex items-start md:items-center justify-center'}>
          {!isLoading && success !== null &&
          <span className={`absolute animate-appearY ${success ? 'text-green-400' : 'text-red-400'}`}>
            {t(success ? 'labels.message_sent' : 'errors.message_error')}
          </span>}
        </div>
      </div>
    </form>
  );
})
