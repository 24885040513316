import React from 'react';

export default React.memo(function CardSkeleton() {
  return (
    <div className={'flex flex-1 w-full h-full'}>
      <div className={'flex-1 w-full h-full flex flex-col justify-center'}>
        <div className={'w-full pb-[100%] rounded-2xl skeleton flex items-center justify-center mb-4 md:mb-10'} />

        <div
          className={'h-full flex flex-col gap-3 justify-items-start'}>
          <div className={'skeleton skeleton-text h-4 w-8/12'} />
          <div className={'skeleton skeleton-text'} />
          <div className={'skeleton skeleton-text'} />
        </div>
      </div>
    </div>
  );
})
