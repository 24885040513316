import { useEffect } from 'react';

const useGoogleCaptcha = (visible) => {
  useEffect(() => {
    if (document.getElementById('g-recaptcha-init-script')) return;

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}`;
    script.id = 'g-recaptcha-init-script';
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!visible) return;
    document.body.classList.add('captcha-visible');
    return () => {
      document.body.classList.remove('captcha-visible');
    }
  }, [visible]);

  return (func, action = 'submit') => {
    window.grecaptcha?.ready(() => window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_CAPTCHA_KEY, { action }).then(func));
  };
};

export {
  useGoogleCaptcha,
}
