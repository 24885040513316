import React from 'react';
import Paragraph from '../../typography/paragraph';

export default React.memo(function SubAccordionLayout({ text, isActive }) {
  return (
    <div className={'py-6 pl-6 pr-12'}>
      <Paragraph>
        {text}
      </Paragraph>
    </div>
  );
})
