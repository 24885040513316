import React from 'react';
import Title from '../../uiKit/typography/title';
import MainNewsBlock from '../news/mainNewsBlock';
import NewsList from '../news/list';
import { NewsSubscriptionForm } from '../forms/index';
import { useTranslation } from 'react-i18next';
import { useGetMainNewsQuery } from '../../redux/api/common';

export default React.memo(function NewsListPage() {
  const { t } = useTranslation('common');
  const { data, isFetching } = useGetMainNewsQuery();

  return (
    <>

      <div className={'w-full flex justify-center pt-16 md:pt-24'}>
        <Title className={'text-7xl md:text-9xl'}>
          {t('titles.news_page')}
        </Title>
      </div>

      {/*<div className={'w-full pt-4 pb-16 md:py-10 px-4 max-w-xl'}>*/}
      {/*  <NewsSubscriptionForm />*/}
      {/*</div>*/}

      <div className={'hidden md:flex w-full flex-col items-center max-w-7xl px-16 pt-24 pb-4'}>
        <MainNewsBlock data={data} />
      </div>

      <div className={'w-full flex flex-col max-w-7xl py-4 px-4 md:px-16'}>
        <NewsList />
      </div>

    </>
  );
})
