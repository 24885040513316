import React from 'react';
import Title from '../../uiKit/typography/title';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import { useTranslation } from 'react-i18next';
import { transformGameTitleToUrl } from '../../utils/transformGameTitleToUrl';
import { Link, useNavigate } from 'react-router-dom';

export default React.memo(function SimilarGamesBlock({ games }) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const goToGamesList = () => {
    navigate('/games');
  };

  return (
    <section className={'flex items-center flex-col px-4 w-full'}>
      <div className={'flex items-center justify-center md:justify-between w-full'}>
        <Title>
          {t('titles.similar_games')}
        </Title>
        <ButtonOutlined onClick={goToGamesList} className={'hidden md:block px-6'}>
          {t('buttons.all_games')}
        </ButtonOutlined>
      </div>

      <div className={'w-full grid grid-cols-[repeat(1,_minmax(0,_15rem))] md:grid-cols-[repeat(auto-fit,_minmax(0,_15rem))] md:grid-rows-1 md:auto-rows-[0] overflow-hidden justify-center gap-y-8 gap-x-8 md:gap-y-0 w-full mt-10 md:mt-24'}>
        {games.slice(0, 8).map(gameData =>
          <Link key={gameData.gameBundle} to={`/games/${transformGameTitleToUrl(gameData.name)}`}>
            <div className={'rounded-3xl overflow-hidden'}>
              <img src={gameData.iconSquare} alt={gameData.name} className={'w-full object-contain'} />
           </div>
          </Link>
        )}
      </div>

      <div className={'flex items-center justify-center w-full mt-12'}>
        <ButtonOutlined onClick={goToGamesList} className={'md:hidden w-3/4 px-4'}>
          {t('buttons.all_games')}
        </ButtonOutlined>
      </div>
    </section>
  );
})
