import { useEffect, useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { blockScroll, releaseScroll } from '../../redux/slices/appSlice';

const useScrollBlocker = (condition) => {
  const dispatch = useDispatch();
  const id = useMemo(() => crypto.randomUUID(), []);
  const scrollBlocked = useRef(false);

  useEffect(() => {
    if (condition && !scrollBlocked.current) {
      dispatch(blockScroll(id));
      scrollBlocked.current = true;
    } else if (!condition && scrollBlocked.current) {
      dispatch(releaseScroll(id));
      scrollBlocked.current = false;
    }

    return () => {
      if (scrollBlocked.current) {
        dispatch(releaseScroll(id));
        scrollBlocked.current = false;
      }
    }
  }, [condition, id, dispatch]);
};

export {
  useScrollBlocker,
}
