import React from 'react';
import PageTitleLayout from '../../uiKit/pageTitleLayout';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PartnerItem from '../partnerItem/index'
import { useGetPartnersListQuery, useGetMediaPartnersListQuery } from '../../redux/api/common';

export default React.memo(function PartnersPage() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { data: partnersData = [] } = useGetPartnersListQuery();
  const { data: mediaPartnersData = [] } = useGetMediaPartnersListQuery();

  const goToGamesList = () => {
    navigate('/games');
  };

  return (
    <>
      <section
        className={`relative w-full min-h-screen after:absolute after:inset-0 after:bg-black after:opacity-50 after:z-[-1]`}>
        <div
          className={'absolute inset-0 after:absolute after:inset-0 after:bg-gradient-to-r after:from-[#DE32BD] after:to-[#FEBF0E]'} />
        <div className={`relative w-full min-h-screen flex items-center pb-4 pt-20 md:pt-40`}>
          <div className={'w-full h-full'}>
            <div className={'flex items-center justify-center'}>
              <PageTitleLayout title={t('titles.partners')}
                               className={'w-10/12 max-w-4xl flex flex-col items-center text-center gap-4 md:gap-12'}>
                <Paragraph className={'!text-white md:w-2/3'}>
                  {t('descriptions.partners_page')}
                </Paragraph>
                <ButtonFulfilled onClick={goToGamesList} className={'px-12 text-white'}>
                  {t('buttons.our_games')}
                </ButtonFulfilled>
              </PageTitleLayout>
            </div>
          </div>
        </div>
      </section>

      <div
        className={'w-full grid grid-cols-[repeat(auto-fill,_minmax(0,_12rem))] justify-center gap-x-32 gap-y-10 px-4 md:px-36 py-20 md:py-32'}>
        {partnersData.map((item) => (
          <PartnerItem key={item.id} partnerData={item} linkActive={true}/>
        ))}
      </div>

      <div className={'w-full flex flex-col gap-12 md:gap-16 items-center py-20 md:py-32'}>
        <div className={'flex flex-col gap-6 md:gap-10 text-center max-w-4xl px-4'}>
          <Title>
            {t('titles.partners_page_media')}
          </Title>
          <Paragraph>
            {t('descriptions.partners_page_media')}
          </Paragraph>
        </div>
        <div
          className={'w-full grid grid-cols-[repeat(auto-fill,_minmax(0,_12rem))] justify-center gap-x-32 gap-y-10 px-4 md:px-36'}>
          {mediaPartnersData.map((item) => (
            <PartnerItem key={item.id} partnerData={item} linkActive={true} alt={'media partner'}/>
          ))}
        </div>
      </div>

    </>
  );
})
