import { createSlice, createSelector } from '@reduxjs/toolkit';
import eModalTypes from '../../enums/eModalTypes';

const initialState = Object.fromEntries(Object.values(eModalTypes).map(type => [type, {
  visible: false,
  data: null,
}]));

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal: (state, action) => {
      state[action.payload.type] = { visible: true, data: action.payload.data || null };
    },
    closeModal: (state, action) => {
      state[action.payload.type].visible = false;
    },
  },
});

export const getModalData = createSelector(
  [
    (state) => state.modals,
    (state, type) => type,
  ],
  (data, type) => data[type],
);

export const { openModal, closeModal } = modalsSlice.actions;
