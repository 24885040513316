import React from 'react';
import { NavLink } from 'react-router-dom';

import { ReactComponent as LogoIcon } from '../../assets/icons/onlyplay_logo.svg';

export default React.memo(function Logo() {
  return (
    <NavLink to={'/'}>
      <LogoIcon />
    </NavLink>
  );
})
