import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useGoogleCaptcha } from './utils/hooks/useGoogleCaptcha';
import { useFontsPreloader } from './utils/hooks/useFontsPreloader';
import { withSuspense } from './components/hoc/suspense';

import MainLayout from './components/pages/mainLayout';
import TopScroller from './components/hoc/topScroller';
import MetaUrlSetter from './components/hoc/metaUrlSetter';
import ErrorBoundary from './uiKit/errorBoundary';

//main
import MainPage from './components/pages/main';
import GamesListPage from './components/pages/gamesList';
import GamePage from './components/pages/game';
import NewsListPage from './components/pages/newsList';
import NewsPage from './components/pages/news';
import CompanyPage from './components/pages/company';
import PartnersPage from './components/pages/partners';
import JobsInfoPage from './components/pages/jobsInfo';
import JobPage from './components/pages/job';
import ContactsPage from './components/pages/contacts';
import PrivacyPolicyPage from './components/pages/privacyPolicy';
import CookiesPage from './components/pages/cookies';
import Page404 from './components/pages/404';

//admin
const AdminLayout = React.lazy(() => import('./components/admin/pages/adminLayout'));
const AdminFeaturesList = React.lazy(() => import('./components/admin/pages/gameFeatures/gameFeatures'));
const AddGameFeaturePage = React.lazy(() => import('./components/admin/pages/gameFeatures/addGameFeature'));
const UpcomingGameModal = React.lazy(() => import('./components/admin/components/modals/upcomingGame'));
const AdminUpcomingGamesListPage = React.lazy(() => import('./components/admin/pages/upcomingGamesList'));
const AdminGamePage = React.lazy(() => import('./components/admin/pages/game'));
const AdminGamesListPage = React.lazy(() => import('./components/admin/pages/gamesList'));
const AdminVacanciesListPage = React.lazy(() => import('./components/admin/pages/vacancies/vacanciesList'));
const AdminPartnersPage = React.lazy(() => import('./components/admin/pages/partners/partners'));
const AdminMediaPartnersPage = React.lazy(() => import('./components/admin/pages/partners/mediaPartners'));
const AdminAddPartner = React.lazy(() => import('./components/admin/pages/partners/addPartnerPage'));
const AdminAddMediaPartner = React.lazy(() => import('./components/admin/pages/partners/addMediaPartnerPage'));
const AddVacancy = React.lazy(() => import('./components/admin/pages/vacancies/addVacancy'));
const AdminNewsListPage = React.lazy(() => import('./components/admin/pages/newsList'));
const NewsModal = React.lazy(() => import('./components/admin/components/modals/news'));
const AdminUsersList = React.lazy(() => import('./components/admin/pages/adminUsers/adminUsersList'));
const AddAdminUser = React.lazy(() => import('./components/admin/pages/adminUsers/addAdminUser'));
const SettingsPage = React.lazy(() => import('./components/admin/pages/settings'));

const AdminLayoutSuspended = withSuspense(AdminLayout);
const AdminFeaturesListSuspended = withSuspense(AdminFeaturesList);
const AddGameFeaturePageSuspended = withSuspense(AddGameFeaturePage);
const UpcomingGameModalSuspended = withSuspense(UpcomingGameModal);
const AdminUpcomingGamesListPageSuspended = withSuspense(AdminUpcomingGamesListPage);
const AdminGamesListPageSuspended = withSuspense(AdminGamesListPage);
const AdminGamePageSuspended = withSuspense(AdminGamePage);
const AdminVacanciesListPageSuspended = withSuspense(AdminVacanciesListPage);
const AdminPartnersPageSuspended = withSuspense(AdminPartnersPage);
const AdminMediaPartnersPageSuspended = withSuspense(AdminMediaPartnersPage);
const AdminAddPartnerSuspended = withSuspense(AdminAddPartner);
const AdminAddMediaPartnerSuspended = withSuspense(AdminAddMediaPartner);
const AddVacancySuspended = withSuspense(AddVacancy);
const AdminNewsListPageSuspended = withSuspense(AdminNewsListPage);
const NewsModalSuspended = withSuspense(NewsModal);
const AdminUsersListPageSuspended = withSuspense(AdminUsersList);
const AddAdminUserSuspended = withSuspense(AddAdminUser);
const SettingsSuspended = withSuspense(SettingsPage);

function App() {
  useGoogleCaptcha();
  const fontsReady = useFontsPreloader();
  const location = useLocation();

  return !fontsReady ? null : (
    <TopScroller>
      <MetaUrlSetter>
        <ErrorBoundary key={location.pathname}>

          <Routes>
            <Route path={'/'} element={<MainLayout />}>

              <Route index element={<MainPage />} />

              <Route path={'games'} element={<GamesListPage />} />
              <Route path={'games/:bundle'} element={<GamePage />} />

              <Route path={'news'} element={<NewsListPage />} />
              <Route path={'news/:id'} element={<NewsPage />} />

              <Route path={'company'} element={<CompanyPage />} />
              <Route path={'partners'} element={<PartnersPage />} />
              <Route path={'we-hire'} element={<JobsInfoPage />} />
              <Route path={'we-hire/:id'} element={<JobPage />} />
              <Route path={'contacts'} element={<ContactsPage />} />

              <Route path={'privacy-policy'} element={<PrivacyPolicyPage />} />
              <Route path={'cookies'} element={<CookiesPage />} />

              <Route path={'/404'} element={<Page404 />} />

            </Route>

            <Route path={'/admin'} element={<AdminLayoutSuspended />}>

              <Route path={'game-features'} element={<AdminFeaturesListSuspended />} />
              <Route path={'game-features/add'} element={<AddGameFeaturePageSuspended />} />
              <Route path={'game-features/edit/:id'} element={<AddGameFeaturePageSuspended />} />

              <Route path={'games'} element={<AdminGamesListPageSuspended />} />
              <Route path={'games/add'} element={<AdminGamePageSuspended />} />
              <Route path={'games/add/:bundle/*'} element={<AdminGamePageSuspended />} />
              <Route path={'games/edit/:bundle/*'} element={<AdminGamePageSuspended />} />

              <Route path={'news'} element={<AdminNewsListPageSuspended />} />
              <Route path={'news/add'} element={<NewsModalSuspended />} />
              <Route path={'news/add/:id'} element={<NewsModalSuspended />} />
              <Route path={'news/edit/:id'} element={<NewsModalSuspended />} />

              <Route path={'upcoming-games'} element={<AdminUpcomingGamesListPageSuspended />}>
                <Route path={'add'} element={<UpcomingGameModalSuspended />} />
                <Route path={'add/:id'} element={<UpcomingGameModalSuspended />} />
                <Route path={'edit/:id'} element={<UpcomingGameModalSuspended />} />
              </Route>

              <Route path={'vacancies'} element={<AdminVacanciesListPageSuspended />} />
              <Route path={'vacancies/add'} element={<AddVacancySuspended />} />
              <Route path={'vacancies/add/:id'} element={<AddVacancySuspended />} />
              <Route path={'vacancies/edit/:id'} element={<AddVacancySuspended />} />

              <Route path={'partners'} element={<AdminPartnersPageSuspended />} />
              <Route path={'partners/add'} element={<AdminAddPartnerSuspended />} />
              <Route path={'partners/edit/:id'} element={<AdminAddPartnerSuspended />} />

              <Route path={'media-partners'} element={<AdminMediaPartnersPageSuspended />} />
              <Route path={'media-partners/add'} element={<AdminAddMediaPartnerSuspended />} />
              <Route path={'media-partners/edit/:id'} element={<AdminAddMediaPartnerSuspended />} />

              <Route path={'users'} element={<AdminUsersListPageSuspended />} />
              <Route path={'users/add'} element={<AddAdminUserSuspended />} />
              <Route path={'users/edit/:id'} element={<AddAdminUserSuspended />} />
              
              <Route path={'settings'} element={<SettingsSuspended />} /> //

            </Route>

            <Route path={'*'} element={<Navigate replace={true} to={'/404'} />} />
          </Routes>

        </ErrorBoundary>
      </MetaUrlSetter>
    </TopScroller>
  );
}

export default App;
