import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import Accordion from '../../uiKit/accordion';
import MainAccordionLayout from '../../uiKit/accordion/layout/main';
import SubAccordionLayout from '../../uiKit/accordion/layout/sub';
import Paragraph from '../../uiKit/typography/paragraph';
import { useTranslation } from 'react-i18next';

export default React.memo(function OurStory() {
  const { t, i18n } = useTranslation(['common', 'story']);

  const story = useMemo(() => {
    const result = [];
    for (let i = 0; i <= 2; i++) {
      result.push({
        id: i,
        title: t(`story:titles.title_${i}`),
        description: t(`story:descriptions.description_${i}`),
      });
    }
    return result;
  }, [i18n.language]);

  const accordionItems = useMemo(() => {
    return story.map(item => ({
      id: item.id,
      mainElement: (isActive) => <MainAccordionLayout title={item.title} isActive={isActive}/>,
      subElement: (isActive) => <SubAccordionLayout text={item.description} isActive={isActive}/>,
    }));
  }, [story]);

  return (
    <div className={'w-full flex flex-col md:flex-row [&>div]:flex-1 gap-8'}>
      <div>
        <div className={'flex flex-col justify-center gap-4 md:gap-10'}>
          <Title className={'md:!text-left'}>
            {t('titles.story')}
          </Title>
          <Paragraph className={'text-center md:text-left'}>
            {t('descriptions.story')}
          </Paragraph>
        </div>
      </div>

      <div className={'flex flex-col items-center md:items-start'}>
        <Accordion className={'p-0'} items={accordionItems} expand={story[0].id}
                   itemContainerClassName={isActive => `rounded-3xl ${isActive ? 'bg-rose' : 'bg-white'}`}
                   singleView />
      </div>
    </div>
  );
})
