import React, { useEffect, useRef, useState } from 'react';
import Navigation from '../navigation';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSideBarOpen, toggleSideBar } from '../../redux/slices/appSlice';
import { useScrollBlocker } from '../../utils/hooks/useScrollBlocker';

export default React.memo(function SideBar() {
  const { t } = useTranslation('common');
  const isOpen = useSelector(getSideBarOpen);
  const isOpenRef = useRef(isOpen);
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFirstAppearance, setIsFirstAppearance] = useState(true);

  useScrollBlocker(isOpen);

  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  useEffect(() => {
    if (!isOpenRef.current) return;
    dispatch(toggleSideBar(false));
  }, [location, dispatch]);

  useEffect(() => {
    if (isOpen) setIsFirstAppearance(false);
  }, [isOpen]);

  const setWrapperStyles = (e) => {
    if (isOpen) {
      e.target.classList.remove('before:!w-0', 'before:!h-0');
      e.target.classList.remove('hidden');
    } else {
      e.target.classList.add('before:!w-0', 'before:!h-0');
      e.target.classList.add('hidden');
    }
  };

  const setContainerStyles = (e) => {
    e.stopPropagation();
    if (isOpen) {
      e.target.classList.add('!opacity-100');
    } else {
      e.target.classList.remove('!opacity-100');
    }
  };

  return (
    <div onAnimationEnd={setWrapperStyles}
         className={`${isFirstAppearance ? 'hidden' : ''} absolute z-[100] w-full h-screen md:hidden before:absolute before:blurred before:top-6 before:right-4 before:w-[200vw] before:h-[200vh] before:-translate-y-1/4 before:translate-x-1/4 before:rounded-full before:origin-top-right ${isOpen ? 'before:animate-grow' : 'before:animate-reduce'}`}>
      <div onAnimationEnd={setContainerStyles}
           className={`relative w-full h-full flex flex-col gap-6 opacity-0 overflow-y-scroll pt-16 ${isOpen ? 'animate-sideBarFadeIn' : 'animate-sideBarFadeOut'}`}>
        <div className={'flex flex-col w-full items-center m-auto'}>
          <Navigation
            className={'w-full [&>ul]:w-full [&>ul]:h-full [&>ul]:flex-col [&>ul]:gap-6 [&_li]:w-full [&_a]:flex [&_a]:p-4 [&_a]:items-center [&_a]:justify-center [&_a]:!text-lg [&_a]:transition-all'}
            activeLinkClassName={'bg-white/30 text-white'} />

          <NavLink to={'/contacts'}
                   className={({ isActive }) => `flex items-center justify-center p-4 ${isActive ? '[&>span]:after:opacity-100' : '[&>span]:after:opacity-0'}`}>
            <span
              className={'py-4 px-12 text-white text-lg overflow-hidden btn__outlined rounded-2xl after:absolute after:inset-0 after:z-[0] after:transition-all after:bg-gradient'}>
              <span className={'relative z-[1]'}>
                {t('buttons.contacts')}
              </span>
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
})
