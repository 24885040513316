import React from 'react';
import { Range, Radio } from '../../inputs';
import { ChromePicker } from 'react-color';
import { useNode } from '@craftjs/core';

export default React.memo(function TextSettings({ options }) {
  const { actions: { setProp }, fontSize, color, textAlign } = useNode((node) => ({
    fontSize: node.data.props.fontSize,
    color: node.data.props.color,
    textAlign: node.data.props.textAlign,
    selection: node.data.props.selection,
  }));

  const changeColor = (color) => {
    setProp(props => props.color = color.rgb)
  };

  const changeAlign = (e) => {
    setProp(props => props.textAlign = e.target.value);
  };

  return (
    <div className={'w-full flex flex-col gap-8'}>

      <div className={'w-full flex flex-col gap-1 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Color</span>
        <ChromePicker width={'100%'} color={color} onChange={changeColor} onChangeComplete={changeColor} />
      </div>

      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Text align</span>
        <div className={'w-full flex flex-col gap-1'}>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlign} name={'align'} value={'left'} checked={textAlign === 'left'} />
            <span>Left</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlign} name={'align'} value={'center'} checked={textAlign === 'center'} />
            <span>Center</span>
          </label>
          <label className={'w-full flex items-center gap-2'}>
            <Radio onChange={changeAlign} name={'align'} value={'right'} checked={textAlign === 'right'} />
            <span>Right</span>
          </label>
        </div>
      </div>

      <div className={'w-full flex flex-col gap-1 bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Font size: ${fontSize} rem`}</span>
        <Range value={fontSize} step={options.fontSize.step} min={options.fontSize.min} max={options.fontSize.max}
               onChange={e => setProp(props => props.fontSize = e.target.value)} />
      </div>
    </div>
  );
})
