import React, { useMemo } from 'react';
import { hasFlag } from 'country-flag-icons';
import Flags from 'country-flag-icons/react/3x2';
import ToolTip from '../../uiKit/tooltip';

export default React.memo(function FlagsBlock({ flagsData, withHoverEffect, className = '' }) {

  const icons = useMemo(() => {
    const result = [];
    for (let item of flagsData) {
      const unicode = item.unicode.toUpperCase();

      if (!hasFlag(unicode)) continue;
      const Flag = Flags[unicode];
      result.push(
        <ToolTip key={item.title + unicode} text={item.title}>
          <Flag className={`shrink-0 border border-neutral/30  ${withHoverEffect ? 'opacity-50 grayscale hover:grayscale-0 hover:opacity-100 transition-all' : ''}`}/>
      </ToolTip>
      );
    }

    return result;
  }, [flagsData]);

  return (
    <div className={`flex flex-wrap ${className}`}>
      {icons}
    </div>
  );
})
