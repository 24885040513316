import React, { useMemo } from 'react';
import { useImagePreload } from '../../utils/hooks/useImagePreload';
import { Link } from 'react-router-dom';
import Card from '../../uiKit/cards/card';
import Skeleton from './cardSkeleton';

export default React.memo(function NewsCards({ cardsData }) {
  const loaded = useImagePreload(cardsData.map(item => item.imageSrc));

  const list = useMemo(() => {
    return cardsData.length ? cardsData : Array.from({ length: 3 }, () => null);
  }, [cardsData]);

  return (
    <ul
      className={`grid grid-cols-1 md:grid-cols-3 items-center justify-between p-6 px-0 gap-16 h-auto w-full auto-rows-fr [&_li_img]:rounded-2xl [&_li_img]:w-full [&_li_img]:aspect-square [&_li_img]:object-cover [&_li_p]:line-clamp-2 [&_li_p]:h-max [&_#card-grid]:grid-rows-[1fr_max-content_minmax(max-content,_1fr)]`}>
      {list.map((item, index) => item && loaded.includes(item.imageSrc) ?
        <Link key={item.id} to={item.link || '/'} className={'flex flex-1 w-full h-full'}>
          <Card {...item} />
        </Link> :
        <Skeleton key={item ? item.imageSrc : index} />
      )}
    </ul>
  );
})
