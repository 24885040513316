const copyToClipBoard = async (text) => {
  let success = false;

  try {
    await navigator.clipboard.writeText(text);
    success = true;
  } catch (e) {
    console.error(e);
  }

  return success;
};

export {
  copyToClipBoard,
}
