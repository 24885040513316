import React from 'react';
import CheckBox from '../../uiKit/inputs/checkbox';

export default React.memo(function FilterSelect({ items, selected = [], select, multiSelect }) {

  const onSelect = (e) => {
    const id = Number(e.currentTarget.dataset.id);

    if (selected.includes(id)) {
      select(selected.filter(item => item !== id));
      return;
    }

    select(multiSelect ? [...selected, id] : [id]);
  };

  return (
    <div className={'w-full px-6'}>
      <ul className={'w-full flex flex-col items-start gap-4 pb-4 overflow-hidden select-none'}>
        {items.map(item => {
            const isItemSelected = selected.includes(item.id);

            return (
              <li key={item.id} className={'relative w-full'}>

                <label className={'w-max flex gap-2.5'}>
                  <CheckBox checked={isItemSelected} onChange={onSelect} data-id={item.id}/>
                  <span className={`text-sm ${isItemSelected ? 'text-gradient' : 'text-gray'}`}>
                  {item.title}
                </span>
                </label>

              </li>
            )
          }
        )}
      </ul>
    </div>
  );
})
