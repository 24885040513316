import React from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ClockIcon } from '../../assets/icons/system/clock-icon.svg';
import { ReactComponent as LocationIcon } from '../../assets/icons/system/map-icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/icons/system/arrow-up-right.svg';
import { Link } from 'react-router-dom';

export default React.memo(function JobItem({ data, className = '' }) {
  const { t } = useTranslation('common');

  return (
    <Link to={`/we-hire/${data.id}`} className={`w-full grid grid-cols-12 p-6 rounded-3xl border border-gray/50 select-text cursor-pointer ${className}`} draggable={false}>
      <div className={'col-span-8 flex flex-col gap-4'}>
        <div className={'flex flex-col gap-2'}>
          <label className={'text-sm text-gray cursor-pointer'}>{data.department}</label>
          <span className={'font-Staatliches text-lg text-black leading-none uppercase'}>{data.name}</span>
        </div>

        <div className={'flex gap-6'}>
          <div className={'flex gap-2.5 items-center'}>
            <LocationIcon className={'shrink-0'} />
            <span className={'text-lg text-neutral-500 whitespace-nowrap'}>{data.workPlace}</span>
          </div>

          <div className={'flex gap-2.5 items-center'}>
            <ClockIcon className={'shrink-0'} />
            <span className={'text-lg text-neutral-500 whitespace-nowrap'}>{data.workTime}</span>
          </div>
        </div>
      </div>

      <div className={'col-span-4'}>
        <div className={'flex justify-end'}>
          <div className={'flex gap-2 items-center'}>

          </div>
            <span className={'text-lg text-neutral-500 whitespace-nowrap'}>{t('buttons.view_job')}</span>
            <ArrowIcon className={'shrink-0'} />
        </div>
      </div>
    </Link>
  );
})
