import React from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import { ContactForm } from '../forms';
import { useTranslation } from 'react-i18next';

export default React.memo(function ContactsPage() {
  const { t } = useTranslation('common');

  return (
    <>
      <div className={'w-full flex flex-col items-center py-20 md:py-24'}>
        <div className={'w-full px-4 md:px-14 max-w-6xl'}>

          <div className={'w-full md:w-1/2 flex flex-col gap-12'}>
            <div className={'w-full flex flex-col gap-4 items-center md:items-start'}>
              <Title>
                {t('titles.contacts_page')}
              </Title>

              <Paragraph className={'text-lg text-center md:text-left'}>
                {t('descriptions.contacts_page')}
              </Paragraph>
            </div>

            <ContactForm />
          </div>

        </div>
      </div>
    </>
  );
})
