import React from 'react';

export default React.memo(function CardSkeleton() {
  return (
    <div className={'w-full flex flex-col gap-10'}>
      <div className={'w-full rounded-3xl overflow-hidden'}>
        <div className={'aspect-video skeleton'}/>
      </div>

      <div className={'w-1/2 flex flex-col gap-3'}>
        <div className={'skeleton skeleton-text'}/>
        <div className={'skeleton skeleton-text'}/>
      </div>
    </div>
  );
})
