import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

export default React.memo(function LinkOutlined({ className = '', children, external, ...props }) {

  const Element = useMemo(() => external ? ({ children, to, ...props }) => <a rel={'noopener noreferrer'} href={to} {...props}>{children}</a> : Link, [external]);

  return (
    <Element {...props}
             className={`btn__outlined cursor-pointer flex items-center justify-center rounded-2xl inline-block select-none ${className}`}>
      {children}
    </Element>
  );
})
