import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Paragraph from '../../uiKit/typography/paragraph';
import Title from '../../uiKit/typography/title';
import Accordion from '../../uiKit/accordion';
import Carousel from '../../uiKit/carousel';
import ArrowControls from '../../uiKit/carousel/controls/arrowControls';
import MainAccordionLayout from '../../uiKit/accordion/layout/main';
import SubAccordionLayout from '../../uiKit/accordion/layout/sub';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGameFeaturesList, useGetGamesDataQuery } from '../../redux/api/games';
import { useImagePreload } from '../../utils/hooks/useImagePreload';

export default React.memo(function FeaturesBlock() {
  const { t } = useTranslation('common');
  const { isFetching } = useGetGamesDataQuery();
  const featuresList = useSelector(getGameFeaturesList);
  useImagePreload(featuresList.reduce((res, feature) => {
    res.push(...feature.screenshots);
    return res;
  }, []));
  const [currentFeatureId, setCurrentFeatureId] = useState(featuresList[0]?.id);

  useEffect(() => {
    setCurrentFeatureId(featuresList[0]?.id);
  }, [featuresList]);

  const featureEntities = useMemo(() => {
    const result = {};
    featuresList.forEach(item => result[item.id] = item);
    return result;
  }, [featuresList]);

  const accordionItems = useMemo(() => {
    return featuresList.map(data => ({
      id: data.id,
      mainElement: (isActive) => <MainAccordionLayout title={data.title} isActive={isActive} />,
      subElement: (isActive) => <SubAccordionLayout text={data.description} isActive={isActive} />
    }));
  }, [featuresList]);

  const onExpand = useCallback(([id]) => {
    if (id === undefined) return;

    setCurrentFeatureId(id);
  }, []);

  const carouselCallback = useCallback((slideIndex) => {
    const id = featuresList[slideIndex]?.id;
    setCurrentFeatureId(id);
  }, [featuresList]);

  const featureIconsCallback = (e) => {
    const id = Number(e.currentTarget.dataset.featureId);
    setCurrentFeatureId(id);
  };

  const currentFeature = useMemo(() => {
    return featureEntities[currentFeatureId]
  }, [currentFeatureId, featureEntities]);

  const hasScreenshots = Boolean(featuresList.length) && currentFeatureId !== undefined &&
    currentFeature && Boolean(currentFeature.screenshots.length);

  const showSecondScreenshot = hasScreenshots && currentFeature.screenshots.length >= 2;

  return (
    <section className={'md:max-w-6xl w-full'}>
      <div className={'flex flex-col items-center'}>
        <div className={'flex flex-col items-center w-10/12'}>
          <Title className={'md:!text-8xl'}>
            {t('titles.features')}
          </Title>
          <Paragraph className={'md:hidden p-4 text-center'}>
            {t('descriptions.features')}
          </Paragraph>
        </div>

        <div className={'hidden md:flex gap-1.5 mt-14'}>
          {featuresList.map(item =>
              <span onClick={featureIconsCallback} key={item.title}
                    className={`w-32 h-32 rounded-3xl overflow-hidden cursor-pointer ${currentFeatureId === item.id ? 'opacity-100' : 'opacity-50'}`}
                    data-feature-id={item.id}>
            <img src={item.icon} alt={item.title} className={'w-full h-full object-cover'} />
          </span>
          )}
        </div>

        <div className={'flex flex-col md:flex-row justify-between w-full mt-12 md:mt-24 [&>div]:flex-1'}>
          {hasScreenshots &&
          <div className={'relative flex justify-center md:justify-end items-center mx-auto pt-[25rem] md:pt-0'}>
            <div className={'relative contents md:block h-full'}>
            <img src={currentFeature.screenshots[0]} alt={`${currentFeature.title} preview`}
                 className={'max-w-xs object-contain absolute md:relative top-0 ml-10 md:ml-0 md:right-0 md:top-20 md:z-[2]'} />
            {showSecondScreenshot &&
            <img src={currentFeature.screenshots[1]} alt={`${currentFeature.title} preview`}
                 className={'hidden md:block absolute right-0 top-36 z-[1] -translate-x-2/3 max-w-xs'} />}
            </div>
          </div>}

          {(!!accordionItems.length && currentFeatureId !== undefined) && <div className={'hidden md:block'}>
            <Accordion items={accordionItems} callback={onExpand} expand={currentFeatureId} expandDuration={500}
                       itemContainerClassName={isActive => `rounded-3xl ${isActive ? 'bg-rose' : 'bg-white'}`}
                       singleView />
          </div>}

          <div className={'md:hidden relative bg-white px-4 pt-12 mb-10'}>
            <Carousel slidesPerView={1} className={'rounded-3xl'} callback={carouselCallback}
                      applyControls={props => <ArrowControls {...props} />}>
              {featuresList.map(data =>
                <div key={data.id} className={'w-full h-full select-none p-6 bg-rose'}>
                  <div className={'rounded-2xl overflow-hidden w-28 h-28'}>
                    <img src={data.icon} alt={`${data.title} icon`} draggable={false} className={'w-full h-full object-cover'}/>
                  </div>
                  <div className={'mt-4'}>
                    <Title>
                      {data.title}
                    </Title>
                  </div>
                  <Paragraph className={'mt-10'}>
                    {data.description}
                  </Paragraph>
                </div>
              )}
            </Carousel>
          </div>

        </div>
      </div>
    </section>
  );
})
