import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useBounds } from '../../utils/hooks/useWindowBounds';

export default React.memo(function ToolTip({ children, text, className = '', wrapperClassName = '' }) {
  const wrapperRef = useRef(null);
  const toolTipRef = useRef(null);
  const [visible, setVisible] = useState(false);
  const bounds = useBounds(wrapperRef.current, toolTipRef.current, visible, true);

  const onMouseEnter = () => {
    setVisible(true);
  };

  const onMouseLeave = () => {
    setVisible(false);
  };

  const onScroll = () => {
    setVisible(false);
  };

  useLayoutEffect(() => {
    wrapperRef.current.onmouseenter = onMouseEnter;
    wrapperRef.current.onmouseleave = onMouseLeave;

    return () => {
      wrapperRef.current.onmouseenter = undefined;
      wrapperRef.current.onmouseleave = undefined;
    }
  }, []);

  useEffect(() => {
    if (visible) {
      window.addEventListener('scroll', onScroll);
    } else {
      window.removeEventListener('scroll', onScroll)
    }

    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [visible]);

  return !children ? null : (
    <>
      <div ref={wrapperRef} className={`flex ${wrapperClassName}`}>
        {children}
      </div>
      <div ref={toolTipRef}
           className={`${visible ? 'block' : 'hidden'} fixed pointer-events-none before:bg-toolTipIcon before:w-4 before:h-1.5 before:bg-no-repeat before:absolute before:top-0 before:left-1/2 before:-translate-x-1/2 z-10 -translate-x-1/2 drop-shadow ${className}`}
           style={bounds}>
        <div className={'flex px-2 py-1.5 mt-1.5 rounded-lg bg-white whitespace-nowrap'}>
          <span>
          {text}
          </span>
        </div>
      </div>
    </>
  );
})
