import React from 'react';

export default React.memo(function ImageBlock({ src, mobileSrc, children, showOverlay = true, className = '', childWrapperClassName = '' }) {
  return (
    <section
      className={`relative w-full min-h-screen ${className} ${showOverlay ? 'after:absolute after:inset-0 after:bg-black after:opacity-50 after:z-[-1]' : ''}`}>
      {src && <img src={src} alt={'page background'}
                   className={`${mobileSrc ? 'hidden md:block' : 'block'} absolute inset-0 w-full h-full object-cover z-[-2]`} />}
      <img src={mobileSrc} alt={'page mobile background'}
           className={`${mobileSrc ? 'md:hidden' : 'hidden'} absolute inset-0 w-full h-full object-cover z-[-2]`} />

      <div className={`relative w-full min-h-screen flex items-center pb-4 pt-20 md:pt-40 ${childWrapperClassName}`}>
        {children}
      </div>
    </section>
  );
})
