const transformGameTitleToUrl = (title) => {
  return title.replace(/\s/g, '-').toLowerCase();
};

const parseGameBundleFromUrlParam = (param) => {
  return param.replace(/-/g, '').toLowerCase();
};

export {
  transformGameTitleToUrl,
  parseGameBundleFromUrlParam
}
