import React from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import { ReactComponent as ArrowUp } from '../../assets/icons/system/arrow-up.svg';
import { useTranslation } from 'react-i18next';

export default React.memo(function CollaborationBlock() {
  const { t } = useTranslation('common');

  const scrollTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  return (
    <div
      className={`relative bg-black flex flex-col w-full md:w-11/12 md:max-w-max md:rounded-[2.5rem] py-40 px-4 md:p-16 md:after:content-[''] md:after:absolute md:after:top-0 md:after:left-1/2 md:after:w-px md:after:h-full md:after:bg-white md:after:opacity-20`}>
      <div className={'flex flex-col md:flex-row items-center w-full md:[&>div]:flex-1'}>
        <div className={'flex justify-center w-10/12'}>
          <Title className={'!text-white md:text-left md:max-w-xs md:leading-[5rem]'}>
            {t('titles.collaboration')}
          </Title>
        </div>
        <div className={'flex'}>
          <div className={'flex flex-col items-center md:items-start md:px-24'}>
            <Paragraph className={'!text-white text-center md:text-left mt-4 md:mt-0'}>
              {t('descriptions.collaboration')}
            </Paragraph>
            <span className={'text-white text-2xl font-bold mt-12 underline hover:cursor-pointer'}>
                  <a href={'mailto:hello@onlyplay.net'}>hello@onlyplay.net</a>
                </span>
          </div>
        </div>
      </div>

      <div className={'hidden md:flex justify-end mt-16 w-full'}>
        <button onClick={scrollTop}>
          <ArrowUp />
        </button>
      </div>
    </div>
  );
})
