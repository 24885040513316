import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {
  globalSpinnerVisible: true,
  sideBarOpen: false,
  scrollBlockers: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSideBar: (state, action) => {
      state.sideBarOpen = action.payload !== undefined ? Boolean(action.payload) : !state.sideBarOpen;
    },
    setGlobalSpinnerVisibility: (state, action) => {
      state.globalSpinnerVisible = action.payload;
    },
    blockScroll: (state, action) => {
      state.scrollBlockers.push(action.payload);
      if (state.scrollBlockers.length === 1) {
        const scrollWidth = window.innerWidth - document.documentElement.clientWidth;
        document.body.style.marginRight = `${scrollWidth}px`;
        document.body.style.top = `-${window.scrollY}px`;
        document.body.style.position = 'fixed';
      }
    },
    releaseScroll: (state, action) => {
      state.scrollBlockers = state.scrollBlockers.filter(item => item !== action.payload);
      if (!state.scrollBlockers.length) {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  },
});

export const getSideBarOpen = createSelector(
  state => state.app,
  ({ sideBarOpen }) => sideBarOpen
);

export const { toggleSideBar, blockScroll, releaseScroll } = appSlice.actions;
