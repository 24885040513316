import React, { useState } from 'react';
import video from '../../assets/video/jackpotter.mp4';

export default React.memo(function VideoBlock({ src, type = 'video/mp4', children }) {
  const [muted, setMuted] = useState(true);

  const toggleMute = () => {
    setMuted(prev => !prev);
  };

  return (
    <section
      className={'relative min-h-screen w-full flex flex-col items-center after:absolute after:inset-0 after:bg-black after:opacity-50 after:z-[-1]'}>

      <video autoPlay muted={muted} loop preload={'auto'} playsInline
             className={'absolute inset-0 w-full h-full object-cover z-[-1]'}>
        <source src={src} type={type} />
      </video>

      <div className={'relative w-full min-h-screen flex items-center pb-4 pt-20 md:pt-40'}>
        {children}
      </div>
    </section>
  );
})
