import React from 'react';
import { ReactComponent as ArrowLeft } from '../../../assets/icons/system/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/system/arrow-right.svg';
import { useTranslation } from 'react-i18next';

export default React.memo(function ArrowControls({ showPrevious, showNext, getCurrentIndex, totalCount, className = '' }) {
  const { t } = useTranslation('common');

  return (
    <div className={`flex items-center justify-between mt-12 pt-3 border-t border-slate-200 ${className}`}>
      <button className={'p-3 border border-slate-300 rounded-lg shadow-sm shadow-[rgba(16,_24,_40,_0.05)]'}
              onClick={showPrevious}>
        <ArrowLeft />
      </button>

      <span className={'text-gray text-lg'}>
        {t('labels.shows_n_of', { n: getCurrentIndex() + 1, total: totalCount })}
      </span>

      <button className={'p-3 border border-slate-300 rounded-lg shadow-sm shadow-[rgba(16,_24,_40,_0.05)]'}
              onClick={showNext}>
        <ArrowRight />
      </button>
    </div>
  );
})
