import React from 'react';
import Ticker from '../../uiKit/ticker';
import Paragraph from '../../uiKit/typography/paragraph';
import { useTranslation } from 'react-i18next';
import { useGetUpcomingGameQuery } from '../../redux/api/games';

export default React.memo(function UpcomingGameBlock() {
  const { t } = useTranslation('common');
  const { data: upcomingGameData, isFetching } = useGetUpcomingGameQuery();

  return (isFetching || !upcomingGameData) ? null : (
    <section className={'w-full'}>
      <BackgroundWrapper desktopBackground={upcomingGameData.backgroundImage} mobileBackground={upcomingGameData.backgroundImageMobile}>
        <div className={'relative w-full h-auto md:w-2/3 xl:w-1/2 flex flex-col items-center justify-between md:justify-start pb-4'}>

          <Ticker title={t('titles.upcoming_game')} timestamp={new Date(upcomingGameData.releaseDate)} />

          <Paragraph className={'!text-white text-center hidden md:block mt-6 mb-16'}>
            {upcomingGameData.description}
          </Paragraph>

          <div>
            <img src={upcomingGameData.logo} alt={`${upcomingGameData.name} logo`} />
          </div>
        </div>
      </BackgroundWrapper>
    </section>
  );
})

function BackgroundWrapper({ desktopBackground, mobileBackground, children }) {
  return (
    <>
    <div className={'hidden md:flex relative h-[45rem] justify-end items-stretch bg-cover bg-no-repeat bg-center lg:px-10 xl:px-48'}
      style={{ backgroundImage: `url(${desktopBackground})` }}>
      {children}
    </div>
    
    <div className={'flex md:hidden relative h-[42.5rem] justify-end items-stretch bg-cover bg-no-repeat bg-center'}
      style={{ backgroundImage: `url(${mobileBackground})` }}>
      {children}
    </div>
    </>
  );
}