import React from 'react';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useStorage } from '../../utils/hooks/useStorage';
import eStorageKeys from '../../enums/storageKeys';

export default React.memo(function CookiesModal() {
  const { t } = useTranslation('common');
  const [cookiesData, setCookiesData] = useStorage(eStorageKeys.ESK_COOKIES_ACCEPT);
  const [ageConfirm] = useStorage(eStorageKeys.ESK_AGE_CONFIRM);

  const declineCookies = () => {
    setCookiesData({
      timestamp: Date.now(),
      accepted: false,
    });
  };

  const acceptCookies = () => {
    setCookiesData({
      timestamp: Date.now(),
      accepted: true,
    });
  };

  return (
    <div className={`z-[10000] bottom-8 px-8 ${!ageConfirm ? 'hidden' : cookiesData ? 'hidden' : 'fixed animate-fadeIn'}`}>
      <div
        className={'w-full max-w-7xl rounded-2xl bg-white shadow-xl flex flex-col md:flex-row items-center px-8 py-6 gap-12'}>
        <div>
          <Paragraph>
            {t('descriptions.cookies_usage')}
            <Link to={'/cookies'} className={'underline'}>
              {t('labels.cookies')}
            </Link>
          </Paragraph>
        </div>

        <div className={'flex gap-4 h-max'}>
          <ButtonFulfilled onClick={acceptCookies} className={'text-white px-8'}>
            {t('buttons.allow')}
          </ButtonFulfilled>

          <button onClick={declineCookies} className={'py-4 px-8 text-gray'}>
            {t('buttons.decline')}
          </button>
        </div>
      </div>
    </div>
  );
})
