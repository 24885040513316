import React from 'react';
import FileDrop from '../../inputs/fileDrop';
import { Range } from '../../inputs';
import { useNode } from '@craftjs/core';
import { fileToBase64 } from '../../../utils/fileToBase64';

export default React.memo(function ImageSettings() {
  const { actions: { setProp }, width, borderRadius } = useNode((node) => ({
    width: node.data.props.width,
    borderRadius: node.data.props.borderRadius,
  }));

  const changeImage = async (e) => {
    let file = e.target.files?.[0];
    if (!file) {
      setProp(props => props.src = '');
      return;
    }

    const base64 = await fileToBase64(file);

    if (base64.error) {
      setProp(props => props.src = '');
    } else {
      setProp(props => props.src = base64.result);
    }
  };

  return (
    <div className={'w-full flex flex-col gap-4'}>
      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>Image</span>
        <FileDrop onChange={changeImage} className={'w-full h-24'} label={'Drop here...'} />
      </div>

      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Width: ${width} %`}</span>
        <Range value={width} onChange={(e) => setProp(props => props.width = e.target.value)} />
      </div>

      <div className={'w-full flex flex-col bg-slate-100 p-4 rounded-lg'}>
        <span className={'font-semibold'}>{`Border radius: ${borderRadius}`}</span>
        <Range value={borderRadius} step={0.1} min={0} max={6}
               onChange={(e) => setProp(props => props.borderRadius = e.target.value)} />
      </div>
    </div>
  );
})
