import React, { useEffect } from 'react';
import { useEditor, Frame } from '@craftjs/core';

export default React.memo(function DeserializableFrame({ data, ...props }) {
    const { actions: { deserialize } } = useEditor();

    useEffect(() => {
      deserialize(data);
    }, [data]);

    return <Frame {...props} />;
});
