import React, { useCallback, useMemo, useState } from 'react';
import Accordion from '../../uiKit/accordion';
import Title from '../../uiKit/typography/title';
import MainAccordionLayout from '../../uiKit/accordion/layout/main';
import SubAccordionLayout from '../../uiKit/accordion/layout/sub';
import { useTranslation } from 'react-i18next';
import { useImagePreload } from '../../utils/hooks/useImagePreload';

export default React.memo(function GameFeatures({ features }) {
  const [currentFeatureId, setCurrentFeatureId] = useState(features[0]?.id);
  useImagePreload(features.reduce((res, feature) => {
    res.push(feature.screenshot);
    return res;
  }, []));
  const { t } = useTranslation('common');

  const featureEntities = useMemo(() => {
    const result = {};
    features.forEach(feature => result[feature.id] = feature);
    return result;
  }, [features]);

  const onExpand = useCallback(([id]) => {
    if (id === undefined) return;

    setCurrentFeatureId(id);
  }, []);

  const currentFeature = useMemo(() => {
    return featureEntities[currentFeatureId]
  }, [featureEntities, currentFeatureId]);

  const accordionItems = useMemo(() => {
    return features.map(item => ({
      id: item.id,
      mainElement: (isActive) => <MainAccordionLayout title={item.title} isActive={isActive} />,
      subElement: (isActive) => <SubAccordionLayout text={item.description} isActive={isActive} />
    }));
  }, [features]);

  return (
    <div className={'w-full flex flex-col md:flex-row [&>div]:flex-1'}>
      <div className={'order-2 md:order-1 relative pt-[24rem] md:pt-0 flex justify-center items-center overflow-hidden'}>
        {<img src={currentFeature.screenshot} alt={currentFeature.title}
              className={'absolute max-w-xs object-contain md:relative top-0 left-1/2 ml-5 md:ml-0 -translate-x-1/2 md:translate-x-0 md:left-0'}/>}
      </div>

      <div className={'order-1 md:order-2 flex flex-col items-center md:items-start'}>
        <Title className={'text-4xl md:text-6xl'}>
          {t('titles.game_features')}
        </Title>

        <Accordion className={'mt-14 p-0'} items={accordionItems} callback={onExpand} expand={currentFeatureId}
                   itemContainerClassName={isActive => `rounded-3xl ${isActive ? 'bg-rose' : 'bg-white'}`}
                   expandDuration={500} singleView />
      </div>
    </div>
  );
})
