import { useEffect, useState } from 'react';
import { isEqual } from '../isEqual';

const useIntersectionObserver = (targetId, options) => {
  const [intersecting, setIntersecting] = useState(false);
  const [intersectOptions, setIntersectOptions] = useState(options || {
    root: null,
    rootMargin: '0px',
    threshold: 0,
  });

  useEffect(() => {
    if (!options) return;
    if (!isEqual(options, intersectOptions)) setIntersectOptions(options);
  }, [options, intersectOptions]);

  useEffect(() => {
    const target = document.getElementById(targetId);

    const observer = new IntersectionObserver((entry, observer) => {
      setIntersecting(entry[0].isIntersecting)
    }, options);

    observer.observe(target);

    return () => {
      observer.disconnect();
    }
  }, [targetId]);

  return intersecting;
};

export {
  useIntersectionObserver,
}
