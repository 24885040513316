import React from 'react';
import InfiniteSlider from '../../../uiKit/carousel/infinite';
import ShadowCard from '../../../uiKit/cards/shadowCard';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BTC } from 'cryptocurrency-icons/svg/color/btc.svg';
import { ReactComponent as ETH } from 'cryptocurrency-icons/svg/color/eth.svg';
import { ReactComponent as USDT } from 'cryptocurrency-icons/svg/color/usdt.svg';
import { ReactComponent as USDC } from 'cryptocurrency-icons/svg/color/usdc.svg';
import { ReactComponent as BNB } from 'cryptocurrency-icons/svg/color/bnb.svg';
import { ReactComponent as XRP } from 'cryptocurrency-icons/svg/color/xrp.svg';
import { ReactComponent as ADA } from 'cryptocurrency-icons/svg/color/ada.svg';
import { ReactComponent as DOGE } from 'cryptocurrency-icons/svg/color/doge.svg';
import { ReactComponent as DOT } from 'cryptocurrency-icons/svg/color/dot.svg';
import { ReactComponent as MATIC } from 'cryptocurrency-icons/svg/color/matic.svg';
import { ReactComponent as DAI } from 'cryptocurrency-icons/svg/color/dai.svg';
import { ReactComponent as TRX } from 'cryptocurrency-icons/svg/color/trx.svg';

export default React.memo(function CurrenciesSupport({ slidesPerView = 3, className = '' }) {
  const { t } = useTranslation('common');

  const icons = [
    { Icon: BTC, title: 'BTC' },
    { Icon: ETH, title: 'ETH' },
    { Icon: USDT, title: 'USDT' },
    { Icon: USDC, title: 'USDC' },
    { Icon: BNB, title: 'BNB' },
    { Icon: XRP, title: 'XRP' },
    { Icon: ADA, title: 'ADA' },
    { Icon: DOGE, title: 'DOGE' },
    { Icon: DOT, title: 'DOT' },
    { Icon: MATIC, title: 'MATIC' },
    { Icon: DAI, title: 'DAI' },
    { Icon: TRX, title: 'TRX' },
  ];

  return (
    <ShadowCard className={className}>
      <div className={'w-full h-full flex flex-col gap-12 md:gap-4 justify-between'}>
        <div className={'px-6'}>
          <span className={'text-xl md:text-base uppercase whitespace-pre-line'}>
            {t('cards.descriptions.currencies_support')}
          </span>
        </div>

        <div className={'w-full flex flex-col justify-center md:flex-row items-center flex-wrap gap-6'}>
          <InfiniteSlider slidesPerView={slidesPerView} speed={20}
                          className={'[&_svg]:h-24 [&_svg]:w-24 [&_svg]:md:w-14 [&_svg]:md:h-14 [&_svg]:object-contain'}>
            {icons.map(({ Icon, title }) => <Icon key={title} />)}
          </InfiniteSlider>
        </div>
</div>
</ShadowCard>
);
})
