const fileToBase64 = async (file) => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve({ result: reader.result });
    };

    reader.onerror = (error) => {
      reject({ error });
    };

    try {
      reader.readAsDataURL(file);
    } catch (error) {
      reject({ error });
    }
  });
};

export {
  fileToBase64,
}
