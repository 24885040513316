import { useEffect, useState } from 'react';

const useBounds = (toggleEl, windowEl, visible, calculateBounds ) => {
  const [bounds, setBounds] = useState(null);

  const getTransformedParent = (el) => {
    let parent = el.parentElement;

    while (parent) {
      if (!['', 'none', 'unset'].includes(parent.style.transform)) {
        break;
      }
      parent = parent.parentElement;
    }

    return parent;
  };

  useEffect(() => {
    if (!visible || !calculateBounds) return;
    const toggleElBounds = toggleEl.getBoundingClientRect();
    const relativeTo = getTransformedParent(windowEl);
    let top = toggleElBounds.top;
    let left = toggleElBounds.left;
    if (relativeTo) {
      const relativeBounds = relativeTo.getBoundingClientRect();
      top -= relativeBounds.top;
      left -= relativeBounds.left;
    }
    setBounds({ relativeTo, top: top + toggleElBounds.height, left: left + toggleElBounds.width / 2 });
  }, [visible, calculateBounds, toggleEl, windowEl]);

  useEffect(() => {
    if (!bounds || !visible || !calculateBounds) return;
    const windowHeight = windowEl.clientHeight;
    const windowWidth = windowEl.clientWidth;
    let top, left;
    let viewBoxHeight = bounds.relativeTo ? bounds.relativeTo.clientHeight : window.innerHeight;
    let viewBoxWidth = bounds.relativeTo ? bounds.relativeTo.clientWidth : window.innerWidth;
    if (bounds.top + windowHeight > viewBoxHeight) top =viewBoxHeight - windowHeight;
    if (bounds.left + windowWidth / 2 > viewBoxWidth) left = viewBoxWidth - windowWidth;
    if (top || left) setBounds({ top: top || bounds.top, left: left || bounds.left });
  }, [bounds, visible, calculateBounds, windowEl]);

  return { top: bounds?.top, left: bounds?.left };
};

export {
  useBounds,
}
