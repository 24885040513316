import React, { useMemo } from 'react';
import Logo from '../logo';
import Navigation from '../navigation';
import Burger from './burger';
import BackButton from '../../uiKit/buttons/back';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSideBarOpen, toggleSideBar } from '../../redux/slices/appSlice';

const headerPresets = [
  {
    routeRegExp: /\/games$/,
    containerClassName: 'absolute',
    className: 'md:blurred',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/games\/.*$/,
    containerClassName: 'absolute',
    className: 'md:blurred',
    showBackButton: true,
    backButtonLocalizationKey: 'common:navigation.games',
    backButtonLink: '/games',
  },
  {
    routeRegExp: /\/news$/,
    containerClassName: 'relative',
    className: 'md:shadow-header md:bg-white [&_a]:text-black [&_span]:text-black [&_#side-bar-burger>span]:bg-black',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/news\/.*$/,
    containerClassName: 'relative',
    className: 'md:shadow-header md:bg-white [&_a]:text-black [&_span]:text-black [&_#side-bar-burger>span]:bg-black',
    showBackButton: true,
    backButtonLocalizationKey: 'common:navigation.news',
    backButtonLink: '/news',
  },
  {
    routeRegExp: /\/partners$/,
    containerClassName: 'absolute',
    className: 'md:blurred',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/contacts$/,
    containerClassName: 'relative',
    className: 'md:shadow-header md:bg-white [&_a]:text-black [&_span]:text-white [&_#side-bar-burger>span]:bg-black',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/we-hire$/,
    containerClassName: 'absolute',
    className: 'md:blurred',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/we-hire\/.*$/,
    containerClassName: 'absolute',
    className: 'md:blurred',
    showBackButton: true,
    backButtonLocalizationKey: 'common:navigation.hire',
    backButtonLink: '/we-hire',
  },
  {
    routeRegExp: /\/privacy-policy$|cookies$/,
    containerClassName: 'relative',
    className: 'md:shadow-header md:bg-white [&_a]:text-black [&_span]:text-black [&_#side-bar-burger>span]:bg-black',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/404$/,
    containerClassName: 'relative',
    className: 'md:shadow-header md:bg-white [&_a]:text-black [&_span]:text-black [&_#side-bar-burger>span]:bg-black',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
  {
    routeRegExp: /\/admin\/.*/,
    containerClassName: 'relative',
    className: 'md:shadow-header md:bg-white [&_a]:text-black [&_span]:text-black [&_#side-bar-burger>span]:bg-black',
    showBackButton: false,
    backButtonLocalizationKey: '',
    backButtonLink: '',
  },
];

const defaultPreset = {
  containerClassName: 'absolute',
  className: '',
  showBackButton: false,
  backButtonLocalizationKey: '',
  backButtonLink: '',
};

export default React.memo(function Header() {
  const { t } = useTranslation('common');
  const location = useLocation();
  const isSideBarOpen = useSelector(getSideBarOpen);
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(toggleSideBar())
  };

  const preset = useMemo(() => {
    let result = headerPresets.find(config => config.routeRegExp.test(location.pathname));
    if (!result) result = defaultPreset;
    return result;
  }, [location.pathname]);

  return (
    <div className={`${preset.containerClassName} top-0 left-0 w-full px-4 py-3 md:py-12 md:px-0 z-[200]`}>
      <div className={`md:px-10 md:py-4 lg:px-20 ${preset.className}`}>
        <div className={'flex flex-row items-center justify-between w-full'}>
          <div>
            <Logo />
          </div>

          <Navigation className={'hidden md:block [&_ul]:gap-6 lg:[&_ul]:gap-16'} />

          <NavLink to={'/contacts'}
                   className={({ isActive }) => isActive ? '[&>span]:after:opacity-100' : '[&>span]:after:opacity-0'}>
            <span
              className={'hidden md:block py-4 px-6 text-white text-lg overflow-hidden btn__outlined rounded-2xl after:absolute after:inset-0 after:z-[0] after:transition-all after:bg-gradient'}>
              <span className={'relative z-[1] select-none'}>
                {t('buttons.contacts')}
              </span>
            </span>
          </NavLink>

          <Burger open={isSideBarOpen} toggle={toggle} />

        </div>
      </div>

      {preset.showBackButton && !isSideBarOpen &&
      <BackButton link={preset.backButtonLink} localizationKey={preset.backButtonLocalizationKey} className={`mt-6 ml-4 md:ml-16 transition-all animate-fadeIn ${isSideBarOpen ? 'opacity-0' : 'duration-1000 opacity-1'}`} />
      }
    </div>
  );
});
