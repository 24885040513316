import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import { useTranslation } from 'react-i18next';

export default React.memo(function CookiesPage() {
  const { t, i18n } = useTranslation('cookies');

  const view = useMemo(() => {
    const result = [];

    for (let i = 0; i <= 5; i++) {
      result.push(
        <div key={i} className={'w-full flex flex-col gap-6'}>
          <Title className={'!text-left !text-4xl'}>
            {t(`titles.title_${i}`)}
          </Title>
          <Paragraph className={'break-words'}>
            {t(`descriptions.description_${i}`)}
          </Paragraph>
        </div>
      )
    }

    return result;
  }, [i18n.language]);

  return (
    <div className={'w-full flex justify-center pb-40 pt-8 px-4'}>

      <div className={'w-full flex flex-col gap-24 items-center max-w-3xl'}>
        <Title className={'!text-8xl'}>
          {t('titles.page_title')}
        </Title>

        <div className={'w-full flex flex-col gap-12'}>
          {view}
        </div>
      </div>

    </div>
  );
})
