import React, { useCallback, useMemo, useState } from 'react';
import Filters from './filters';
import GameCard from '../gameCard';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import { useQueryParams } from '../../utils/hooks/useQueryParams';
import { useTranslation } from 'react-i18next';
import { getGamesList, useGetGamesDataQuery } from '../../redux/api/games';
import { useSelector } from 'react-redux';
import { useImagePreload } from '../../utils/hooks/useImagePreload';
import GameCardSkeleton from '../gameCard/skeleton';

export default React.memo(function GamesList() {
  const { t } = useTranslation('common');
  const { isFetching } = useGetGamesDataQuery();
  const gamesList = useSelector(getGamesList);
  const loaded = useImagePreload(gamesList.reduce((res, game) => {
    res.push(game.iconPreview, game.iconSquare);
    return res;
  }, []));
  const [searchParams, setSearchParams] = useQueryParams({
    type: value => value.split(',').map(id => Number(id)),
    feature: value => value.split(',').map(id => Number(id)),
  });

  const [page, setPage] = useState(1);
  const [openCardId, setOpenCardId] = useState(null);
  const gamesOnPage = 8;

  const gamesLimit = useMemo(() => {
    return gamesOnPage * page;
  }, [page]);

  const filteredGames = useMemo(() => {
    return gamesList.filter(item => {
      let match = true;

      if (searchParams.hasOwnProperty('term')) {
        if (!item.name.toUpperCase().includes(searchParams.term.toUpperCase()) &&
          !item.gameBundle.toUpperCase().includes(searchParams.term.toUpperCase())) match = false;
      }

      if (searchParams.hasOwnProperty('type')) {
        if (searchParams.type.some(id => !item.gameTypeIds.includes(id))) match = false;
      }

      if (searchParams.hasOwnProperty('feature')) {
        if (searchParams.feature.some(id => !item.gameFeatureIds.includes(id))) match = false;
      }

      return match;
    });
  }, [searchParams, gamesList]);

  const list = useMemo(() => {
    return filteredGames.length ? filteredGames : (gamesList.length ? [] : Array.from({ length: page * gamesOnPage }, () => null));
  }, [filteredGames, gamesList, page, gamesOnPage]);

  const onCardClick = useCallback((id) => {
    setOpenCardId(id);
  }, []);

  const showMore = () => {
    setPage(prev => prev + 1);
  };

  const showLoadMoreButton = useMemo(() => gamesLimit < filteredGames.length, [filteredGames, gamesLimit]);

  return (
    <div className={'w-full flex flex-col md:flex-row gap-8'}>
      <div className={'w-full md:w-1/2 lg:w-1/3'}>
        <Filters searchParams={searchParams} setSearchParams={setSearchParams} />
      </div>

      <div className={'w-full'}>

        <div className={'flex flex-col gap-24'}>
          <div
            className={`grid grid-cols-[_22rem] auto-rows-[22rem] xl:grid-cols-[repeat(2,minmax(0,_22rem))] justify-center gap-8 w-full [&>*]:w-[22rem]`}>
            {list.slice(0, page * gamesOnPage).map((game, index) => game && loaded.includes(game.iconSquare) && loaded.includes(game.iconPreview) ?
              <GameCard key={game.gameBundle} game={game} callback={onCardClick} isOpen={game.id === openCardId} /> :
              <GameCardSkeleton key={index} />
            )}
          </div>

          {showLoadMoreButton && <div className={'w-full flex items-center justify-center'}>
            <ButtonOutlined className={'px-6'} onClick={showMore}>
              {t('buttons.load_more')}
            </ButtonOutlined>
          </div>}
        </div>

      </div>
    </div>
  );
});
