import React from 'react';
import ShadowCard from '../../../uiKit/cards/shadowCard';
import { useTranslation } from 'react-i18next';

export default React.memo(function OperatorsCount({ className = '' }) {
  const { t } = useTranslation('common');

  return (
    <ShadowCard className={className}>
      <div className={'w-full h-full flex flex-col gap-2'}>
        <div>
          <span className={'font-Staatliches text-6xl uppercase break-words whitespace-pre-line'}>
            2000
          </span>
        </div>

        <div className={'w-full flex items-center'}>
          <span className={'text-base uppercase'}>
            {t('cards.descriptions.operators_count')}
          </span>
        </div>

      </div>
    </ShadowCard>
  );
})
