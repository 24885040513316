import React from 'react';
import { useEditor } from '@craftjs/core';

export default function Border({ highlight }) {
  const { editable } = useEditor((state) => ({
    editable: state.options.enabled,
  }));

  return !editable ? null : (
    <div className={`absolute inset-0 pointer-events-none border border-dashed ${highlight ? 'border-blue-400' : 'border-blue-400/20'}`}
         style={{ borderRadius: 'inherit' }} />
  );
}
