import React from 'react';
import LinkFulfilled from '../../uiKit/links/fulfilled';
import LinkOutlined from '../../uiKit/links/outlined';

import { ReactComponent as ShareIcon } from '../../assets/icons/system/share-icon.svg';
import { useTranslation } from 'react-i18next';

export default React.memo(function GameDescriptionBlock({ game, className }) {
  const { t } = useTranslation('common');

  const canShare = Boolean(navigator.share);

  const shareLink = async () => {
    try {
      await navigator.share({
        title: game.name,
        text: 'Play & win',
        url: window.location.href,
      })
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div
      className={`relative w-full flex flex-col md:flex-row items-center md:items-stretch justify-between [&>*]:flex-1 gap-8 p-6 before:absolute before:blurred before:md:rounded-2xl before:top-24 before:md:top-0 before:inset-0 before:z-[-1] ${className}`}>

      <div className={'flex flex-col items-center md:flex-row gap-4 py-2'}>
        <div className={'h-32 w-32 shrink-0 rounded-2xl overflow-hidden'}>
          <img src={game.iconSquare} alt={`${game.name} icon`} className={'h-full w-full object-contain'} />
        </div>

        <div className={'text-center md:text-left'}>
          <h1 className={'font-Staatliches text-[3.5rem] leading-none text-white'}>
            {game.name}
          </h1>
        </div>
      </div>

      <div className={'flex items-center text-center md:text-left'}>
        <p className={'text-lg text-white line-clamp-6'}>
          {game.overview}
        </p>
      </div>

      <div className={'flex gap-4 justify-end'}>
        <div className={'flex flex-col w-44 gap-2.5 justify-center'}>
          <LinkFulfilled external className={'w-full text-white'} to={game.demoLink} target={'_blank'}
                         rel={'noopener noreferrer'}>
            {t('buttons.try_now')}
          </LinkFulfilled>

          {game.trailerLink && <LinkOutlined external to={game.trailerLink} className={'w-full text-white'} target={'_blank'}
                        rel={'noopener noreferrer'}>
            {t('buttons.watch_trailer')}
          </LinkOutlined>}
        </div>

        {canShare &&
        <div className={'absolute top-32 right-6 md:relative md:top-0 md:right-0 flex items-center justify-center'}>
          <button onClick={shareLink}>
            <ShareIcon />
          </button>
        </div>
        }

      </div>
    </div>
  );
})
