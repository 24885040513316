import { createEntityAdapter, createSelector } from '@reduxjs/toolkit';
import { apiSlice } from './index';

export const featuresAdapter = createEntityAdapter();
export const gameTypesAdapter = createEntityAdapter();
export const gamesListAdapter = createEntityAdapter({
  selectId: (entity) => entity.gameBundle
});

const featuresInitialState = featuresAdapter.getInitialState();
const gameTypesInitialState = gameTypesAdapter.getInitialState();
const gameListInitialState = gamesListAdapter.getInitialState();

export const gamesExtendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getGamesData: builder.query({
      query: () => '/common/get_games_list',
      providesTags: ['GamesData'],
      transformResponse: responseData => {
        const features = featuresAdapter.setAll(featuresInitialState, responseData.data.gameFeatures);
        const gameTypes = gameTypesAdapter.setAll(gameTypesInitialState, responseData.data.gameTypes);
        const gamesList = gamesListAdapter.setAll(gameListInitialState, responseData.data.games);
        return { features, gameTypes, gamesList };
      }
    }),
    getGame: builder.query({
      query: bundle => `/common/get_game?gameBundle=${bundle}`,
      providesTags: (result, err, arg) => [{ type: 'Game', id: arg }],
      transformResponse: responseData => responseData.data
    }),
    getUpcomingGamesList: builder.query({
      query: () => '/common/get_upcoming_games_list',
      providesTags: ['UpcomingGame'],
      transformResponse: responseData => responseData.data
    }),
    getUpcomingGame: builder.query({
      query: () => '/common/get_upcoming_game',
      providesTags: ['UpcomingGame'],
      transformResponse: responseData => responseData.data
    }),
  })
});


const selectGamesDataResult = gamesExtendedApi.endpoints.getGamesData.select();

const selectGamesListResult = createSelector(
  selectGamesDataResult,
  ({ data }) => data?.gamesList
);

const selectGameTypesResult = createSelector(
  selectGamesDataResult,
  ({ data }) => data?.gameTypes
);

const selectFeaturesResult = createSelector(
  selectGamesDataResult,
  ({ data }) => data?.features
);

export const { selectAll: getGamesList, selectEntities: getGamesEntities } = gamesListAdapter.getSelectors(state => selectGamesListResult(state) ?? gameListInitialState);
export const { selectAll: getGameTypesList, selectEntities: getGameTypeEntities } = gamesListAdapter.getSelectors(state => selectGameTypesResult(state) ?? gameTypesInitialState);
export const { selectAll: getGameFeaturesList, selectEntities: getGameFeatureEntities, selectById: getGameFeatureById } = gamesListAdapter.getSelectors(state => selectFeaturesResult(state) ?? featuresInitialState);

export const { useGetGamesDataQuery, useGetGameQuery, useGetUpcomingGameQuery, useGetUpcomingGamesListQuery } = gamesExtendedApi;
