import React from 'react';
import { Text, Container, Image, Title, Video } from '../components';
import { Editor } from '@craftjs/core';
import DeserializableFrame from './deserializableFrame';

export default React.memo(function PageEditor({ data }) {
  return (
    <Editor enabled={false} resolver={{ Container, Title, Image, Text, Video }}>
      <DeserializableFrame data={data} />
    </Editor>
  );
})
