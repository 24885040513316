import React from 'react';

export default React.memo(function DeleteButton({ className = '', ...props }) {
  return (
    <button type={'button'} {...props}
            className={`absolute z-[2] top-0 right-0 -translate-y-1/2 translate-x-1/2 flex flex-col w-4 h-4 rounded-full bg-black items-center justify-center transition-all hover:scale-125 hover:rotate-90 ${className}`}>
      <span className={'absolute w-2 h-px block bg-white -rotate-45'} />
      <span className={'absolute w-2 h-px block bg-white rotate-45'} />
    </button>
  );
})
