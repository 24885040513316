import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import Cards from '../../uiKit/cards';

import iphone from '../../assets/images/iphone_pig.png';
import screen from '../../assets/images/pad_jackPotter_in_Vegas.png';
import { useTranslation } from 'react-i18next';

export default React.memo(function GamingSolutionsBlock() {
  const { t, i18n } = useTranslation('common');

  const cardsAbout = useMemo(() => [
    {
      id: 0,
      title: t('cards.titles.solutions_0'),
      text: t('cards.descriptions.solutions_0')
    },
    {
      id: 1,
      title: t('cards.titles.solutions_1'),
      text: t('cards.descriptions.solutions_1')
    },
    {
      id: 2,
      title: t('cards.titles.solutions_2'),
      text: t('cards.descriptions.solutions_2')
    },
  ], [i18n.language]);

  // const [iphoneToggled, toggleIphone] = useState(false);
  // const iphoneRef = useRef(null);
  // const parallaxRef = useRef(null);
  // const listener = useCallback(() => {
  //   toggleIphone(isInViewPort(iphoneRef.current));
  // }, []);
  //
  // useEffect(() => {
  //   parallaxRef.current.container.current.onscroll = listener;
  // }, []);

  return (
    <section className={'flex items-center justify-center flex-col w-full md:max-w-7xl'}>
      <div
        className={'flex items-center justify-center flex-col gap-4 w-10/12 mx-auto text-center md:gap-10'}>
        <Title>
          {t('titles.solutions')}
        </Title>
        <Paragraph className={'md:w-2/3'}>
          {t('descriptions.solutions')}
        </Paragraph>
      </div>

      <div className={'relative w-full md:w-max flex items-center justify-center pt-[25rem] md:pt-0 md:my-20'}>
        <img src={iphone} alt={'iphone image'}
             className={'max-w-full object-contain ml-0 md:ml-12 absolute top-0 left-1/2 -translate-x-1/2 md:left-[32%] md:-top-[7%] md:-translate-x-full lg:ml-[6%] lg:left-[24%] xl:ml-[8%] xl:-left-[24%] xl:translate-x-0 '} />
        <img src={screen} alt={'desktop image'}
             className={'hidden shrink-0 md:block md:ml-[12%] lg:ml-[34%] xl:ml-[28%]'} />
      </div>

      <div className={'relative w-full md:mt-10 bg-white md:bg-transparent'}>
        <div className={'w-full flex justify-center text-center md:text-left'}>
          <Cards cardsData={cardsAbout}
                 className={'md:max-w-5xl [&_li_div>div]:w-2/3 [&_li_hr]:w-2/3 [&_li_div>div]:md:w-full [&_li_hr]:md:w-full [&_li]:md:max-w-xxs'} />
        </div>
      </div>
    </section>
  );
})
