import React, {useImperativeHandle, useRef} from 'react';

export default React.memo(React.forwardRef(function BaseForm({ submit, onError, children, className = '' }, ref) {
  const formRef = useRef(null);

  const onSubmit = (e) => {
    e.preventDefault();
    const errors = validate();

    if (errors) {
      onError && onError(errors);
    } else {
      submit(e);
    }
  };

  const validate = () => {
    const inputs = formRef.current.elements;
    let errorFields = [];

    for (let input of inputs) {
      if (!input.validity.valid) {
        if (!errorFields) errorFields = [];
        errorFields.push(input.name);
      }
    }

    return errorFields.length ? errorFields : null;
  };
  
  useImperativeHandle(ref, () => ({
    validate,
  }))

  return (
    <form ref={formRef} onSubmit={onSubmit} className={`w-full flex flex-col gap-12 ${className}`} noValidate>
      {children}
    </form>
    );
}))
