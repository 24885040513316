import { useEffect } from 'react';

const useMetaTags = (metaData) => {

  useEffect(() => {
    let existingTags = [];
    let tags = [];
    for (let data of metaData) {
      const tag = document.createElement('meta');

      Object.assign(tag, data);
      if (data.hasOwnProperty('property')) {
        tag.setAttribute('property', data.property);
      }

      const existingTag = document.head.querySelector(`meta[property="${data.property}"]`) || document.head.querySelector(`meta[name="${data.name}"]`);
      if (existingTag) {
        existingTags.push(existingTag);
        existingTag.remove();
      }

      tags.push(tag);
      document.head.prepend(tag);
    }

    return () => {
      tags.forEach(tag => tag.remove());
      existingTags.forEach(tag => document.head.prepend(tag));
    }
  }, [metaData]);
};

export {
  useMetaTags,
}
