import React, { useCallback } from 'react';
import { SearchInput } from '../../uiKit/inputs';
import DropDownFilterSelect from './dropDownFilterSelect';
import Accordion from '../../uiKit/accordion';
import MainAccordionLayout from '../../uiKit/accordion/layout/main';
import FilterSelect from './filterSelect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getGameFeaturesList, getGameTypesList } from '../../redux/api/games';

export default React.memo(function GamesListFilters({ searchParams, setSearchParams }) {
  const { t } = useTranslation('common');
  const gameTypes = useSelector(getGameTypesList);
  const features = useSelector(getGameFeaturesList);

  const onSearchTermChange = useCallback((e) => {
    const term = e.target.value;
    setSearchParams({ ...searchParams, term });
  }, [searchParams, setSearchParams]);

  const selectType = (ids) => {
    setSearchParams({ ...searchParams, type: ids.length ? ids : null });
  };

  const selectFeature = (ids) => {
    setSearchParams({ ...searchParams, feature: ids.length ? ids : null });
  };

  return (
    <>
      <div className={'w-full flex flex-col'}>
        <SearchInput placeholder={'Search'} value={searchParams.term || ''} onChange={onSearchTermChange} />
      </div>

      <div className={'w-full flex flex-col gap-4 md:gap-10 mt-4 md:mt-10'}>
          <span className={'font-Staatliches text-black md:text-gray text-3xl md:text-2xl'}>
            {t('labels.filters')}
          </span>

        <div className={'h-px w-full bg-gradient-to-b from-[#F79F2D] to-[#E44E98]'} />

        <div className={'w-full'}>
          <div className={'md:hidden flex flex-col gap-2'}>
            <DropDownFilterSelect title={t('labels.type')} items={gameTypes} selected={searchParams.type} select={selectType} />
            <DropDownFilterSelect title={t('labels.feature')} items={features} selected={searchParams.feature}
                                  select={selectFeature} multiSelect />
          </div>

          <div className={'hidden md:block'}>
            <Accordion className={'p-0'}
                       itemContainerClassName={isActive => `rounded-3xl ${isActive ? 'bg-rose' : 'bg-white'}`}
                       items={[{
                         id: 0,
                         mainElement: (isActive) => <MainAccordionLayout title={t('labels.type')} isActive={isActive} />,
                         subElement: () => <FilterSelect items={gameTypes} selected={searchParams.type}
                                                         select={selectType} />,
                       }, {
                         id: 1,
                         mainElement: (isActive) => <MainAccordionLayout title={t('labels.feature')} isActive={isActive} />,
                         subElement: () => <FilterSelect items={features} selected={searchParams.feature}
                                                         select={selectFeature} multiSelect />
                       }]} />
          </div>
        </div>
      </div>
    </>
  );
})
