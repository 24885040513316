import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import NewsCards from '../news/cards';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetMainNewsQuery, useGetNewsListQuery } from '../../redux/api/common';

export default React.memo(function NewsBlock({ excludeId }) {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { data = { news: [], pagesCount: 0 }, isFetching } = useGetNewsListQuery(1);
  const { data: mainNews, isFetching: isMainNewsFetching } = useGetMainNewsQuery();

  const filteredList = useMemo(() => {
    let res = [];
    if (mainNews) res.push(mainNews);
    res.push(...data.news);

    if (excludeId !== undefined) {
      res = res.filter(item => Number(item.id) !== Number(excludeId));
    }

    return res;
  }, [data.news, mainNews, excludeId]);

  const slicedList = useMemo(() => {
    return filteredList.slice(0, 3).map(item => ({
      imageSrc: item.icon,
      title: item.title,
      text: item.description,
      link: `/news/${item.urlName}`,
      id: item.id
    }));
  }, [filteredList]);

  const goToNewsPage = () => {
    navigate('/news');
  };

  return (
    <section className={'flex items-center flex-col px-4 w-full max-w-6xl'}>
      <div className={'flex items-center justify-center md:justify-between w-full'}>
        <Title>
          {t('titles.last_news')}
        </Title>
        <ButtonOutlined onClick={goToNewsPage} className={'hidden md:block px-6'}>
          {t('buttons.read_news')}
        </ButtonOutlined>
      </div>
      <div className={'w-full'}>
        <NewsCards cardsData={slicedList} />
      </div>
      <div className={'flex items-center justify-center w-full'}>
        <ButtonOutlined onClick={goToNewsPage} className={'md:hidden w-3/4 px-4'}>
          {t('buttons.read_news')}
        </ButtonOutlined>
      </div>
    </section>
  );
})
