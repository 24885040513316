import { useCallback, useEffect, useMemo, useRef } from 'react';

const useScrollTo = () => {
  const mounted = useRef(true);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    }
  }, []);

  const isSmoothScrollingEnabled = useMemo(() => {
    const body = document.body;
    const scrollBehavior = body.style.scrollBehavior;
    body.style.scrollBehavior = 'smooth';
    const hasSmooth = getComputedStyle(body).scrollBehavior === 'smooth';
    body.style.scrollBehavior = scrollBehavior;
    return hasSmooth;
  }, []);

  const scrollNative = useCallback((element, behavior) => {
    element.scrollIntoView({ behavior });
  }, [isSmoothScrollingEnabled]);

  const scrollPolyfill = useCallback((element, behavior) => {
    let lastTimestamp;
    const fps = 120;

    const handler = () => {
      const currentOffset = element.getBoundingClientRect().top;
      window.scrollTo({ top: window.pageYOffset + currentOffset });
    };

    const smoothHandler = (timestamp = 0) => {
      if (!mounted.current) return;

      if (!lastTimestamp) lastTimestamp = timestamp;
      if (timestamp - lastTimestamp < 1000 / fps) return requestAnimationFrame(smoothHandler);
      lastTimestamp = timestamp;

      const currentOffset = element.getBoundingClientRect().top;

      if (currentOffset > 60) {
        window.scrollTo({ top: window.pageYOffset + 60 });
      } else if (currentOffset < -60) {
        window.scrollTo({ top: window.pageYOffset - 60 });
      } else {
        window.scrollTo({ top: window.pageYOffset + currentOffset });
        return;
      }

      requestAnimationFrame(smoothHandler);
    };

    if (behavior === 'smooth') {
      smoothHandler();
    } else {
      handler();
    }
  }, [isSmoothScrollingEnabled]);

  // return isSmoothScrollingEnabled ? scrollNative : scrollPolyfill;
  return scrollPolyfill;
};

export {
  useScrollTo,
}
