import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import commonEn from './assets/localizations/en/common';
import privacyPolicyEn from './assets/localizations/en/privacyPolicy';
import cookiesEn from './assets/localizations/en/cookies';
import storyEn from './assets/localizations/en/story';

import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    resources: {
      en: {
        common: commonEn,
        privacyPolicy: privacyPolicyEn,
        cookies: cookiesEn,
        story: storyEn,
      },
    }
  }, () => {
    // // let lang = '';
    // // if ((/\/r+(u$|u\/)/gm).test(window.location.pathname)) {
    // //   lang = 'ru';
    // // } else if ((/\/e+(n$|n\/)/gm).test(window.location.pathname)) {
    // //   lang = 'en';
    // // } else {
    // //   if ((/ru-?/i).test(i18n.language)) {
    // //     lang = 'ru';
    // //   } else {
    // //     lang = 'en';
    // //   }
    // // }
    //
    // i18n.changeLanguage('en');
  });


export default i18n;
