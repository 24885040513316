import React from 'react';
import {ReactComponent as MobileSVG} from '../../assets/images/mobile_mask.svg'

const MobileScreenshotWrapper = ({ src, width=314, height=639, borderRadius=35 }) => {

  return <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} xmlns="http://www.w3.org/2000/svg">
    <defs>
      <mask id={'mask-1'} x="0" y="0" width={width} height={height}>
        <rect id="main-rect" x="10" y="10" width={width-20} height={height-20} rx={borderRadius} ry={borderRadius} fill="#fff" />
      </mask>
    </defs>
    <image width={width} height={height} href={src} xlinkHref={src} mask="url(#mask-1)" preserveAspectRatio="xMidYMid slice" />
    <MobileSVG width={width} height={height} />
  </svg>
}

export default MobileScreenshotWrapper;
