import React, { useId } from 'react';
import VideoBlock from '../videoBlock';
import PageTitleLayout from '../../uiKit/pageTitleLayout';
import Paragraph from '../../uiKit/typography/paragraph';
import ButtonFulfilled from '../../uiKit/buttons/fulfilled';
import LinkFulfilled from '../../uiKit/links/fulfilled';
import GamingSolutionsBlock from '../infoBlocks/gamingSolutionsBlock';
import MechanicsBlock from '../infoBlocks/mechanicsBlock';
import UpcomingGameBlock from '../infoBlocks/upcomingGameBlock';
import GamesBlock from '../infoBlocks/gamesBlock';
import FeaturesBlock from '../infoBlocks/featuresBlock';
import DashboardBlock from '../infoBlocks/dashboardBlock';
import NewsBlock from '../infoBlocks/newsBlock';
import PartnersBlock from '../infoBlocks/partnersBlock';
import MediaPartnersBlock from '../infoBlocks/mediaPartnersBlock';
import Title from '../../uiKit/typography/title';
import { useTranslation } from 'react-i18next';

import video from '../../assets/video/video_web.mp4';

export default React.memo(function MainPage() {
  const { t } = useTranslation('common');
  // const gamesBlockId = useId();

  // const scrollToGameBlock = () => {
  //   const block = document.getElementById(gamesBlockId);
  //   if (block) block.scrollIntoView({ behavior: 'smooth' });
  // };

  return (
    <>
      <VideoBlock src={video}>
        <div className={'w-full h-full flex items-center justify-center'}>
          <PageTitleLayout title={t('titles.main_page')}
                           className={'w-10/12 max-w-4xl flex flex-col items-center text-center gap-4 md:gap-6'}>
            <Paragraph className={'!text-white md:w-2/3'}>
              {t('descriptions.main_page')}
            </Paragraph>
            {/*<ButtonFulfilled onClick={scrollToGameBlock} className={'px-12 text-white'}>*/}
            {/*  {t('buttons.our_games')}*/}
            {/*</ButtonFulfilled>*/}
            <LinkFulfilled to={'/games'} className={'px-12 text-white'}>
              {t('buttons.our_games')}
            </LinkFulfilled>
          </PageTitleLayout>
        </div>
      </VideoBlock>

      <div className={'w-full py-20 md:pt-32 md:py-0 flex flex-col items-center'}>
        <GamingSolutionsBlock />
      </div>

      <div className={'w-full py-16 md:py-32 flex flex-col items-center'}>
        <MechanicsBlock />
      </div>

      <UpcomingGameBlock />

      <div className={'w-full py-20 md:py-32 flex flex-col items-center'}>
        <GamesBlock title={t('titles.our_games')} topOffset={10} />
      </div>

      <div className={'w-full py-20 md:py-32 flex flex-col items-center'}>
        <FeaturesBlock />
      </div>

      <div className={'w-full py-20 md:pt-32 flex flex-col items-center'}>
        <DashboardBlock />
      </div>

      <div className={'w-full py-20 md:py-32 flex flex-col items-center'}>
        <NewsBlock />
      </div>

      <div className={'w-full py-20 md:py-32 flex flex-col items-center'}>
        <PartnersBlock />
      </div>

      <div className={'w-full py-20 md:pt-24 md:pb-40 flex flex-col items-center'}>
        <MediaPartnersBlock />
      </div>
    </>
  );
})
