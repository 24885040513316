import React from 'react';
import Paragraph from '../typography/paragraph';

export default React.memo(function Card({ imageSrc, title, text, className = '' }) {
  return (
    <li
      className={`flex-1 w-full h-full flex flex-col justify-center ${className}`}>
      {imageSrc &&
      <div className={'flex items-center justify-center mb-4 md:mb-10'}>
        <img src={imageSrc} alt={'preview'} />
      </div>}

      <div id={'card-grid'}
        className={'h-full grid grid-rows-[1fr_max-content_minmax(max-content,_3fr)] gap-6 justify-items-center md:justify-items-start'}>
        <div className={'w-full overflow-hidden'}>
          <span className={'uppercase font-Staatliches text-black break-words text-3xl md:text-4xl'}>{title}</span>
        </div>

        <hr className={'w-full h-px border-gray self-center'} />

        <Paragraph>
          {text}
        </Paragraph>
      </div>
    </li>
  );
});
