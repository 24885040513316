import React from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import { useTranslation } from 'react-i18next';

export default React.memo(function OverviewBlock({ text }) {
  const { t } = useTranslation('common');

  return (
    <div className={'bg-rose rounded-3xl px-4 py-6 md:px-12 md:py-14'}>
      <div className={'flex items-center justify-center md:justify-start'}>
        <Title className={'text-4xl md:text-6xl'}>{t('titles.overview')}</Title>
      </div>

      <Paragraph className={'text-lg leading-7 md:leading-8 mt-4 md:mt-14'}>
        {text}
      </Paragraph>
    </div>
  );
})
