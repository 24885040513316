import tailwindConfig from '../tailwindConfigPreBuild';

const getBreakpointValue = (value) => {
  return parseInt(tailwindConfig.theme.screens[value] || '0');
}

const getCurrentBreakpoint = () => {
  let currentBreakpoint = 'xs';
  let biggestBreakpointValue = 0;
  for (const breakpoint of Object.keys(tailwindConfig.theme.screens)) {
    const breakpointValue = getBreakpointValue(breakpoint);
    if (
      breakpointValue > biggestBreakpointValue &&
      window.innerWidth >= breakpointValue
    ) {
      biggestBreakpointValue = breakpointValue;
      currentBreakpoint = breakpoint;
    }
  }

  return currentBreakpoint;
};

export {
  getBreakpointValue,
  getCurrentBreakpoint,
}
