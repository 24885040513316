import { useEffect } from 'react';

const usePageTitle = (title) => {

  useEffect(() => {
    document.title = `Onlyplay - ${title || 'Instant win game provider'}`;

    return () => {
      document.title = 'Onlyplay - Instant win game provider';
    }
  }, [title]);
};

export {
  usePageTitle,
}
