const splitArrayByLength = (array, length) => {
    const res = [];

    for (let i = 0; i < array.length; i++) {
      if (i % length === 0) {
        const part = [];
        res.push(part);
      }

      res[res.length - 1].push(array[i]);
    }

    return res;
};

export {
  splitArrayByLength,
}
