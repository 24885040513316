const createTransformResponse = (transformFunc) => {
  return (response) => {
    if (!response.success) throw new Error(response.message);
    
    if (transformFunc) {
      return transformFunc(response)
    } else return response;
  }
}

export {
  createTransformResponse,
}