import React from "react";
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';
import COLORS from '../../utils/colors';

export default function DonutChart({ data, dataKey, className = '' }) {
  return (
    <ResponsiveContainer width="100%" height="100%" className={className}>
    <PieChart>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={'70%'}
        outerRadius={'100%'}
        fill="#8884d8"
        paddingAngle={0}
        dataKey={dataKey}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={entry.color || COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
    </ResponsiveContainer>
  );
}
