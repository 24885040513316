import React, { useId, useImperativeHandle, useRef } from 'react';

export default React.memo(React.forwardRef(function FileDrop({ accept, multiple, callback, className = '', wrapperClassName = '', labelClassName = '', label = 'Drop files here...', ...props }, ref) {
  const id = useId();
  const inputRef = useRef(null);

  const onChange = (e) => {
    const files = e.target.files;
    callback(files);
  };

  const reset = () => {
    inputRef.current.value = null;
  };

  useImperativeHandle(ref, () => ({
    reset,
    inputRef,
  }));

  return (
    <div className={`relative w-full h-full flex items-center justify-center ${wrapperClassName}`}>
      <input type={'file'} id={id} onChange={onChange} ref={inputRef}
             className={`w-full h-full text-transparent select-none file-selector-hidden border rounded-xl border-dashed border-sky-300 ${className}`}
             accept={accept ?? 'image/*'} multiple={multiple ?? false} {...props} />
             <label htmlFor={id} className={`absolute w-full h-full inset-0 flex items-center justify-center pointer-events-none ${labelClassName}`}>{label}</label>
    </div>
  )
}))
