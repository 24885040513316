const getTimeLeftTillTimestamp = (timestamp) => {
  const diff = timestamp - Date.now();
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;

  if (diff > 0) {
    const totalSeconds = diff / 1000;
    const totalMinutes = totalSeconds / 60;
    const totalHours = totalMinutes / 60;
    const totalDays = totalHours / 24;

    days = parseInt(String(totalDays));
    hours = parseInt(String(totalHours % 24));
    minutes = parseInt(String(totalMinutes % 60));
    seconds = parseInt(String(totalSeconds % 60));
  }

  days = days < 10 ? `0${days}` : String(days);
  hours = hours < 10 ? `0${hours}` : String(hours);
  minutes = minutes < 10 ? `0${minutes}` : String(minutes);
  seconds = seconds < 10 ? `0${seconds}` : String(seconds);

  return {
    days,
    hours,
    minutes,
    seconds,
  }
};

export {
  getTimeLeftTillTimestamp,
}
