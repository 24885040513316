import React, { useEffect, useImperativeHandle, useState } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import editorColors from './colors';

export default React.memo(React.forwardRef(function TextEditor({ initialValue, onChange, wrapperClassName = '', toolbarClassName = '', editorClassName = '', ...props }, ref) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (!initialValue) return;

    const content = htmlToDraft(initialValue);
    if (content) {
      const contentState = ContentState.createFromBlockArray(content.contentBlocks);
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialValue]);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    onChange && onChange(editorState);
  };

  const toHtml = () => {
    return draftToHtml(convertToRaw(editorState.getCurrentContent()));
  };

  useImperativeHandle(ref, () => ({
    toHtml,
  }));

  return (
    <Editor
      editorState={editorState} onEditorStateChange={onEditorStateChange}
      wrapperClassName={wrapperClassName}
      toolbarClassName={toolbarClassName}
      editorClassName={`border border-neutral/50 rounded-lg p-2 leading-normal [&_.public-DraftStyleDefault-block]:!my-0 ${editorClassName}`}
      toolbar={{
        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'history'],
        blockType: { options: ['Normal', 'Blockquote', 'Code'] },
        inline: { options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'] },
        fontFamily: { options: ['Staatliches', 'Gilroy', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana']},
        colorPicker: {
          colors: editorColors,
        },
      }}
      {...props} />
  );
}));
