import React from 'react';

export default React.memo(function GameCardSkeleton() {

  return (
    <div
      className={`relative flex w-full h-full rounded-3xl overflow-hidden select-none opacity-70`}>
      <div className={'flex items-center justify-center w-full h-full'}>
        <div className={'w-full h-full skeleton'}/>
      </div>

      <div
        className={'absolute max-h-full w-full bottom-0 left-0 right-0 px-4 py-6 bg-gradient-to-t from-[#141414] to-[rgba(20,_20,_20,_0)]'}>
        <div
          className={'flex flex-col items-center w-full h-full p-4 bg-neutral-900/[0.7] rounded-3xl overflow-hidden'}>

          <div className={'flex w-full gap-4'}>
            <div className={'w-14 h-14 shrink-0 rounded-lg overflow-hidden'}>
              <div className={'w-full h-full skeleton'}/>
            </div>

            <div className={'w-full flex flex-col justify-center gap-2'}>
              <div className={'skeleton skeleton-text'}/>
              <div className={'skeleton skeleton-text'}/>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
})
