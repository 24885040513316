import React, { useEffect, useState } from 'react';
import Badge from '../../uiKit/badge';
import LinkFulfilled from '../../uiKit/links/fulfilled';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import { useNavigate } from 'react-router-dom';
import { transformGameTitleToUrl } from '../../utils/transformGameTitleToUrl';
import { useTranslation } from 'react-i18next';

export default React.memo(function GameCard({ game, callback, isOpen }) {
  const { t } = useTranslation('common');
  const [showInfo, setShowInfo] = useState(isOpen);
  const navigate = useNavigate();

  useEffect(() => {
    setShowInfo(isOpen);
  }, [isOpen]);

  const openCard = () => {
    setShowInfo(true);
    callback && callback(game.id);
  };

  const goToGamePage = () => {
    navigate(`/games/${transformGameTitleToUrl(game.name)}`);
  };

  return (
    <div onClick={openCard}
         className={`relative flex w-full h-full rounded-3xl overflow-hidden select-none transition-all md:hover:-translate-y-4 group ${!isOpen ? 'cursor-pointer' : 'md:-translate-y-4'}`}>
      <div className={'flex items-center justify-center w-full h-full'}>
        <img src={game.iconPreview} alt={`${game.name} icon`} className={'w-full h-full object-cover'} draggable={false}/>
      </div>

      <div
        className={'absolute max-h-full w-full bottom-0 left-0 right-0 px-4 py-6 bg-gradient-to-t from-[#141414] to-[rgba(20,_20,_20,_0)]'}>
        <div
          className={'flex flex-col items-center w-full h-full p-4 blurred rounded-3xl overflow-hidden'}>

          <div className={'flex w-full gap-4'}>
            <div className={'w-14 h-14 shrink-0 rounded-lg overflow-hidden'}>
              <img src={game.iconSquare} alt={`${game.name} icon`} className={'h-full w-full object-contain'} />
            </div>

            <div className={'flex flex-col gap-2'}>
              <div>
                <span className={'text-2xl text-white font-Staatliches leading-[88%] line-clamp-1'}>
                  {game.name}
                </span>
              </div>
              <div>
                <span className={'text-xs text-neutral line-clamp-2'}>
                {game.overview}
                </span>
              </div>
            </div>
          </div>

          <div
            className={`w-full transition-all duration-500 max-h-0 opacity-0 md:group-hover:max-h-[22rem] md:group-hover:opacity-100 ${showInfo ? 'max-h-[22rem] opacity-100' : ''}`}>
            <div className={'w-full flex items-stretch gap-2.5 [&>*]:flex-1 mt-2'}>
              <Badge label={t('labels.type')}
                     className={'bg-white/10 text-center [&>label]:text-neutral-300 [&>label]:text-xs [&>label]:leading-[75%] px-0 py-2'}>
                <span className={'inline-block font-Staatliches text-white text-sm leading-[90%] uppercase'}>{game.gameTypes?.join(' / ')}</span>
              </Badge>
              <Badge label={t('labels.rtp')}
                     className={'bg-white/10 text-center [&>label]:text-neutral-300 [&>label]:text-xs [&>label]:leading-[75%] px-0 py-2'}>
                <span className={'inline-block font-Staatliches text-white text-sm leading-[90%] uppercase'}>{game.rtp}</span>
              </Badge>
              <Badge label={t('labels.volatility')}
                     className={'bg-white/10 text-center [&>label]:text-neutral-300 [&>label]:text-xs [&>label]:leading-[75%] px-0 py-2'}>
                <span className={'inline-block font-Staatliches text-white text-sm leading-[90%] uppercase'}>{game.volatility}</span>
              </Badge>
            </div>

            <div className={'w-full flex flex-col gap-2 mt-2'}>
              <LinkFulfilled external to={game.demoLink} target={'_blank'} rel={'noopener noreferrer'} className={'w-full text-white'}>
                {t('buttons.try_now')}
              </LinkFulfilled>
              <ButtonOutlined onClick={goToGamePage} className={'w-full text-white'}>
                {t('buttons.game_info')}
              </ButtonOutlined>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
})
