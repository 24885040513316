import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import Carousel from '../../uiKit/carousel';
import DotControls from '../../uiKit/carousel/controls/dotControls';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import InfiniteSlider from '../../uiKit/carousel/infinite';
import { splitArrayByLength } from '../../utils/splitArrayByLength';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetMediaPartnersListQuery } from '../../redux/api/common';
import PartnerItem from '../partnerItem';

export default React.memo(function MediaPartnersBlock() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { data: mediaPartnersData = [] } = useGetMediaPartnersListQuery();

  const goToPartnersPage = () => {
    navigate('/partners');
  };

  const partners = useMemo(() => {
    return mediaPartnersData.map((item) => (
      <PartnerItem key={item.id} partnerData={item} linkActive={false} className={'opacity-50 hover:opacity-100'} classNamesImg={'w-1/2 md:w-full'}/>
    ));
  }, [mediaPartnersData]);

  const mediaPartnersByPages = useMemo(() => splitArrayByLength(partners, 4), [partners]);

  return (
    <section className={'flex items-center flex-col w-full'}>
      <div className={'contents w-full md:flex md:flex-col md:items-center md:gap-16'}>
        <div className={'flex flex-col md:flex-row md:justify-between items-center w-full md:max-w-6xl px-4'}>
          <Title>
            {t('titles.media_partners')}
          </Title>

          <Carousel slidesPerView={1} className={'rounded-3xl'} containerClassName={'mt-12 md:hidden'}
                    applyControls={props => <DotControls {...props} className={'mt-12'} />}>
            {mediaPartnersByPages.map((list, i) =>
              <div key={i} className={'gap-1 w-full h-full grid grid-cols-1 grid-rows-4'}>
                {list}
              </div>
            )}
          </Carousel>

          <ButtonOutlined onClick={goToPartnersPage} className={'mt-16 md:mt-0 w-3/4 px-4 md:w-max'}>
            {t('buttons.view_media_partners')}
          </ButtonOutlined>
        </div>

        <InfiniteSlider slidesPerView={4} speed={100} containerClassName={'hidden md:flex'}
                        className={'[&>div]:gap-16'}>
          {partners}
        </InfiniteSlider>
      </div>
    </section>
  );
})
