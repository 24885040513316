import React, { useMemo, useState } from 'react';
import { SearchInput } from './index';

export default React.memo(function MultiSelect({ options, selected, setSelected, name, min, max, className, required, search }) {
  const [searchTerm, setSearchTerm] = useState('');
  
  const onChangeSearchTerm = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleItem = (id) => () => {
    setSelected({
      target: {
        name,
        value: selected.includes(id) ? selected.filter(item => item !== id) : ((max && selected.length >= max) ? selected : [...selected, id])
      }
    });
  };

  const filteredOptions = useMemo(() => {
    return search ? options.filter(item => item.searchKey.toUpperCase().includes(searchTerm.toUpperCase()) && !selected.includes(item.id)) : options.filter(item => !selected.includes(item.id));
  }, [searchTerm, options, search, selected]);

  const selectedOptions = useMemo(() => {
    return selected.map(id => options.find(item => item.id === id)).filter(item => item !== undefined);
  }, [selected, options]);

  return (
    <div className={`w-full flex flex-col p-4 border border-[#D0D5DD] rounded-2xl gap-4 ${className}`}>
      <div className={'w-full flex flex-wrap gap-2'}>
        {selectedOptions.map(option =>
          <div key={option.id} onClick={toggleItem(option.id)}
               className={`transition-all p-2 rounded-lg [&>*]:whitespace-nowrap cursor-pointer select-none bg-green-300/50`}>
            {<option.Component selected={true} />}
          </div>
        )}
      </div>

      {search && <SearchInput value={searchTerm} onChange={onChangeSearchTerm} />}

      <div className={'w-full flex flex-wrap gap-2'}>
        <input name={name} value={selected.join(', ')} pattern={min ? `(.+,){${min - 1}}.+` : undefined} onChange={() => {
        }} className={'hidden'} required={required} />
        {filteredOptions.map(option =>
          <div key={option.id} onClick={toggleItem(option.id)}
               className={'transition-all p-2 rounded-lg [&>*]:whitespace-nowrap cursor-pointer select-none'}>
            {<option.Component selected={false} />}
          </div>
        )}
      </div>
    </div>
  );
})
