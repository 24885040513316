import React, { useMemo } from 'react';
import Title from '../../uiKit/typography/title';
import Paragraph from '../../uiKit/typography/paragraph';
import Carousel from '../../uiKit/carousel';
import DotControls from '../../uiKit/carousel/controls/dotControls';
import ButtonOutlined from '../../uiKit/buttons/outlined';
import InfinitySlider from '../../uiKit/carousel/infinite';
import { splitArrayByLength } from '../../utils/splitArrayByLength';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGetPartnersListQuery } from '../../redux/api/common';
import PartnerItem from '../partnerItem';

export default React.memo(function PartnersBlock() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();
  const { data: partnersData = [] } = useGetPartnersListQuery();

  const partners = useMemo(() => {
    return partnersData.map((item) => (
        <PartnerItem key={item.id} partnerData={item} linkActive={false} className={'opacity-50 hover:opacity-100'} classNamesImg={'w-1/2 md:w-full'}/>
      ));
  }, [partnersData]);

  const goToPartnersPage = () => {
    navigate('/partners');
  };

  const partnersByPages = useMemo(() => splitArrayByLength(partners, 4), [partners]);

  return (
    <section className={'flex items-center flex-col px-4 w-full md:max-w-6xl'}>
      <div className={'contents md:flex gap-10'}>
        <div className={'flex flex-col items-center md:items-start w-full md:w-2/3'}>
          <Title className={'md:text-8xl md:text-left'}>
            {t('titles.partners')}
          </Title>
          <Paragraph className={'text-center md:text-left mt-4 md:mt-10'}>
            {t('descriptions.partners')}
          </Paragraph>

          <Carousel slidesPerView={1} className={'rounded-3xl'} containerClassName={'mt-12 md:hidden'}
                    applyControls={props => <DotControls {...props} className={'mt-12'} />}>
            {partnersByPages.map((list, i) =>
              <div key={i} className={'gap-1 w-full h-full grid grid-cols-1 grid-rows-4'}>
                {list}
              </div>
            )}
          </Carousel>

          <ButtonOutlined onClick={goToPartnersPage} className={'mt-16 w-3/4 px-4 md:w-max'}>
            {t('buttons.view_partners')}
          </ButtonOutlined>
        </div>

        <div className={'hidden md:grid grid-cols-2 auto-rows-[26rem] gap-x-36 gap-y-10 w-full'}>
          <InfinitySlider slidesPerView={2} speed={40} direction={'top'} >
            {partners}
          </InfinitySlider>
          <InfinitySlider slidesPerView={2} speed={40} direction={'bottom'} >
            {partners}
          </InfinitySlider>
        </div>
      </div>
    </section>
  );
})
