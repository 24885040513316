import React, { useMemo } from 'react';

export default React.memo(function InfiniteSlider({
                                                    children, slidesPerView, speed = 10,
                                                    className = '', containerClassName = '',
                                                    direction = 'left'
                                                  }) {
  const availableDirections = useMemo(() => ['top', 'bottom', 'left', 'right'], []);

  const transform = useMemo(() => ({
    top: {
      template: 'grid grid-flow-row auto-rows-fr',
      dimensionAttribute: 'height',
      animation: 'animate-slideTop'
    },
    bottom: {
      template: 'grid grid-flow-row auto-rows-fr',
      dimensionAttribute: 'height',
      animation: 'animate-slideBottom'
    },
    left: {
      template: 'flex flex-row',
      dimensionAttribute: 'width',
      animation: 'animate-slideLeft'
    },
    right: {
      template: 'flex flex-row',
      dimensionAttribute: 'width',
      animation: 'animate-slideRight'
    },
  }), []);

  const items = useMemo(() => {
    return [...children, ...React.Children.map(children, child => React.cloneElement(child))];
  }, [children]);

  const slidesCount = useMemo(() => {
    return items.length;
  }, [items.length]);

  const sliderDimension = useMemo(() => {
    return slidesCount * 100 / slidesPerView;
  }, [slidesPerView, slidesCount]);

  if (!availableDirections.includes(direction)) {
    console.error(`Unhandled direction: ${direction}`);
  }

  //todo think about gap handler
  return !availableDirections.includes(direction) ? null : (
    <div className={`w-full flex select-none ${containerClassName}`}>
      <div className={`w-full h-full overflow-hidden ${className}`}>
        <div
          className={`${transform[direction].template} [&>div]:flex-1 [&>div]:min-h-0 ${transform[direction].animation}`}
          style={{
            [transform[direction].dimensionAttribute]: `${sliderDimension}%`,
            animationDuration: `${speed}s`,
          }}>

          {items.map((slide, i) =>
              <div key={i} className={'flex items-center justify-center overflow-hidden'}>
                {slide}
              </div>
          )}

          {(direction === 'left' || direction === 'right') && <span className={'!w-0 !h-0'} />}

        </div>
      </div>
    </div>
  );
});
