import React, { useEffect, useId, useMemo, useState } from 'react';
import PieChart from '../../charts/pieChart';
import ShadowCard from '../../../uiKit/cards/shadowCard';
import COLORS from '../../../utils/colors';
import { useTranslation } from 'react-i18next';
import { useIntersectionObserver } from '../../../utils/hooks/useIntersectionObserver';

export default React.memo(function GameTypes({ data, className = '' }) {
  const { t } = useTranslation('common');
  const id = useId();
  const isIntersecting = useIntersectionObserver(id);

  const dataPercentage = useMemo(() => {
    const totalValue = data.reduce((acc, curr) => acc + curr.gamesCount, 0);
    return data.map((item, i) => ({
      ...item,
      color: COLORS[(COLORS.length - i) % COLORS.length],
      percent: ((item.gamesCount / totalValue) * 100).toFixed(0)
    }));
  }, []);

  return (
    <ShadowCard className={className}>
      <div className={'w-full h-full flex flex-col gap-6'}>
        <div className={'w-full flex items-center gap-2'}>
          <span className={'font-Staatliches text-6xl uppercase'}>{data.length}</span>
          <span className={'text-base uppercase whitespace-pre-line'}>
            {t('cards.descriptions.game_types')}
          </span>
        </div>

        <div className={'w-full h-full flex flex-col items-center md:flex-row flex-wrap gap-6'}>
          <div id={id} className={'w-48 h-48'}>
            <PieChart data={data} dataKey={'gamesCount'} className={isIntersecting ? '' : 'hidden'}/>
          </div>

          <div className={'w-full md:w-max flex flex-col gap-2 justify-center'}>
            {dataPercentage.map((item, i) =>
              <div key={i} className={'w-full text-sm text-black'}>
                <div className={'flex items-center gap-1.5'}>
                  <span className={'w-2.5 h-2.5 rounded-full'} style={{ backgroundColor: item.color }} />
                  <span>{item.type}</span>
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
    </ShadowCard>
  );
})
