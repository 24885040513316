import React from 'react';
import { ReactComponent as ToggleIcon } from '../../../assets/icons/system/open-icon.svg';

export default React.memo(function MainAccordionLayout({ title, isActive }) {
  return (
    <div className={'w-full grid grid-cols-[minmax(0,_1fr)_max-content] px-6 py-4'}>
        <span
          className={`transition-all uppercase font-Staatliches text-2xl select-none ${isActive ? 'text-black' : 'text-gray'}`}>
          {title}
        </span>

      <div className={'h-full flex items-start'}>
        <ToggleIcon
          className={`transition-all ${isActive ? '-rotate-45 [&_path]:stroke-black' : 'rotate-0'}`} />
      </div>
    </div>
  );
})
