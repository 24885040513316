import React from 'react';
import { useNode } from '@craftjs/core';
import ContainerSettings from '../settings/containerSettings';
import Border from '../utils/border';

export const Container = React.memo(function ({ width, background, borderRadius, paddingTop, paddingBottom, paddingLeft, paddingRight, align, alignSelf, direction, spacing, children }) {
  const { connectors: { connect, drag }, hasSelectedNode, hasHoveredNode, hasDraggedNode } = useNode((state) => {
    return {
      hasSelectedNode: state.events.selected,
      hasHoveredNode: state.events.hovered,
      hasDraggedNode: state.events.dragged,
    }
  });

  return (
    <div ref={ref => connect(drag(ref))} className={`relative flex [&>*]:shrink-1 transition-all`}
         style={{
           background: typeof background === 'object' ? `rgba(${background.r}, ${background.g}, ${background.b}, ${background.a})` : background,
           width: /%$/.test(width) ? width : `${width}rem`,
           maxWidth: '100%',
           paddingTop: `${paddingTop}rem`,
           paddingBottom: `${paddingBottom}rem`,
           paddingLeft: `${paddingLeft}rem`,
           paddingRight: `${paddingRight}rem`,
           borderRadius: `${borderRadius}rem`,
           alignItems: align,
           alignSelf,
           flexDirection: direction,
           gap: `${spacing}rem`
         }}>
      {children}

      <Border highlight={hasSelectedNode || hasHoveredNode} />
    </div>
  );
});

const defaultProps = {
  width: '100%',
  paddingTop: 1,
  paddingBottom: 1,
  paddingLeft: 1,
  paddingRight: 1,
  borderRadius: 0,
  background: '#FFF',
  align: 'unset',
  alignSelf: 'unset',
  direction: 'column',
  spacing: 0,
};

Container.craft = {
  props: defaultProps,
  related: {
    settings: ContainerSettings
  }
};
