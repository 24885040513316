const colors = [
  "#F75D5F",
  "#F7A23B",
  "#0FCA7A",
  "#0568DF",
  "#5E81F4",
  "#E44E98",
  "#0088FE",
  '#FF0000',
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  '#8884d8',
  '#82ca9d',
  '#0099ff',
  '#7fff00',
  '#FF69B4',
  '#9932CC',
  '#8B008B',
  '#FFA500',
  '#FFFF00',
  '#0000FF',
  '#2F4F4F',
  '#BDB76B',
  '#4B0082',
  '#696969',
  '#FF4D4D',
  '#FF971D',
  '#0FCA7A',
];

export default colors;
