import { apiSlice } from './index';
import { setToStorage, getFromStorage } from '../../utils/hooks/useStorage';
import eStorageKeys from '../../enums/storageKeys';
import { createTransformResponse } from './utils/createTransformResponse';

export const adminExtendedApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: (data) => ({
        url: `/login`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Auth'],
      transformResponse: responseData => {
        if (responseData.success) {
          setToStorage(eStorageKeys.ESK_ADMIN_TOKEN, responseData.token);
        } else {
          setToStorage(eStorageKeys.ESK_ADMIN_TOKEN, '');
          throw Error(responseData.message);
        }
        return responseData;
      },
    }),
    checkAuth: builder.query({
      query: () => {
        const token = getFromStorage(eStorageKeys.ESK_ADMIN_TOKEN);
        return {
          url: `/check_auth`,
          method: 'POST',
          body: { token }
        }
      },
      providesTags: ['Auth'],
      transformResponse: responseData => {
        if (responseData.success) {
          setToStorage(eStorageKeys.ESK_ADMIN_TOKEN, responseData.token);
        } else {
          setToStorage(eStorageKeys.ESK_ADMIN_TOKEN, '');
        }
        return responseData.success && responseData.token;
      }
    }),
    getAvailableLanguages: builder.query({
      query: () => '/common/get_languages_list',
      providesTags: ['Languages'],
      transformResponse: createTransformResponse(response => response.data),
    }),
    getAdminNewsList: builder.query({
      query: () => '/admin/news/list',
      providesTags: (result = [], error, arg) => [
        'AdminNews',
        ...result.map(({ id }) => ({ type: 'AdminNews', id }))
      ],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createNews: builder.mutation({
      query: (data) => ({
        url: '/admin/news/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['News', 'AdminNews'],
      transformResponse: createTransformResponse(),
    }),
    editNews: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/news/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: ['News', 'AdminNews'],
      transformResponse: createTransformResponse(),
    }),
    deleteNews: builder.mutation({
      query: (id) => ({
        url: '/admin/news/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['News', 'AdminNews'],
      transformResponse: createTransformResponse(),
    }),
    sortNews: builder.mutation({
      query: (data) => ({
        url: '/admin/news/order',
        method: 'POST',
        body: { data }
      }),
      invalidatesTags: ['News', 'AdminNews'],
      transformResponse: createTransformResponse(),
    }),
    getAdminVacanciesList: builder.query({
      query: () => '/admin/vacancy/list',
      providesTags: (result = [], error, arg) => [
        'AdminVacancies',
        ...result.map(id => ({ type: 'AdminVacancies', id }))
      ],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createVacancy: builder.mutation({
      query: (data) => ({
        url: `/admin/vacancy/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Vacancies', 'AdminVacancies'],
      transformResponse: createTransformResponse(),
    }),
    editVacancy: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/vacancy/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: ['Vacancies', 'AdminVacancies'],
      transformResponse: createTransformResponse(),
    }),
    deleteVacancy: builder.mutation({
      query: (id) => ({
        url: '/admin/vacancy/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['Vacancies', 'AdminVacancies']
    }),
    sortVacancies: builder.mutation({
      query: (data) => ({
        url: '/admin/vacancy/order',
        method: 'POST',
        body: { data }
      }),
      invalidatesTags: ['Vacancies', 'AdminVacancies'],
      transformResponse: createTransformResponse(),
    }),
    getAdminPartnersList: builder.query({
      query: () => '/admin/partner/list',
      providesTags: ['AdminPartners'],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createPartner: builder.mutation({
      query: (data) => ({
        url: `/admin/partner/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['Partners', 'AdminPartners'],
      transformResponse: createTransformResponse(),
    }),
    editPartner: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/partner/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: ['Partners', 'AdminPartners'],
      transformResponse: createTransformResponse(),
    }),
    deletePartner: builder.mutation({
      query: (id) => ({
        url: '/admin/partner/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['Partners', 'AdminPartners'],
      transformResponse: createTransformResponse(),
    }),
    sortPartner: builder.mutation({
      query: (data) => ({
        url: '/admin/partner/order',
        method: 'POST',
        body: { data }
      }),
      invalidatesTags: ['Partners', 'AdminPartners'],
      transformResponse: createTransformResponse(),
    }),
    getAdminGame: builder.query({
      query: bundle => `/admin/game/game?gameBundle=${bundle}`,
      providesTags: (result, err, arg) => [{ type: 'AdminGame', id: arg }],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    getAdminGamesData: builder.query({
      query: () => '/admin/game/list',
      providesTags: ['AdminGamesData'],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createGame: builder.mutation({
      query: (data) => ({
        url: `/admin/game/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['GamesData', 'AdminGamesData'],
      transformResponse: createTransformResponse(),
    }),
    editGame: builder.mutation({
      query: (data) => {
        const { id } = data;
        
        return {
          url: '/admin/game/edit',
          method: 'POST',
          body: { id, data }
        }
      },
      invalidatesTags: (result, err, arg) => [
        'GamesData',
        'AdminGamesData',
        { type: 'Game', id: arg.gameBundle },
        { type: 'AdminGame', id: arg.gameBundle }
      ],

      transformResponse: createTransformResponse(),
    }),
    deleteGame: builder.mutation({
      query: (id) => ({
        url: '/admin/game/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: (result, err, arg) => ['GamesData', 'AdminGamesData', { type: 'Game', id: arg.id }],
      transformResponse: createTransformResponse(),
    }),
    sortGames: builder.mutation({
      query: (data) => ({
        url: '/admin/game/order',
        method: 'POST',
        body: { data }
      }),
      invalidatesTags: ['GamesData', 'AdminGamesData'],
      transformResponse: createTransformResponse(),
    }),
    getAdminUpcomingGamesList: builder.query({
      query: () => '/admin/upcoming_game/list',
      providesTags: ['AdminUpcomingGame'],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createUpcomingGame: builder.mutation({
      query: (data) => ({
        url: `/admin/upcoming_game/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UpcomingGame', 'AdminUpcomingGame'],
      transformResponse: createTransformResponse(),
    }),
    editUpcomingGame: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/upcoming_game/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: (result, err, arg) => ['UpcomingGame', 'AdminUpcomingGame', {
        type: 'UpcomingGame',
        id: arg.id
      }],
      transformResponse: createTransformResponse(),
    }),
    deleteUpcomingGame: builder.mutation({
      query: (id) => ({
        url: '/admin/upcoming_game/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: (result, err, arg) => ['UpcomingGame', 'AdminUpcomingGame', {
        type: 'UpcomingGame',
        id: arg.id
      }],
      transformResponse: createTransformResponse(),
    }),
    sortUpcomingGames: builder.mutation({
      query: (data) => ({
        url: '/admin/upcoming_game/order',
        method: 'POST',
        body: { data }
      }),
      invalidatesTags: ['UpcomingGame', 'AdminUpcomingGame'],
      transformResponse: createTransformResponse(),
    }),
    getAdminMediaPartnersList: builder.query({
      query: () => '/admin/media_partner/list',
      providesTags: ['AdminMediaPartners'],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createMediaPartner: builder.mutation({
      query: (data) => ({
        url: `/admin/media_partner/create`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['MediaPartners', 'AdminMediaPartners'],
      transformResponse: createTransformResponse(),
    }),
    editMediaPartner: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/media_partner/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: ['MediaPartners', 'AdminMediaPartners'],
      transformResponse: createTransformResponse(),
    }),
    deleteMediaPartner: builder.mutation({
      query: (id) => ({
        url: '/admin/media_partner/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['MediaPartners', 'AdminMediaPartners'],
      transformResponse: createTransformResponse(),
    }),
    sortMediaPartner: builder.mutation({
      query: (data) => ({
        url: '/admin/media_partner/order',
        method: 'POST',
        body: { data }
      }),
      invalidatesTags: ['MediaPartners', 'AdminMediaPartners'],
      transformResponse: createTransformResponse(),
    }),
    createGameFeature: builder.mutation({
      query: (data) => ({
        url: '/admin/game_feature/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['GamesData', 'AdminGamesData'],
      transformResponse: createTransformResponse(),
    }),
    editGameFeature: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/game_feature/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: ['GamesData', 'AdminGamesData'],
      transformResponse: createTransformResponse(),
    }),
    deleteGameFeature: builder.mutation({
      query: (id) => ({
        url: '/admin/game_feature/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['GamesData', 'AdminGamesData'],
      transformResponse: createTransformResponse(),
    }),
    getAdminUsersList: builder.query({
      query: () => '/admin/users/list',
      providesTags: ['AdminUsers'],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    createAdminUser: builder.mutation({
      query: (data) => ({
        url: '/admin/users/create',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['AdminUsers'],
      transformResponse: createTransformResponse(),
    }),
    editAdminUser: builder.mutation({
      query: (data) => {
        const { id, ...rest } = data;

        return {
          url: '/admin/users/edit',
          method: 'POST',
          body: { id, data: rest }
        }
      },
      invalidatesTags: ['AdminUsers'],
      transformResponse: createTransformResponse(),
    }),
    deleteAdminUser: builder.mutation({
      query: (id) => ({
        url: '/admin/users/delete',
        method: 'POST',
        body: { id }
      }),
      invalidatesTags: ['AdminUsers'],
      transformResponse: createTransformResponse(),
    }),
    getSettings: builder.query({
      query: () => '/common/get_settings',
      providesTags: ['Settings'],
      transformResponse: createTransformResponse(responseData => responseData.data),
    }),
    editSettings: builder.mutation({
      query: (settings) => ({
        url: '/admin/settings/edit',
        method: 'POST',
        body: { ...settings }
      }),
      invalidatesTags: ['Settings'],
      transformResponse: createTransformResponse(),
    }),
  })
});

export const {
  useLoginMutation,
  useCheckAuthQuery,

  useGetAvailableLanguagesQuery,

  useGetAdminGameQuery,
  useGetAdminGamesDataQuery,
  useCreateGameMutation,
  useEditGameMutation,
  useDeleteGameMutation,
  useSortGamesMutation,

  useGetAdminUpcomingGamesListQuery,
  useCreateUpcomingGameMutation,
  useEditUpcomingGameMutation,
  useDeleteUpcomingGameMutation,
  useSortUpcomingGamesMutation,

  useGetAdminNewsListQuery,
  useCreateNewsMutation,
  useEditNewsMutation,
  useDeleteNewsMutation,
  useSortNewsMutation,

  useGetAdminVacanciesListQuery,
  useCreateVacancyMutation,
  useEditVacancyMutation,
  useDeleteVacancyMutation,
  useSortVacanciesMutation,

  useGetAdminPartnersListQuery,
  useCreatePartnerMutation,
  useEditPartnerMutation,
  useDeletePartnerMutation,
  useSortPartnerMutation,

  useGetAdminMediaPartnersListQuery,
  useCreateMediaPartnerMutation,
  useEditMediaPartnerMutation,
  useDeleteMediaPartnerMutation,
  useSortMediaPartnerMutation,

  useCreateGameFeatureMutation,
  useEditGameFeatureMutation,
  useDeleteGameFeatureMutation,

  useGetAdminUsersListQuery,
  useCreateAdminUserMutation,
  useEditAdminUserMutation,
  useDeleteAdminUserMutation,

  useGetSettingsQuery,
  useEditSettingsMutation
} = adminExtendedApi;
