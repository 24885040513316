import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default React.memo(function TopScroller({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location.pathname]);

  return children;
})
